import React from 'react';

const TrustFactors =() =>{

    return(
        <div className="trust_factorContainer">
            <h4 className="trust_factorhfour">Our Accreditation partners</h4>
            <div className="trust_factor">
                <div>
                    <img src="/images/googletrust.png" alt="Global mclien gear on a screen"/>
                    <p>Google</p>
                </div>
                <div>
                    <img src="/images/facebooknobg.png" alt="Global mclien gear on a screen"/>
                    <p>Facebook</p>
                </div>
                <div>
                    <img src="/images/yelp.png" alt="Global mclien gear on a screen"/>
                    <p>Yelp</p>
                </div>
                <div>
                    <img src="/images/angie.png" alt="Global mclien gear on a screen"/>
                    <p>Angie</p>
                </div>
            </div>

        </div>
    )
}

export default React.memo(TrustFactors);