import React from 'react';
import { Link } from 'react-router-dom';

const MoreIndustryList =() =>{

    return(
        <div >
            <h1>More Industry list</h1>
            <div className="other_industrieselement">
                <div>
                    <Link to="/">Software development near me</Link>
                </div>
                <div>
                    <Link to="/">Software for Solar Industry</Link>
                </div>
                <div>
                    <Link to="/">Software for Attornneys</Link>
                </div>
                <div>
                    <Link to="/">Software for Injury Law Firms</Link>
                </div>
                <div>
                    <Link to="/">Software for Financial Institutions</Link>
                </div>
                <div>
                    <Link to="/">Software for Entertainment Industries</Link>
                </div>
                <div>
                    <Link to="/">Software for Media Companies</Link>
                </div>
                <div>
                    <Link to="/">Software for Retail</Link>
                </div>
                <div>
                    <Link to="/">Software for Manufcaturing</Link>
                </div>
                <div>
                    <Link to="/">Software for Insurance Companies</Link>
                </div>
                <div>
                    <Link to="/">Software for Construction Companies</Link>
                </div>
                <div>
                    <Link to="/">Software for Education</Link>
                </div>
                <div>
                    <Link to="/">Software for Mining Companies</Link>
                </div>
                <div>
                    <Link to="/">Software for Transportation and Logistics Companies</Link>
                </div>
                <div>
                    <Link to="/">Software for Auto dealers </Link>
                </div>
                <div>
                    <Link to="/">Software for Agriculture</Link>
                </div>  
            </div>
        </div>
    )
}

export default React.memo(MoreIndustryList);