import React , {useState} from 'react';
import { Helmet } from 'react-helmet';
import {  useNavigate } from 'react-router-dom';
import { IoCodeSlashOutline ,IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { FaRegHandshake ,FaChalkboardTeacher} from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BiUserX ,BiGlassesAlt } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import FooterForm from './Footer/FooterForm';
import Reviews from './Reviews';
import { EmailJsComponent } from '../utility/EmailJsComponent';

const Seo =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);
    const navigate = useNavigate();



    return(
        <>       
           <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='seo optimization, seo services , geo-targeted content ,PPC, keywords, sem, email marketing ,videos'/>
                <meta name='description' 
                    content="Unlock your website's potential with our top-notch SEO services. At Global Mclien Software Solutions, we specialize in driving organic traffic and boosting search engine rankings for businesses like yours. Our expert team employs proven strategies and the latest techniques to optimize your website's visibility and attract targeted visitors. Whether you're looking to improve your local presence or compete on a global scale, we have the expertise to help you achieve your SEO goals. Partner with us to elevate your online presence and dominate the search results. Contact us today to start maximizing your website's performance and driving sustainable growth!"
                    />
                <title>Global Mclien Software Solutions - SEO | PPC | Email Marketing | optimisation</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/seo" />
            </Helmet>
            <div className='component_container ' >
                <div className="hero_container seoPage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1 seoPageHone">Search Engine Optimization</h1>
                                <ul className="hero_message_ul">
                                    <li>On-Page | Off-Page SEO</li>
                                    <li>White Label Seo</li>
                                    <li>Search Engine Optimization</li>
                                    <li>SEM | PPC </li>
                                    <li>Content Creation</li>
                                    <li>Conversion rate optimization</li>
                                </ul>
                                <p className="hero_message_p">
                                    We get your business in front of your ideal customers needing your services using 
                                    our search engine optimization and 
                                    <a href="/digitalmarketing" className="backlinks"> digital marketing services. </a> 
                                    Get your online reputation management <a href="/digitalmarketing" className="backlinks">custom made 
                                    marketing strategies.</a>
                                   
                                </p>
                                <div>
                                    <a className="hero_message_button" href="#content_form">Work With Us</a>
                                </div>
                            </div>
                            <div className="hero_message_image seoPageImage">
                                <img src="/images/seoservices.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overview">
                    <h3>An Overview Of Our SEO Services</h3>
                    <p>
                        Appearing in the organic, non-paid listings on Google's first page is important for
                        growing leads, sales, and your company's brand awareness and exposure. SEO efforts generates leads 
                        and sales at the lowest cost out of almost any <a href="/digitalmarketing" className="backlinks">marketing strategy</a> .
                         We serve both local clients (Southern California), and nationwide (North America) that are looking for 
                         SEO agency near me.
                    </p>
                    <p>
                        <a href="/software" className="backlinks">Global Mclien Software Solutions 
                        </a>  is a full-service <a href="/digitalmarketing" className="backlinks">Internet marketing </a>  and 
                        Search Engine Optimization (SEO) 
                        services company offering results-driven services and exceptional customer support. 
                        Our <a href="/websites" className="backlinks">web, </a>email, marketing services include Social Media,
                         Link Building, Local Search, PPC, 
                         <a href="/digitalmarketing" className="backlinks"> Content Creation,</a>
                          <a href="/websites" className="backlinks">Web Design, </a>and Retargeting.
                    </p>
                    <div><button type="button" onClick={()=>navigate('/contact', { state:"Book A Discovery Call" })} className="hero_message_button">Book A Discovery Call</button></div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Project Consultation</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Work With us'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="reason_container">
                        <div className="reason_element">
                            <IoCodeSlashOutline />
                            <p>Comprehensive SEO Audit</p>
                            <div>
                                <p className="reason_elementP">
                                    A comprehensive SEO audit that evaluates all factors evaluated by search 
                                    engines: metadata such as title tags, meta descriptions and headers, backlinks
                                    volume, quality, and authority, content quality, SEO-friendliness, and 
                                    duplicates, technical SEO, XML <a href="/sitemaps" className="backlinks">sitemap,</a> schema, international seo, local 
                                    SEO, and <a href="https://g.page/r/CXzB0lEvWoVzEBM/review">Google Search Console errors. </a>
                                     As for keyword research, we analyze 
                                    relevance, intent, search volume, and competition levels.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <BiUserX />
                            <p>SEO Strategy</p>
                            <div>
                                <p className="reason_elementP">
                                    A specialized SEO strategy, thoughtfully executed by a dedicated team of SEO 
                                    specialists and  <a href="/digitalmarketing" className="backlinks">digital marketers </a>
                                     focused on driving brand recognition and 
                                    revenue growth.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <FaRegHandshake/>
                            <p>Integrating SEO</p>
                            <div>
                                <p className="reason_elementP">
                                    SEO integration with CMS sites, content marketing, PR and paid search and social for
                                    a holistic growth approach through your dedicated 
                                    <a href="/digitalmarketing" className="backlinks"> marketing agency near me.</a> 
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <BiGlassesAlt />
                            <p>Analysing your Competitiors</p>
                            <div>
                                <p className="reason_elementP">
                                We conduct an exhaustive study of competitors in order to identify new link
                                building & <a href="/digitalmarketing" className="backlinks"> marketing opportunities.</a> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="other_maincontainer">
                    <div className="other_subfeatures">
                        <div className="right_items">
                            <h2>
                            Local SEO <span className='softwareheader_span'><a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className='backlinks'>Google Maps | Local Packs</a></span>
                            </h2>
                            <div className="rightitem_content">
                                <img src="/images/newseo.jpg" alt="Software Dev Bytes process" />
                                <p>
                                    Local SEO is about showing up for search queries with local intent in Google's search
                                    results pages and within <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">Google Maps. </a>The process of local optimization is very 
                                    different than traditional SEO and relies more on your
                                     <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">Google My Business profile </a>  
                                    optimization, geo signals and consistency in accurate NAP (name, address, phone) 
                                    information throughout the internet, among other factors.
                                </p>
                            </div>
                        </div>
                        <div className="right_items">
                            <h2>
                                Traditional SEO <span className='softwareheader_span'> - No Local Packs</span>
                            </h2>
                            <div className="rightitem_content">
                                <p>
                                    When Google searchers are not looking for <a href="https://www.globalmcliensoftwaresolutions.com/" className="backlinks">local businesses,</a> traditional SEO efforts play 
                                    an important role in ranking your domain well. These efforts typically revolve around 
                                    content creation and content optimization strategies (e.g., textual content, video, and
                                    images), and strategies such as building silos of related content, and internal linking 
                                    strategies.
                                </p>
                                <img src="/images/webseo.png" alt="Software Dev Bytes performance" />
                            </div>
                        </div>
                        <div className="right_items">
                            <h2>
                            Technical <span className='softwareheader_span'>SEO</span>
                            </h2>
                            <div className="rightitem_content">
                                <img src="/images/seo.png" alt="performance" />
                                <p>
                                    Organic, non-paid optimization for local SEO and traditional SEO requires solid technical 
                                    understandings as well. Markups such as schema, page load times which are affected by 
                                    factors that include, but are not limited to,
                                     <a href="https://www.softwaredevbytes.com/mobileapp/" className="backlinks">hosting along with coding </a>
                                     and <a href="/websites" className="backlinks">website design </a> 
                                    strategies. Google's Core Web Vitals are important factors to improving rankings. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <h2 className="sub_htwo">Vital steps of our <span className="container_span">Core Strategy</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/presentation.png" alt="presentation"  />
                            <h5><a href="https://www.softwaredevbytes.com/webdev/" className="backlinks">Website Audit </a>& SEO Assessment</h5>
                            <p>
                                A critical analysis from the ground up from design infrastructure & content to link 
                                profile & brand strength. Get a Free <a href="/websites" className="backlinks">Website </a>Audit Report.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/link200.png" alt="marketing"  />
                            <h5>Detailed Back-link Audit</h5>
                            <p>
                                We look at ALL links generated for a <a href="/websites" className="backlinks">website </a>in order to determine their value and 
                                remove spammy links.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/bussprofilelist.png" alt="Business profile list"  />
                            <h5>Business Profile Listings</h5>
                            <p>
                                These are influential, industry based directories that every <a href="https://www.softwaredevbytes.com/index.html" className="backlinks">business </a>should target to
                                gain traction in Local SEO.
                            </p>
                        </div>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/content200.png" alt="Content creation" />
                            <h5>Content Creation & Marketing</h5>
                            <p>
                                High-quality, SEO optimized content (articles, blogs, press releases, social media
                                posts & guest posts) that establishes <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">Google rankings.</a>
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/keywordresearch.png" alt="marketing"  />
                            <h5>Extensive Keyword Research</h5>
                            <p>
                                The most important step in SEO, our keyword research is exhaustive and focuses on
                                "conversion keywords" that bring <a href="/digital" className="backlinks">leads.</a>
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/link200.png" alt="Link bilding" />
                            <h5>Link-building Services</h5>
                            <p>
                                We build links on authoritative sites that ultimately link back to your <a href="/websites" className="backlinks">website, </a>
                                leveraging their credibility and improving SEO.
                            </p>
                        </div>
                    </div>
                </div>
                <Reviews />
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    How Long does SEO takes?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    Unfortunately, SEO does not usually show an immediate impact. It takes time for search engines to update
                                    your site's reputation and begin ranking it higher. Typically, you should begin to see results about 
                                    3 to 6 months after updates are made. However, the most impactful results won't likely be seen until 
                                    about 12 months after changes are made. That seems like a long time, but throughout that time period, 
                                    you will notice increasingly positive changes in your metrics.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                    Is SEO Worth It?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    Yes. Though it is an investment of both time and money, SEO is something you should implement sooner 
                                    rather than later. Think of it as building an asset for your company and brand. You may not see immediate
                                    payouts as a result, but over time you will be glad you invested in the future growth of your business.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    Do you do SEO Audit?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    An SEO audit is a crucial step in the Global Mclien Software Solutions methodology. Our team will conduct a comprehensive 
                                    review of your entire SEO setup both on and off-site to develop a deep understanding of your current
                                    strategy. Specific elements of evaluation include current metadata, backlinks, content, technical setup,
                                    and keywords. We will then use these findings to develop a robust strategic plan that will help increase
                                    your ranking on search engines and start driving more traffic directly to your website.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                   How Much does SEO Cost?
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    At Global Mclien Software Solutions, our strategies are customized based on the individual business and 
                                    its unique needs and goals. So we can't give you a specific number without first reviewing your site's 
                                    current SEO. Use one of the forms above or below to request a FREE SEO audit and we will review your 
                                    current set up. From there, we can provide an estimated cost and plan for any changes that need to be made.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                    What Are SEO Backlinks?
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                    A backlink is also known as an inbound or incoming link and is created when another external website links 
                                    to your site. Such links are particularly valuable for SEO because it helps to build a positive reputation
                                    for your website and its content. This is because the backlink acts as a signal to search engines that
                                    others find the content of your website to be valuable and credible. When many different sites link back
                                    to the same webpage or website, it allows search engines to infer that the content is worth linking to, 
                                    and therefore also worth ranking higher on a search results page.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                    The Project Cost is too high
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <p>
                                    While custom software development may have a higher upfront cost than 
                                    off-the-shelf solutions, it can be more cost-effective in the long run.
                                    Custom software eliminates additional licenses or subscriptions. 
                                    It can reduce costs associated with manual processes, inefficiencies,
                                    or workarounds required with generic software.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                    What SEO Tools Do you Use
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                    When it comes to SEO there are a lot of tools available to increase the efficacy of your efforts. Our team 
                                    of experts chooses to use a combination of best-in-class tools that provide us with invaluable data about 
                                    our client's sites. These include KeywordTool.io, Moz, SEMRush, AWR Cloud, and MarketMuse. Each tool
                                    provides our team with a unique set of data, but together the entire stack paints a clear picture of any 
                                    given website's SEO strategy and any resulting changes. We can use these tools during an audit as well as
                                    after we have implemented changes to continuously monitor improvements.
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <FooterForm field="Work With Us"/>
            </div>
        </>
    )
}

export default React.memo(Seo);