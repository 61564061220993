import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {FaChalkboardTeacher } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import Reviews from './Reviews';
import FooterForm from './Footer/FooterForm';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import { Link } from 'react-router-dom';

const JavaApp =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='data management, analytics , software development ,data quality, data access, data security'/>
                <meta name='description' 
                    content="Unlock the potential of Java with our expert application development services. At Global Mclien Software Solutions, we specialize in creating robust and scalable Java applications tailored to your business needs. Our experienced team delivers high-quality solutions with a focus on performance, reliability, and security. Whether you need a web, desktop, or enterprise application, we have the expertise to bring your Java projects to life. Partner with us to streamline your development process and achieve your business goals efficiently. Contact us today to start building your next successful Java application and stay ahead of the competition!"
                    />
                <title>Global Mclien Software Solutions - Java Application Development Company</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/javapp" />
            </Helmet>
            <div className='component_container' >
                <div className="hero_container datapage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Your Premier Java Development Partner</h1>
                                <ul className="hero_message_ul">
                                    <li>Full App Dev</li>
                                    <li>Unit Testing</li>
                                    <li>JAVA Game development</li>
                                    <li>SSO Authentication</li>
                                    <li>OTP authentication</li>
                                    <li>UI | UX </li>
                                </ul>
                                <p className="hero_message_p">
                                    At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link> we specialize in 
                                    Java development solutions that drive innovation, efficiency, and success for businesses of all sizes. 
                                    With our team of experienced Java developers, we're committed to delivering high-quality software 
                                    solutions that meet your unique needs and propel your business forward.
                                </p>
                                <div>
                                  <a className="hero_message_button" href="#content_form">Get Started</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/mobileprocessone.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free <a href="/websites" className="backlinks"> Website Consultation</a></p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>creativity meets <span className="container_span">innovation.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/presentation.png" alt="presentation"  />
                            <h5>Exceptional Flexibility and Scalability</h5>
                            <p>
                                We're a <Link className="link-builder" to="/mobileapp" > Mobile App development </Link>company that adjusts to the scale of the project and is 
                                ready to attract an even greater number of highly skilled experts if necessary for the 
                                successful implementation of the project on schedule.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/delivery.png" alt="marketing"  />
                            <h5>Best Security Practices</h5>
                            <p>
                               Keep calm and don't woryy about data, system and infrastructure protection:we adhere to strict 
                               protection policy rules inherent in game development outsourcing cooperation and constatntly update 
                               security systems in accordance with the latest innovations
                        </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/customer.png" alt="presentation"  />
                            <h5>Customized Full-cycle Game Development</h5>
                            <p>
                                Our JAVA development aagency creates games carefully tailored to your specific needs and budget expectations.
                                Experienced game development <Link className="link-builder" to="/customwebsites" > custom-code developers </Link>with a customised approach to your project.
                            </p>
                        </div>
                    </div>
                 </div>
                <div className="data-objectioncontainer">
                    <div className="data-objections">
                        <h2>
                            Expertise:
                        </h2>
                        <p>
                            Our team comprises seasoned Java developers with deep expertise in Java programming language, frameworks, and
                             technologies. From enterprise applications to web development, we have the skills and experience to tackle any 
                             Java project with confidence.
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Innovation:
                        </h2>
                        <p>
                            We stay ahead of the curve by leveraging the latest advancements in Java technology and best practices. Whether 
                            it's building scalable <Link className="link-builder" to="/customwebsites" >eb applications,  </Link>wenterprise solutions, 
                            or <Link className="link-builder" to="/mobileapp" >mobile apps,  </Link>we bring innovation and creativity
                             to every project we undertake.              
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Collaborative Approach:
                        </h2>
                        <p>
                            We believe in collaborative partnerships and transparent communication with our clients. From initial consultation 
                            to project delivery, we work closely with you to understand your requirements, provide regular updates, and ensure
                             your vision is brought to life effectively.
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Quality Assurance: 
                        </h2>
                        <p>
                           Quality is our top priority at <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>. We adhere 
                           to rigorous testing standards and best practices to ensure that every Java application we deliver is robust, secure, 
                           and meets the highest standards of quality and performance.      
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Customer Satisfaction:
                        </h2>
                        <p>
                            We are dedicated to exceeding our clients' expectations and ensuring their satisfaction with our Java development 
                            services. Our commitment to responsiveness, reliability, and post-project support sets us apart and ensures a positive
                             experience for our clients.                     
                        </p>
                    </div>
                </div>
                <Reviews />
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                Java development sounds expensive.
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                    While Java development may require investment, we offer competitive pricing without compromising on quality. Plus,
                                    the scalability and reliability of Java applications often result in long-term cost savings and improved return 
                                    on investment.                               
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                How long will it take to develop my Java application?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                    Timelines vary depending on project complexity, but we're committed to delivering high-quality Java applications 
                                    within agreed-upon deadlines. Our streamlined development process and agile methodologies ensure efficient project
                                    execution without sacrificing quality.                                           
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                     Will my Java application work across different platforms and devices?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                    Java's platform independence makes it highly compatible across various operating systems, devices, and 
                                    environments. We follow best practices and conduct thorough testing to ensure your Java application functions 
                                    seamlessly across different platforms and devices.
                                </p>                          
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    Will my Java application be able to handle future growth and increased demand?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                    Java's scalability and flexibility make it ideal for building applications that can grow and adapt to changing 
                                    business needs. We design and architect Java applications with scalability in mind, ensuring they can handle 
                                    increased traffic, data volume, and functionality as your business grows.                    
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                What support do you offer after my Java application is deployed?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                    Our commitment to client satisfaction extends beyond project completion. We provide ongoing maintenance, updates,
                                    and technical support to ensure your Java application remains secure, reliable, and aligned with your evolving
                                    business requirements.                                                   
                                </p>
                            </div>
                            }
                        </div>
                     
                     </div>
                </div>
                <FooterForm field="Get started"/>
            </div>
        </>
    )
}

export default React.memo(JavaApp);