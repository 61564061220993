import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {FaChalkboardTeacher } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import Reviews from './Reviews';
import FooterForm from './Footer/FooterForm';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import { Link } from 'react-router-dom';

const HybridMobileApp =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='data management, analytics , software development ,data quality, data access, data security'/>
                <meta name='description' 
                    content="Take your app to new heights with our hybrid mobile application development services. At Global Mclien Software Solutions, we specialize in creating cross-platform apps that work seamlessly across iOS and Android devices. Our expert team leverages the latest technologies to deliver high-performance and visually stunning applications tailored to your specific needs. Whether you're a startup or a large enterprise, our hybrid approach offers faster development cycles and cost-effectiveness without compromising quality. Partner with us to reach a wider audience and maximize your app's potential. Contact us today to embark on your hybrid mobile app journey and achieve your business objectives!"
                    />
                <title>Global Mclien Software Solutions - Hybrid App Development Company</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/hybridapp" />
            </Helmet>
            <div className='component_container' >
                <div className="hero_container datapage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Transforming Your Ideas into Powerful Hybrid Mobile Apps</h1>
                                <ul className="hero_message_ul">
                                    <li>Full App Dev</li>
                                    <li>Unit Testing</li>
                                    <li>iOS & Android Deployment</li>
                                    <li>SSO Authentication</li>
                                    <li>OTP authentication</li>
                                    <li>UI | UX </li>
                                </ul>
                                <p className="hero_message_p">
                                    At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link> we specialize in creating 
                                    innovative and high-performance hybrid mobile applications that work seamlessly across multiple platforms. 
                                    With our expertise in hybrid app development, we'll bring your vision to life and help you reach a wider 
                                    audience on both iOS and Android devices.
                                </p>
                                <div>
                                  <a className="hero_message_button" href="#content_form">Get Started</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/mobileprocessone.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free <a href="/websites" className="backlinks"> Website Consultation</a></p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>creativity meets <span className="container_span">innovation.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/presentation.png" alt="presentation"  />
                            <h5>Exceptional Flexibility and Scalability</h5>
                            <p>
                                We're a <Link className="link-builder" to="/mobileapp" >Mobile App development  </Link>company 
                                that adjusts to the scale of the project and is 
                                ready to attract an even greater number of highly skilled experts if necessary for the 
                                successful implementation of the project on schedule.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/delivery.png" alt="marketing"  />
                            <h5>Best Security Practices</h5>
                            <p>
                               Keep calm and don't worry about <Link className="link-builder" to="/data" >data, </Link> system 
                               and infrastructure protection:we adhere to strict 
                               protection policy rules inherent in <Link className="link-builder" to="/mobileapp" > mobile development </Link>outsourcing cooperation and constatntly update 
                               security systems in accordance with the latest innovations
                        </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/customer.png" alt="presentation"  />
                            <h5>Customized Full-cycle Game Development</h5>
                            <p>
                                Our <Link className="link-builder" to="/mobileapp">Hybrid Mobile development agency</Link> creates 
                                apps carefully tailored to your specific needs and budget expectations.
                                Experienced game development <Link className="link-builder" to="/customwebsites" >custom-code developers  </Link>with a customised approach to your project.
                            </p>
                        </div>
                    </div>
                 </div>
                <div className="data-objectioncontainer">
                    <div className="data-objections">
                        <h2>
                            Expertise:
                        </h2>
                        <p>
                            Our team comprises seasoned <Link className="link-builder" to="/menifeedevelopers" >Hybrid developers  </Link>with deep
                             expertise in Hybrid programming language, frameworks, and
                             technologies. From enterprise applications 
                             to <Link className="link-builder" to="/customwebsites" >web development, </Link>we 
                             have the skills and experience to tackle any 
                             Hybrid project with confidence.
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Innovation:
                        </h2>
                        <p>
                            We stay ahead of the curve by leveraging the latest advancements in 
                            Hybrid technology and best practices. Whether 
                            it's building scalable <Link className="link-builder" to="/customwebsites" >web applications, </Link>enterprise solutions,
                             or <Link className="link-builder" to="/mobileapp" >mobile apps, </Link>we bring innovation and creativity
                             to every project we undertake.              
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Collaborative Approach:
                        </h2>
                        <p>
                            We believe in collaborative partnerships and transparent communication with our clients. From initial consultation 
                            to project delivery, we work closely with you to understand your requirements, provide regular updates, and ensure
                             your vision is brought to life effectively.
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Quality Assurance: 
                        </h2>
                        <p>
                           Quality is our top priority at <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>. We adhere 
                           to rigorous testing standards and best practices to ensure that every Hybrid App we deliver is robust, secure, 
                           and meets the highest standards of quality and performance.      
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Customer Satisfaction:
                        </h2>
                        <p>
                            We are dedicated to exceeding our clients' expectations and ensuring their satisfaction 
                            with our Hybrid App development 
                            services. Our commitment to responsiveness, reliability, and post-project support sets us 
                            apart and ensures a positive
                             experience for our clients.                     
                        </p>
                    </div>
                </div>
                <Reviews />
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                I'm not sure if a hybrid app is the right choice for my project.
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                Hybrid apps offer the best of both worlds, combining the versatility of 
                                <Link className="link-builder" to="/websites" > web </Link>technologies 
                                with the performance of native apps. We'll assess your project requirements and provide 
                                expert guidance on whether a hybrid approach is the most suitable option for your app.                            
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                I've heard that hybrid apps are slower than native apps.
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                While it's true that hybrid apps may have been slower in the past, advancements in 
                                technology have significantly improved their performance. With our expertise in optimizing 
                                hybrid app development, we'll ensure that your app delivers fast and responsive user 
                                experiences.                                         
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                I'm concerned about the user experience on different devices.
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                Cross-platform compatibility is a top priority in our development process. We rigorously 
                                test our hybrid apps across various devices and screen sizes to ensure a consistent and 
                                user-friendly experience for all users, regardless of the device they're using.
                                </p>                          
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                I've had a bad experience with app development agencies in the past.
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                We understand your concerns and are committed to providing a positive experience for all 
                                our clients. Our team is dedicated to clear communication, transparency, and delivering 
                                exceptional results. We'll work closely with you to address any concerns and ensure a 
                                successful collaboration.                  
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                I'm not sure if I can afford app development services.
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                We offer flexible pricing options to accommodate a wide range of budgets. 
                                Our goal is to provide affordable solutions without compromising on quality. 
                                We'll work with you to develop a customized plan that meets your needs and fits 
                                within your budget constraints.                                                  
                                </p>
                            </div>
                            }
                        </div>
                     
                     </div>
                </div>
                <FooterForm field="Get started"/>
            </div>
        </>
    )
}

export default React.memo(HybridMobileApp);