import  { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate} from 'react-router-dom';


export const EmailJsComponent = ({callToAction}) => {
  const navigate = useNavigate();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
          navigate('/sucesspage', { replace: true , callToAction });
      }, (error) => {
        alert('Network Error please wait few seconds and try again');
      });
  };

  return (
    <div className="form_container">
      <form ref={form} onSubmit={sendEmail} id='contactForm'>
         <input hidden type="text" name="Page_title" readOnly defaultValue="Global Mclien software" />
         <input hidden type="text" name="Query" readOnly defaultValue={callToAction}  />

        <div className="form_content">
          <input type="text" name="from_name" required placeholder='Full Name*' />
        </div>
        <div className="form_content">
          <input type="email" name="email" required placeholder='Email*'/>
        </div>
        <div className="form_content">
          <input type="text" name="phone" required placeholder='Phone'/>
        </div>
        <div className="form_content">
          <textarea name="message" cols="10" rows="6" placeholder='Tell us about your project...'/>
        </div>
        <div className="form_submitbuttonDiv">
          <input className="form_submitbutton" type="submit" value={callToAction ? callToAction : "Submit"}/>
          <p className="form_submitarrow">&rarr;</p>
        </div>
      </form>
    </div>
  );
};