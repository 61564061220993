import React ,{useState} from 'react';
import FooterForm from '../components/Footer/FooterForm';
import Reviews from '../components/Reviews';
import { Helmet } from 'react-helmet';
import {  Link, useNavigate } from 'react-router-dom';
import TrustFactors from '../components/TrustFactors';
import { TfiWrite } from "react-icons/tfi";
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";
import {FaChalkboardTeacher, FaRegLightbulb , FaUserSecret ,FaGlobe,FaRocket } from "react-icons/fa";
import { EmailJsComponent } from '../utility/EmailJsComponent';

const GoogleSeo =() =>{
    const navigate = useNavigate();
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='seo, ppc, branding, digital marketing, design, traffic, social media management, run ads ,online advertising'/>
                <meta name='description' 
                    content="Maximize your online advertising potential with our Google Ads services. At Global Mclien Software Solutions, we specialize in crafting targeted and impactful ad campaigns that drive results. Our expert team leverages the power of Google Ads to reach your ideal audience and boost your ROI. Whether you're looking to increase website traffic, generate leads, or drive sales, we have the expertise to create and manage successful campaigns that deliver measurable results. Partner with us to elevate your online advertising strategy and achieve your business objectives. Contact us today to unlock the full potential of Google Ads and take your business to the next level!"
                    />
                <title>Facebook Ads Agency | Full Service Marketing Agency</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/googleseo" />
            </Helmet>
            <div className='component_container'>
                <div className="hero_container digitalPage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Welcome to GMSS : Your Expert SEO Agency</h1>
                                <ul className="hero_message_ul">
                                    <li>Keyword Research</li>
                                    <li>On-Page | Offpage Optimization</li>
                                    <li>Google Adwords | Campaigns</li>
                                    <li>Google Ads</li>
                                    <li>Google My Business Optimization</li>
                                    <li>SEO Audits</li>
                                </ul>
                                <p className="hero_message_p">
                                <Link className="link-builder" to="/">GMSS </Link>is dedicated to helping businesses like yours improve 
                                their online presence and reach their target
                                    audience through effective search engine optimization (SEO) strategies.
                                     Our team of experts specializes 
                                    in <Link className="link-builder" to="/seo" >local SEO </Link>techniques tailored to the unique needs of your business.
                                </p>
                                <div>
                                    <a className="hero_message_button" href="#content_form">Book A Free Strategy Call</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/digitalmarketing.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Marketing Strategy</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Detailed strategy</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home_aboutcontainer">
                <div className="whychoose_us">
                    <div>
                        <h2>Why Choose Global Mclien Software Solutions?</h2>
                    </div>
                    <div className='paracontent-conainer'>
                        <p>
                            <span className='bold-text'>1.Expertise: </span>
                            Our team consists of experienced <Link className="link-builder" to="/seo" >SEO </Link>professionals who stay up-to-date with the latest 
                            trends and algorithms to ensure your business stays ahead of the competition.
                        </p>
                        <p>
                            <span className='bold-text'>2.Customized Strategies: </span>
                            We understand that every business is unique. That's why we tailor our <Link className="link-builder" to="/seo" >SEO </Link> strategies 
                            to meet the specific needs and goals of your business.
                        </p>
                        <p>
                            <span className='bold-text'>3.Transparent Reporting: </span>
                            We believe in transparency. With regular reports and updates, you'll always know exactly 
                            how your <Link className="link-builder" to="/seo" >SEO </Link> campaign is performing.
                        </p>
                        <p>
                            <span className='bold-text'>4.Dedicated Support: </span>
                            Our friendly and knowledgeable team is here to support you every step of the way. 
                            Have a question or need assistance? We're just a phone call or email away.
                        </p>
                        <p>
                            <span className='bold-text'>5.Proven Results: </span>
                            We have a track record of delivering tangible results for our clients. 
                            From increased <Link className="link-builder" to="/customwebsites" >website </Link>traffic
                             to higher search <Link className="link-builder" to="/localrankings" >engine rankings, </Link>we're 
                            committed to helping your business succeed online.
                        </p>
                    </div>
                </div>
                    <div className="about_ourservices">
                        <span>We Help You</span>
                        <h4>Gain A Competitive Edge</h4>
                        <div className="about_illustration">
                            <div className="illustratin_container">
                                <FaRegLightbulb />
                                <div className="illustration_content">
                                    <h6>Gain Insights on your Competition</h6>
                                    <p>
                                        We reveal how you stack up against 
                                        who <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">Google </a>
                                        thinks is your competition  and the areas you need the most improvement, then show you what your
                                        competition is doing behind the scenes to improve their own online visibility.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaUserSecret />
                                <div className="illustration_content">
                                    <h6>Grow Leads and Sales</h6>
                                    <p>
                                        Understanding your <Link className="link-builder" to="/digitalmarketing" >local market </Link>and 
                                        the competition in it is critical for growth.
                                        That's why we use our exclusive Footprint Technology to conduct a competitor and
                                        market analysis, with <a href="/websites" className="backlinks">website </a> and <a href="/seo" className="backlinks">SEO assessment </a>
                                        to reveal what's impacting your success.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaGlobe />
                                <div className="illustration_content">
                                    <h6>Track your Result</h6>
                                    <p>
                                        Managing a small business near me in Los Angeles, California can be time-consuming without 
                                        the worry of managing your digital marketing. With 
                                        <a href="/home" className="backlinks">Global Mclien Software Solutions's </a> exclusive 
                                        Dashboard reports, you can easily keep track of our team's work, 
                                        leads, and channel performance in real time and provide feedback to
                                        our team on the lead quality you're getting.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaRocket />
                                <div className="illustration_content">
                                    <h6>Strenghten your Brand</h6>
                                    <p>
                                        A solid online presence for small businesses is crucial.
                                        We start by creating a professional, fast <a href="/websites" className="backlinks">website </a>
                                        to ensure an excellent first impression. Then we, recommend getting a 
                                        <a href="/mobileapp" className="backlinks"> mobile app </a> to scale and strenghten your brand.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <a href="/home" className="backlinks">Global Mclien Software Solutions, </a>we develop marketing strategy that makes your business stay <span className="container_span">Relevant & visible online.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/marketingdata.png" alt="branding" />
                            <h5>Generate you online data</h5>
                            <p>
                                We build a comprehensive <Link className="link-builder" to="/data" >data  </Link>for your 
                                marketing campaign 
                                to perform at it's highest capabilities
                                on the major <a href="/seo" className="backlinks">search engines.</a>
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/keyword.png" alt="marketing"  />
                            <h5>Build your Keyword List</h5>
                            <p>
                                We create a detailed keyword list of your <Link className="link-builder" to="/localrankings" >top ranking </Link>competitors 
                                plug it into your campaign with periodic updates.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/contract.png" alt="presentation"  />
                            <h5>No Contracts</h5>
                            <p>
                                You decide if you want to run a month-to-month 
                                <Link className="link-builder" to="/digitalmarketing" >digital marketing campaign </Link>or retain a contract 
                                sales campaign.
                            </p>
                        </div>
                    </div>
                    <div className="other_subfeatures">
                            <div className="subfeatures_title">
                                <h2 >Our Services</h2>
                            </div>
                            <div className="subfeatures_contentcontainer">
                                <div className="subfeatures_content">
                                    <h4>SEO</h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/seo.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        We re-edit your keyword listings, update your <Link to='/seo'>seo</Link> process,
                                         get high ranking keywords from your competitors ranking top on search engines,
                                        create/revamp your <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">google my business page </a>and other local channels, create 
                                        profiles on other search engines such as bing ,start new google ad campaign 
                                        for your products/listings and create a detailed <Link className="link-builder" to="/digitalmarketing" >digital marketing </Link>strategy with 
                                        measurable progress at every stage of the process 
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card"> Google PPC Advertising </h4> 
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/ppc.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        We create an engaging and user friendly <a href="/websites" className="backlinks">website </a>that
                                         will be easy to use for your customers, which will
                                        reduce your <a href="/websites" className="backlinks">website </a>
                                         bounce rates increasing your <a href="/websites" className="backlinks">website </a>
                                         score on all <a href="/seo" className="backlinks">seacrh engines.</a>
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card">Content Marketing </h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/content.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button' className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        Building a progressive <a href="/websites" className="backlinks">web </a> app isn't just enough in the <a href="/mobileapp" className="backlinks">app </a>business,that's the reason we make sure all
                                        <a href="/websites" className="backlinks"> web </a> application is scalable from initial app versioning, multilevel stages of user authorisation,  
                                        authentication and verification all in a streamlined flow of operation.
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card"> Social Media Marketing</h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/socialmedia.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                    <Link className="link-builder" to="/websites" >Landing pages </Link>with a high conversion ratio is the bedrock of any <Link className="link-builder" to="/digitalmarketing" >digital campaign </Link>.We help both your sales team and 
                                    <Link className="link-builder" to="/digitalmarketing" >marketing </Link>team close, build pipelines and close more leads with our conversion-optimised <a href="/websites" className="backlinks">web pages.</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
                <TrustFactors />
                <div className="reviewsheader_container">
                    <div className="main_reviewsheader">
                        <div>
                            <h4>Our Reviews</h4>
                            <p>& Clients Feedbacks</p>
                        </div>
                        <Link to="/all-reviews" >See all Reviews</Link>
                    </div>
                    <Reviews />
                </div>
                <div className="whychoose_us">
                    <h2>Get Started Today</h2>
                    <p className='p_content'>
                        Ready to take your online presence to the next level? Contact us today for a
                        free consultation and discover how GMSS can help your 
                        business thrive in the digital world.
                    </p>
                    <p>
                    <span className='bold-text'>Keywords: </span>SEO agency, local SEO, Google SEO, 
                    search engine optimization, SEO services, SEO experts, SEO strategies, online presence,
                     keyword research, website optimization, SEO audits, content creation, 
                     Google My Business optimization.
                    </p>
                    <div>
                        <Link className="hero_message_button"  to="/contact">Talk To Us</Link>
                    </div>
                </div>
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                I'm not sure if SEO is worth the investment.
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                   Investing in SEO can have a significant impact on your business's online visibility 
                                   and ultimately, its success. With our proven track record of delivering tangible 
                                   results, we're confident that our SEO services will provide a strong return on 
                                   investment.

                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                    I've tried SEO before and it didn't work for me.
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                   Every SEO campaign is unique, and what may not have worked in the past doesn't mean it 
                                   won't work now. Our customized strategies and experienced team will identify any previous
                                    issues and implement effective solutions to ensure success this time around.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                I don't have the time to manage an SEO campaign.
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                   We understand that running a business keeps you busy. That's why we offer comprehensive 
                                   SEO services, handling every aspect of your campaign from start to finish. You can trust
                                    us to efficiently manage your SEO while you focus on other important tasks.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                I'm not sure how to measure the success of an SEO campaign.
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    We provide transparent reporting and regular updates on your campaign's performance. 
                                    From increased website traffic to higher search engine rankings and more leads, you'll 
                                    have clear metrics to gauge the success of your <Link className="link-builder" to="/seo" > SEO  </Link> investment.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                   Do you think SEO will help my business?
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                   It’s difficult to say without doing a keyword, website, and market analysis. That said, 
                                   for B2C and B2B organizations operating in competitive markets, 
                                   <Link className="link-builder" to="/seo" > SEO  </Link>can be a very 
                                   productive service that drives significant value. 70% of purchases start and end with 
                                   an online search, so there aren’t many scenarios where having stronger 
                                   organic Google visibility will hurt.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                I'm already ranking well on Google, so I don't need SEO.
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <p>
                                    While it's great that you're already ranking well, SEO is not a one-time effort. 
                                    Continuous optimization is necessary to maintain and improve your rankings, stay 
                                    ahead of competitors, and adapt to changes in search engine algorithms. Our services 
                                    can help ensure your business maintains its strong online presence over time.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                   What kind of assisstance can i expect after my campaign go live?
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                   You’ll receive the support of your Digital Consultant as well as a Customer Success 
                                   Manager who will help with strategy, reporting, internal communication, and asset 
                                   trafficking. In addition, you’ll have specialists working on every aspect of your 
                                   campaign to ensure consistent optimizations to deliver desired outcomes.                              
                                </p>
                            }
                        </div>
                    </div>
            </div>
                <FooterForm field="Book A Free Strategy Call"/>
            </div>
        </>
    )
}

export default React.memo(GoogleSeo);