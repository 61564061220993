import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {FaChalkboardTeacher } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import Reviews from './Reviews';
import FooterForm from './Footer/FooterForm';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import { Link } from 'react-router-dom';

const DataEngineering =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='data management, analytics , software development ,data quality, data access, data security'/>
                <meta name='description' 
                    content="Empower your data-driven decisions with Global Mclien Software Solutions.
                     Our expert team specializes in building robust data engineering solutions tailored 
                     to your business needs. From data integration to processing and analysis, 
                     we provide end-to-end services to help you extract maximum value from your data.
                      Take your business to new heights with our innovative data engineering solutions.
                       Contact us now to start harnessing the power of your data!" 
                    />
                <title>Global Mclien Software Solutions - Simplify Your Data & Cloud Management</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/data" />
            </Helmet>
            <div className='component_container' >
                <div className="hero_container datapage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Better Data Better Decisions</h1>
                                <ul className="hero_message_ul">
                                    <li>Hybrid Technologies</li>
                                    <li>Cloud Data Management</li>
                                    <li>Data | Cloud Protection</li>
                                    <li>Data Recovery</li>
                                    <li>Data Backup</li>
                                </ul>
                                <p className="hero_message_p">
                                    At <a href="/home" className="backlinks">Global Mclien Software Solutions </a> we partner with decision-makers 
                                    to enable making business decisions confidently with data, effectively, and 
                                    achieving their client's trusts. 
                                </p>
                                <div>
                                  <a className="hero_message_button" href="#content_form">Get Started</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/datanobg.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free <a href="/websites" className="backlinks"> Website Consultation</a></p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <a href="/home" className="backlinks"> Global Mclien Software Solutions, </a>Data Chaos becomes <span className="container_span">Data Clarity.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/presentation.png" alt="presentation"  />
                            <h5>Open and Flexible</h5>
                            <p>
                                Built on industry standards, this cloud platform works with any reference
                                architecture and at <a href="/home" className="backlinks"> Global Mclien Software Solutions, </a>
                                we strive for highest  Three Gartner Magic, with more focus on <Link className="link-builder" to="/data" > Data integration</Link>
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/delivery.png" alt="marketing"  />
                            <h5>Multi-cloud and Hybrid</h5>
                            <p>
                                Supports the cloud model that best suits your business for migrating on-premises
                                data with focus on <Link className="link-builder" to="/data" > data management</Link>
                        </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/customer.png" alt="presentation"  />
                            <h5>Low Code, No Code</h5>
                            <p>
                                Avoids the risk of <Link className="link-builder" to="/software" >custom coding </Link>with the power 
                                of <a href="/cloud" className="backlinks">AI and machine learning </a>in one
                                solution with focus on data quality.
                            </p>
                        </div>
                    </div>
                 </div>
                <div className="data-objectioncontainer">
                    <div className="data-objections">
                        <h2>
                            Find, reconfigure overly permissive data authorisation
                        </h2>
                        <p>
                            Sensitive data should never be stored in a file with open
                            access permissions. Over-privileged access is a key risk to data 
                            that can be reduced by only sharing sensitive data with people who
                            are authorized or granted specific permissions.   
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Sensitive data requires authorisation control
                        </h2>
                        <p>
                            Ensuring sensitive data is controlled and restricted to only
                            those who have legitimate business and legal need is crucial to
                            comply with regulations, protect proprietary company data, 
                            and maintain customer trust.                     
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Easily detect where data access control is needed
                        </h2>
                        <div>
                            <h4>Identify sensitive data stored in unsecure places</h4>
                            <p>
                                Detect sensitive data stored in open access or in locations
                                with public access.
                            </p>
                        </div>
                        <div>
                            <h4>Simplify Workflows to lock down data</h4>
                            <p>
                                Automatically trigger the removal of public links or
                                open sharing when certain data types are found in your
                                data estate. Or move data to sanctioned, secured data stores. 
                            </p>
                        </div>
                    </div>
                </div>
                <Reviews />
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                Is Global Mclien Software Solutions an offshore shop?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    No! we take pride in perfroming all work in the US.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                Is Global Mclien Software Solutions skills and experience geared more towards Database Administration  or Databases Development?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    GMSS Databases is one of the few companies which has the right combination of skills
                                     and experience in both areas ie., Administration and Development.
                                    We believe : In order to give flawless databases solutions to clients there has to 
                                    be and understanding of how SQL Server is processing the code and to do that you 
                                    have to know the <Link className="link-builder" to="/software" >code itself. </Link>Thats where we come in – as our relations database 
                                    knowledge is built upon a strong foundation of code development.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    Will it be Secure from attacks?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    Yes, your data and files will be secured with the token based encrption process. 
                                   
                                </p>
                            }
                        </div>
                     </div>
                </div>
                <FooterForm field="Get started"/>
            </div>
        </>
    )
}

export default React.memo(DataEngineering);