import React from 'react';
import { EmailJsComponent } from '../../utility/EmailJsComponent';


const FooterForm =({field}: any) =>{

    return(
        <div id="content_form">
            <div className="footer_form">
                <div className="footerformside">
                    <p>Let's talk</p>
                    <div title='click to send email' className="footer_email">
                        <p >Please fill out the form or email at :</p>
                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                            info@globalmcliensoftwaresolutions
                        </a>
                    </div>
                    <div className="footer_sideitems">
                        <span>50+</span>
                        <p>Full stack developers</p>
                    </div>
                    <div className="footer_sideitems">
                        <span>120+</span>
                        <p>Active Local Business</p>
                    </div>
                    <div className="footer_sideitems">
                        <span>14+</span>
                        <p>Years of Experience</p>
                    </div>
                </div>
                <div>
                    <EmailJsComponent callToAction={field}/>
                </div>
            </div>
        </div>
    )
}

export default FooterForm;
