import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';


const SecondLayout =() =>{

    return(
        <>
            <div className="layout-container">
                <Header />
                <div className="layout-content">
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default SecondLayout;
