import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy =() =>{

    return(
        <div className='privacydiv'>
            <h1>Privacy Policy</h1>
            <div>
                <h3 className='privacy-hthree'>Effective Date: January 2024</h3>
            </div>
            <div className="privacycontent">
                <p className='privacyp-tag'>
                Global Mclien Software Solutions ("we," "our," or "us") is committed to protecting your 
                privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your 
                information when you visit our website <Link to="www.globalmcliensoftwaresolutions.com">Global Mclien Software Solutions</Link>, 
                use our software development
                 services, or interact with us in any other way. By accessing or using our services,
                  you agree to the terms of this Privacy Policy.
                </p>
            </div>
            <div className="privacy-body">
                <h2>1. Information We Collect</h2>
                <h4>1.1 Personal Information</h4>
                <p className='privacyp-tag'>We may collect personal information that you provide to us directly, such as:</p>
                <div className='ul-boxcontent'>
                    <ul>
                        <li className='privacy-li'><span className='privacyli-span'>Contact Information: </span>Name, email address, phone number, and company name.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Account Information: </span>Username, password, and other details needed to access our services.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Payment Information: </span>Credit card details and billing address for payment processing.</li>
                    </ul>
                </div>
            </div>
            <div className="privacy-body">
                <h4>1.2 Non-Personal Information</h4>
                <p className='privacyp-tag'>We may also collect non-personal information about your interactions with our website and services, such as:</p>
                <div className='ul-boxcontent'>
                    <ul>
                        <li className='privacy-li'><span className='privacyli-span'>Usage Data: </span>IP address, browser type, operating system, pages viewed, and the date and time of your visit.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Cookies: </span>Information collected through cookies and similar tracking technologies.</li>
                    </ul>
                </div>
            </div>
            <div className="privacy-body">
                <h2>2. How We Use Your Information</h2>
                <p className='privacyp-tag'>We use the information we collect for various purposes, including:</p>
                <div className='ul-boxcontent'>
                    <ul>
                        <li className='privacy-li'><span className='privacyli-span'>Providing Services: </span>To deliver and manage our software development services and support.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Improving Our Services: </span>To understand how our services are used and to enhance user experience.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Communication: </span> To send you updates, newsletters, and other information about our services.</li>
                        <li className='privacy-li'><span className='privacyli-span'> Security: </span> To protect against unauthorized access, fraud, and other security issues.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Compliance: </span>To comply with legal obligations and respond to legal requests.</li>
                    </ul>
                </div>
            </div>
            <div className="privacy-body">
                <h2>3. How We Share Your Information</h2>
                <p className='privacyp-tag'>We do not sell, trade, or otherwise transfer your personal information to outside parties, except in the following circumstances:</p>
                <div className='ul-boxcontent'>
                    <ul>
                        <li className='privacy-li'><span className='privacyli-span'>Service Providers: </span>We may share your information with third-party service providers who assist us in operating our website, conducting our business, or providing services to you.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Legal Requirements:  </span>We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Business Transfers:</span> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.</li>
                    </ul>
                </div>
            </div>
            <div className="privacy-body">
                <h2>4. Security of Your Information</h2>
                <p className='privacyp-tag'>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>
            </div>
            <div className="privacy-body">
                <h2>5. Your Choices and Rights</h2>
                <p className='privacyp-tag'>You have certain rights regarding your personal information, including:</p>
                <div className='ul-boxcontent'>
                    <ul>
                        <li className='privacy-li'><span className='privacyli-span'>Access: </span>You can request access to the personal information we hold about you.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Correction: </span>You can request that we correct or update your personal information.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Deletion: </span> You can request that we delete your personal information.</li>
                        <li className='privacy-li'><span className='privacyli-span'>Opt-Out: </span> You can opt out of receiving marketing communications from us by following the unsubscribe instructions included in those communications.</li>
                    </ul>
                </div>
                <p className='privacyp-tag'>To exercise these rights, please contact us using the contact information provided below.</p>
            </div>
            <div className="privacy-body">
                <h2>6. Third-Party Links</h2>
                <p className='privacyp-tag'>Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these third-party websites. We encourage you to review the privacy policies of any third-party websites you visit.</p>
            </div>
            <div className="privacy-body">
                <h2>7. Changes to This Privacy Policy</h2>
                <p className='privacyp-tag'>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the effective date. Your continued use of our services after any changes constitutes your acceptance of the revised Privacy Policy.</p>
            </div>
            <div className="privacy-body">
                <h2>8. Contact Us</h2>
                <p className='privacyp-tag'>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>
                <div className="privacy-body">
                        <Link to="/">Global Mclien Software Solutions</Link>
                    <address>
                        25630 Felicia Avenue , Menifee,
                        CA92586
                    </address>
                    <a href="mailto:developer@softwaredevbytes.com">info@globalmcliensoftwaresolutions.com</a>
                    <p>
                    <a href="tel:+17472498760">7472498760</a>

                    </p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(PrivacyPolicy);