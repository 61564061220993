import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions =() =>{

    return(
        <div className='privacydiv'>
        <h1>Terms and Conditions</h1>
        <div className="privacycontent">
            <p className='privacyp-tag'>
                Welcome to Global Mclien Software Solutions! These terms and conditions outline the rules and regulations
                for the use of Global Mclien Software Solutions's Website, located at <Link to="/">https://www.globalmcliensoftwaresolutions.com</Link>.
                By accessing this website, we assume you accept these terms and conditions. 
                Do not continue to use Global Mclien Software Solutions if you do not agree to 
                take all of the terms and conditions stated on this page.
            </p>
        </div>
        <div className="privacy-body">
            <h2>1. Definitions</h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'><span className='privacyli-span'>Company: </span>Global Mclien Software Solutions, referred to as "Company," "We," "Us," or "Our."</li>
                    <li className='privacy-li'><span className='privacyli-span'>Client: </span>The individual, company, or entity utilizing the services provided by the Company.</li>
                    <li className='privacy-li'><span className='privacyli-span'>Service: </span> The software development and related services offered by the Company.</li>
                    <li className='privacy-li'><span className='privacyli-span'> Website: </span><Link to="/">https://www.globalmcliensoftwaresolutions.com</Link></li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>2. Use of the Website</h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>By accessing our website, you warrant and represent that you are at least 18 years old and have the legal authority to enter into these terms and conditions.</li>
                    <li className='privacy-li'>You agree to use the website in compliance with all applicable laws and regulations.</li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>3. Services</h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>The Company provides software development services as outlined on our website.</li>
                    <li className='privacy-li'>A detailed proposal and contract will be provided for each project, specifying the scope, deliverables, timeline, and payment terms.
                    </li>
                </ul>
            </div>
        </div>
        
        <div className="privacy-body">
            <h2>4. Intellectual Property</h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>Unless otherwise stated,Global Mclien Software Solutions and/or its licensors own the intellectual property rights for all material on <Link to="/">https://www.globalmcliensoftwaresolutions.com</Link>. All intellectual property rights are reserved.
                    </li>
                    <li className='privacy-li'>You may access this from <Link to="/">https://www.globalmcliensoftwaresolutions.com</Link> for your personal use, subject to restrictions set in these terms and conditions.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>5. User Responsibilities</h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>You agree not to use our website or services for any unlawful purpose or in a way that may cause harm to the Company or any third party.
                    </li>
                    <li className='privacy-li'>You are responsible for providing accurate information and ensuring timely communication during the project.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>6. Payment Terms
            </h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>Payment terms are outlined in the project proposal and contract. Typically, a 40% deposit is required upfront, with the balance divided over the completion of project milestones.
                    </li>
                    <li className='privacy-li'>Late payments may incur additional charges as specified in the contract.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>7. Refund Policy
            </h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>Clients may cancel their project before the completion of the first milestone and receive an 85% refund of the initial deposit.
                    </li>
                    <li className='privacy-li'>Refunds are only issued if the project cannot be completed. Refund processing takes up to 10 business days.
                    </li>
                    <li className='privacy-li'>A non-refundable fee of 15% of the initial deposit applies to all projects.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>8. Limitation of Liability
            </h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>The Company is not liable for any indirect, incidental, or consequential damages arising from the use of our services or website.
                    </li>
                    <li className='privacy-li'>Our total liability for any claims related to our services shall not exceed the amount paid by the Client to the Company for the specific project.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>9. Confidentiality
            </h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>Both parties agree to keep all information related to the project confidential and not disclose it to any third party without prior written consent.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>10. Dispute Resolution
            </h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>Any disputes arising from the use of our services will be resolved by granting additional reviews of the project.
                    </li>
                    <li className='privacy-li'>If the dispute cannot be resolved amicably, it will be referred to mediation or arbitration as specified in the contract.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>11. Changes to Terms and Conditions
            </h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>The Company reserves the right to update or modify these terms and conditions at any time. Changes will be effective immediately upon posting on the website.
                    </li>
                    <li className='privacy-li'>It is your responsibility to review the terms and conditions periodically for any changes.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>12. Governing Law
            </h2>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State], and you submit to the non-exclusive jurisdiction of the courts located in California for the resolution of any disputes.
                    </li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>13. Contact Us</h2>
            <p className='privacyp-tag'>For any questions or concerns regarding these terms and conditions, please contact us at:</p>
            <div className="privacy-body">
                    <Link to="/">Global Mclien Software Solutions</Link>
                <address>
                    25630 Felicia Avenue , Menifee,
                    CA92586
                </address>
                <a href="mailto:developer@softwaredevbytes.com">info@globalmcliensoftwaresolutions.com</a>
                <p>
                <a href="tel:+17472498760">7472498760</a>
                </p>
                <p>
                By using our website and services, you acknowledge that you have read, understood, and agreed to be bound by these terms and conditions. Thank you for choosing Global Mclien Software Solutions!
                </p>
            </div>
        </div>
    </div>
    )
}

export default React.memo(TermsAndConditions);