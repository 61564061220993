import React ,{useState , useRef } from 'react';
// import { Link } from 'react-router-dom';
import { EmailJsComponent } from '../../utility/EmailJsComponent';
import { Helmet } from 'react-helmet';

const getInitialInputState = () => ({
    fullName:'',
    phoneNumber:'',
    jobTitle:'',
    email:'',
    company:'',
    message:'',
    newProject:'',
    existingProject:'',
    rebuildProject:'',
    fourteenDays:'',
    thirtyDays:'',
    fourMonths:''
  });

  interface IInputState {
    fullName:string,
    phoneNumber:string,
    jobTitle:string,
    email:string,
    company:string,
    message:string,
    newProject:string,
    existingProject:string,
    rebuildProject:string,
    fourteenDays:string,
    thirtyDays:string,
    fourMonths:string
  }
  

const Pricing =() =>{
    const [value, setValue] = useState<IInputState>(
        getInitialInputState()
    );
    const [checkednewProject, setCheckedNewProject] = useState<boolean>(false);
    const [checkedExistingProject, setCheckedExistingProject] = useState<boolean>(false);
    const [checkedRebuildProject, setCheckedRebuildProject] = useState<boolean>(false);
    const [checkedFourteendays, setCheckedFourteendays] = useState<boolean>(false);
    const [checkedThirtyDays, setCheckedThirtyDays] = useState<boolean>(false);
    const [checkedFourMonths, setCheckedFourMonths] = useState<boolean>(false);
    const messageRef = useRef<null | HTMLTextAreaElement>(null);
   
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
            setValue((prevState: IInputState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const formRef = useRef<HTMLFormElement>(null);

    const handleSUbmit = (e:any)=>{
        e.preventDefault();
        alert("submitted");
        console.log(
            value.fullName,
            value.phoneNumber,
            value.email,
            value.company,
            value.message,
            value.fourMonths,
            value.jobTitle,
            value.rebuildProject,
            checkednewProject,
            checkedExistingProject,
            checkedRebuildProject,
            checkedFourteendays,
            checkedThirtyDays,
            checkedFourMonths,
             messageRef.current?.value,
        );
        setValue(getInitialInputState());
        formRef.current?.reset();
    };

    return(
        <div className='component_container ' >
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='Reviews, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance donations'
                    />
                <title>Donations - Global Mclien Software Solutions | Mobile Apps Reviews</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/pricing" />
            </Helmet>
            <h2 className='pricing_header'>We offer two ways to get pricing information.</h2>
            <p className='pricing_paragraph'>Choose a path and get your Price.</p>
            <div className="pricing-container">
                <div className="pricing-containerone">
                    <div className="pricingcontainerone-header">
                        <div >
                            <span>1</span>
                            <h3>Get Self-Service Pricing Now</h3>
                        </div>
                        <p>
                            This option sends estimated pricing based on your selections via email follow-up. 
                        </p>
                    </div>
                    <div className="pricing-formcontainer">
                        <form onSubmit={handleSUbmit} ref={formRef} id='contact_form'>
                            <div className='form_content'>
                                <input 
                                    required 
                                    type="text"
                                    placeholder='Full Name*'
                                    name='fullName'
                                    value={value.fullName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form_content'>
                                <input
                                    required 
                                    type="text"
                                    placeholder='Phone Number*'
                                    name='phoneNumber'
                                    value={value.phoneNumber}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form_content'>
                                <input
                                    required 
                                    type="text"
                                    placeholder='Job Title*'
                                    name='jobTitle'
                                    value={value.jobTitle}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form_content'>
                                <input 
                                    required 
                                    type="email"
                                    placeholder='Email*'
                                    name='email'
                                    value={value.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form_content'>
                                <input 
                                    required 
                                    type="text"
                                    placeholder='Company*'
                                    name='company'
                                    value={value.company}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form_content'>
                                <textarea 
                                    name="message"
                                    cols={10} 
                                    rows={6}
                                    placeholder='End Goal of your project' 
                                    ref={messageRef}
                                />
                            </div>
                            <div className="pricing-checkbox">
                                <div className='form_content checkBox' title='Is it a new project?'>
                                    <label htmlFor="newproject">
                                        New Project
                                      <input type="checkbox" onChange={() => setCheckedNewProject((prev) => !prev)} name="newproject" id="" />
                                    </label>
                                </div>
                                <div className='form_content checkBox' title='Is it an Existing project?'>
                                    <label htmlFor="newproject">
                                        Existing Project
                                        <input type="checkbox" onChange={() => setCheckedExistingProject((prev) => !prev)} name="" id="" />
                                    </label>
                                </div>
                                <div className='form_content checkBox' title='Is it a Rebuilt project?'>
                                    <label htmlFor="newproject">
                                        Rebuild Project
                                        <input type="checkbox" onChange={() => setCheckedRebuildProject((prev) => !prev)} name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="pricing-checkbox">
                                <div className='form_content checkBox'>
                                    <label htmlFor="newproject">
                                        14-Days
                                        <input type="checkbox" onChange={() => setCheckedFourteendays((prev) => !prev)} name="" id="" />
                                    </label>
                                </div>
                                <div className='form_content checkBox'>
                                    <label htmlFor="newproject">
                                        30-Days
                                       <input type="checkbox" onChange={() => setCheckedThirtyDays((prev) => !prev)} name="" id="" />
                                    </label>
                                </div>
                                <div className='form_content checkBox'>
                                    <label htmlFor="newproject">
                                        4-Months
                                        <input type="checkbox" onChange={() => setCheckedFourMonths((prev) => !prev)} name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className='form_submitbuttonDiv'>
                                <input type="submit" className='form_submitbutton ' value="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="pricing-containertwo">
                    <div className="pricingcontainerone-header">
                        <div>
                            <span>2</span>
                            <h3>Request a Quote</h3>
                        </div>
                        <p>
                        This option will send an email to our Sales Team who will call you.                        </p>
                    </div>
                    <EmailJsComponent callToAction='Get Quote' />

                </div>
            </div>
        </div>
    )
}

export default React.memo(Pricing);