import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Reviews from '../../Reviews';
import {IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { TfiWrite } from "react-icons/tfi";
import {FaChalkboardTeacher } from "react-icons/fa";
import { BsTelephone } from 'react-icons/bs';
import { EmailJsComponent } from '../../../utility/EmailJsComponent';


const MobileAppSantaBarbara =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='mobile application, Apps, iOS, Playstore, Android, Java,Kotlin, scalability , software development '/>
                <meta name='description' 
                    content='We Develop mobile applications with features accepted on both iOS and Playstore.
                    Assisst your clients scale their business by offering your market-ready mobile application solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>Global Mclien Software Solutions - Mobile Applications Developer Santa Barbara</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/mobile-app-development-santa-barbara" />
            </Helmet>
            <div className="landingpage-content">
                <div className='lpleft-side'>
                    <p className='lpage-top'>Looking for a<Link className="link-builder" to="/mobileapp"> Mobile App Developer </Link>in Santa Barbara?</p>
                    <h1>
                        Transform Your Vision into Reality with <span className='lphero-span'>Custom Mobile Apps</span>
                    </h1>
                    <p className='lp-subheader'>Empowering Your Business with Innovative<Link className="link-builder" to="/mobileapp"> Mobile Solutions.</Link></p>
                   <div className="button_containers">
                        <div className='cta-button'>
                            <Link to='#contactform'>Free Consultation</Link>
                        </div>
                        <div className='cta-otherbutton'>
                            <Link to="/mobileapp">Learn More</Link>
                        </div>
                   </div>
                </div>
                <div className='lpright-side'>
                    <img src="/images/mblpage1.png" alt="Global Mclien Software Solutions" />                    
                </div>
            </div>
            <div className="lpage-body">
                <div className='lpagebody-content'>
                    <h2>Your are not located near Me.</h2>
                    <p>
                      We successfully work with clients worldwide through various communication 
                      tools such as video conferencing, emails, and instant messaging. Our team 
                      is flexible and can schedule meetings at times convenient for you, ensuring
                       smooth and consistent communication.Get in Touch with us for mobile application 
                       development near me.
                    </p>
                    <div className='cta-button'>
                        <Link to='#contactform'>Free Consultation</Link>
                    </div>
                </div>
                <div className='lpagebody-content lp-conclusion'>
                    <h2>Why Choose Our Mobile App Development Services in Santa Barbara</h2>
                    <p>
                        <Link className="link-builder" to='/'>Global Mclien Software Solutions </Link>is a leading <Link className="link-builder" to="/mobileapp">mobile app development </Link> 
                        company dedicated to delivering innovative solutions that drive business 
                        success. With years of experience and a passion for technology, 
                        we help businesses create impactful mobile applications.
                    </p>
                    <div className='cta-button'>
                        <Link to='#contactform'>Free Consultation</Link>
                    </div>
                </div>
                <div className='lpagebody-content'>
                    <h2>How will you understand the Santa Barbara local market needs?</h2>
                    <p>
                        We conduct thorough market research and take the time to understand your 
                        local market and target audience. Our team collaborates with you closely to
                        ensure the app meets the specific needs of your region and customers in Santa Barbara.
                    </p>
                    <div className='cta-button'>
                        <Link to='#contactform'>Free Consultation</Link>
                    </div>
                </div>
                <div className='lp-spacer'>
                    <div className="getting_started">
                <div className="gts_header">
                    <div></div>
                    <h3>Getting Started </h3>
                    <div></div>
                </div>
                <div className="get_startedcontainer">
                    <div className="getting_starteditem">
                        <IoChatbubblesOutline />
                        <div className="getstarted_item">
                            <p className="getstarted_P">Step 1</p>
                            <p className="getstartedP">Contact us</p>
                        </div>
                    </div>
                    <div className="gts_arrow">
                      <p> &#62;</p>
                    </div>
                    <div className="getting_starteditem">
                        <FaChalkboardTeacher />
                        <div className="getstarted_item">
                            <p className="getstarted_P">Step 2</p>
                            <p className="getstartedP">Free Idea and Concept Consultation</p>
                        </div>
                    </div>
                    <div className="gts_arrow">
                        <p> &#62;</p>
                    </div>
                    <div className="getting_starteditem">
                        <TfiWrite />
                        <div className="getstarted_item">
                            <p className="getstarted_P">Step 3</p>
                            <p className="getstartedP">Develop &amp; Email Proposal</p>
                        </div>
                    </div>
                </div>
                <div className="getin_touch">
                    <div className="getin_touchheader">
                        <span className='container_span bigspan'>Call Us </span> 
                        <span>or</span>
                        <h3>Have us Call You</h3>
                    </div>
                    <div className="getin_touchform">
                        <div className="getintouchaddress">
                            <h4>Office Locations</h4>
                            <ul>
                                <li>Menifee, CA</li>
                                <li>New York, NY</li>
                                <li>Chicago, IL</li>
                                <li>Lagos,Nigeria</li>
                            </ul>
                            <div className="getintouchdivider">
                                <h4>Contact Us</h4>
                                <div title='click to call' className="getintouchdivider_div">
                                    <BsTelephone />
                                    <a href="tel:+7472498760">747.249.8760</a>
                                </div>
                                <div title='click to send email' className="getintouchdivider_div">
                                    <IoMailOutline />
                                    <a href="mailto:developer@softwaredevbytes.com">
                                        developer@softwaredevbytes.com
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="getin_touchformelement">
                            <EmailJsComponent callToAction='Get In Touch'/>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
                <div className='lpagebody-content'>
                    <h2> Our <Link className="link-builder" to="/mobileapp">Mobile App </Link>Development Services</h2>
                    <p>
                        We offer a comprehensive suite of <Link className="link-builder" to="/mobileapp">mobile app development services </Link>tailored to meet your business needs:
                    </p>
                    <ul className='lpagebody_ul'>
                        <li>
                            <span className='lpagebody_span'>Custom App Development:</span>
                            Tailored solutions for <Link className="link-builder" to="/iosapp">iOS </Link> and <Link className="link-builder" to="/androidapp">Android.</Link>
                        </li>
                        <li><span className='lpagebody_span'>UI/UX Design: </span>Engaging and intuitive user interfaces.</li>
                        <li><span className='lpagebody_span'>App Maintenance & Support: </span>Ongoing support to ensure app success.</li>
                        <li><span className='lpagebody_span'>Consultation Services: </span>Expert guidance from concept to launch.</li>
                    </ul>
                    <div className='cta-button'>
                        <Link to='#contactform'>Free Consultation</Link>
                    </div>
                </div>
                <div className='lppage-margin'>
                    <div className="reviewsheader_container ">
                        <div className="main_reviewsheader">
                            <div>
                                <h4>Our Reviews</h4>
                                <p>& Clients Feedbacks</p>
                            </div>
                            <Link to="/all-reviews" >See all Reviews</Link>
                        </div>
                        <Reviews />
                    </div>
                    <div className="app_faqscontainer ">
                        <h3>Frequently Asked Questions</h3>
                        <div className="app_faqselement">
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(1)}>
                                        Can you build Apps for both iOS and Android?
                                    </h3>
                                    {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                                </div>
                                {(showFaqParagraph === 1) && 
                                    <p>
                                        Yes, the majority of the apps we create are compatible with both iOS and Android. People
                                        frequently want their applications to function on both mobile and web devices, therefore
                                        we employ development frameworks like React and React Native to swiftly support both
                                        platforms.                              
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(2)}>
                                        How long does App development take?
                                    </h3>
                                    {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 2) && 
                                    <p>
                                        A successful project launch depends on a variety of elements, and some criteria can
                                        make the project take longer to build than others. After we have comprehended the 
                                        , only then can we communicate the estimated time.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(3)}>
                                        How much will app development cost?
                                    </h3>
                                    {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 3) && 
                                    <p>
                                        The cost of the development of the mobile app depends on the requirements of the app.
                                        We can only give you an estimate after listening to all the requirements.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(4)}>
                                    What if i want any modification after launching my app?
                                    </h3>
                                    {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 4) && 
                                    <p>
                                        The team at Blitz Mobile Apps is highly talented and can develop anything you like
                                        with extreme professionalism. Our developers believe in a customer-centric approach,
                                        therefore, developing very user-friendly yet attractive applications.  
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(5)}>
                                        What is the role of a mobile app developer?
                                    </h3>
                                    {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 5) && 
                                    <p>
                                        A mobile app developer creates, tests, and develops applications for mobile devices 
                                        using programming languages and development expertise. They generally consider UI 
                                        and UX ideas when developing applications and work in well-known operating systems
                                        environments like iOS and Android.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(6)}>
                                        How much does it cost of hiring a developer ?
                                    </h3>
                                    {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 6) && 
                                    <p>
                                        The cost of hiring a mobile app developer mostly depends on two factors: the 
                                        technology used for development and the developer's level of experience.
                                        However, Resource offers app developers for hire in India for, on average, 
                                        $49 to $100 per hour.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(7)}>
                                    What kind of assisstance can i expect during the development process?
                                    </h3>
                                    {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 7) && 
                                    <p>
                                        Your personal project manager will be your primary point of contact for the duration
                                        of the development process. Our project managers handle all of your requests and 
                                        inquiries to make sure we can respond to you quickly and prioritize your work 
                                        appropriately.                               
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lpagebody-content lp-conclusion'>
                <h2>About Global Mclien Software Solutions</h2>
                    <p>
                        <Link className="link-builder" to="/">Global Mclien Software Solutions </Link>is a leading 
                        <Link className="link-builder" to="/mobileapp"> mobile app development </Link>
                        company dedicated to delivering innovative solutions that drive business 
                        success. With years of experience and a passion for technology, 
                        we help businesses create impactful <Link className="link-builder" to="/mobileapp">mobile applications.</Link>
                    </p>
                <div className='cta-button'>
                    <Link to='#contactform'>Free Consultation</Link>
                </div>
            </div>
        </>
    )
}

export default React.memo(MobileAppSantaBarbara);