import React from 'react';
import { Helmet } from 'react-helmet';
import {  useLocation } from 'react-router-dom';

const PaymentPage =() =>{
    let location = useLocation();
    const handleSubmit = ()=>{
        alert('Payment succesfull');
    }

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>Payment - Global Mclien Software Solutions</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/payment" />
            </Helmet>
            <div className="paymentPage">
                <div className="payment_container">
                    <div className="payment_header">
                        <h4>Payment Information</h4>
                        <div className="payment_headerElement">
                            <h6>Amount to be Paid:</h6>
                            <h6 className="payment_headerPrice">${location?.state?.price}</h6>
                        </div>
                    </div>
                    <div className="paymentItems">
                        <div>
                            <div className="paymentItems_element">
                                <h5>Payment Method:</h5>
                                <button type='button' className="credit_buttons credit">Credit Card</button>
                                <button type='button' className="credit_buttons paypal" >
                                    <img src="/images/paypal.png"  className="credit_image" alt="Global mclien gear on a screen" />
                                </button>
                            </div>
                            <div className='paymentform_content'> 
                                <form  className='form_cards' onSubmit={handleSubmit} id='payment_form'>
                                    <div className='cardElements_items'>
                                        <label htmlFor="card-holder">
                                            Card Holder Name
                                        </label>
                                        <input 
                                        type="text"
                                         name="cardholdername" 
                                         id="" 
                                         placeholder='John Doe'
                                         />
                                    </div>
                                    <div className='cardElements_items'>
                                        <label htmlFor="card-holder">
                                            Card Number
                                        </label>
                                        <input 
                                        type="text" 
                                        name="cardholdername" 
                                        id="" 
                                        placeholder='4545 1234 5678 0011'
                                        />
                                    </div>
                                    <div className='card_elements'>
                                        <div className='cardElements_items middle'>
                                            <label htmlFor="card-holder">
                                                Expiration Date:
                                            </label>
                                            <input
                                             type="text"
                                              name="cardholdername"
                                               id="" 
                                               placeholder='05/29'
                                               />
                                        </div>
                                        <div className='cardElements_items'>
                                            <label htmlFor="card-holder">
                                                CCV
                                            </label>
                                            <input 
                                            type="text"
                                             name="cardholdername"
                                              id=""
                                              placeholder='578'
                                               />
                                        </div>
                                    </div>
                                    <div className='cardElements_items'>
                                        <label htmlFor="card-holder">
                                            Zip Code
                                        </label>
                                        <input 
                                        type="text" 
                                        name="cardholdername"
                                         id="" 
                                         placeholder='96581'
                                         />
                                    </div>
                                    <div className="payment_buttons">
                                        <a className="paymentbutton_item cancel" href='https://buy.stripe.com/aEU5nm6R7aVB7ks5kk' type="button">Cancel</a>
                                        {/* <button className="paymentbutton_item cancel" onClick={() => navigate(-1)} type="button">Cancel</button> */}
                                        <button className="paymentbutton_item next" onClick={() => alert('Payment Successfull')} type="button">Next</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="payment_image">
                            <img src="/images/creditcard.png" alt="Global mclien gear on a screen" />
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default React.memo(PaymentPage);