import React ,{useState} from 'react';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import {Helmet} from "react-helmet";
import {Link, useNavigate} from 'react-router-dom';
import { IoCodeSlashOutline ,IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { BiUserX ,BiGlassesAlt } from "react-icons/bi";
import { FaRegHandshake ,FaChalkboardTeacher} from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import FooterForm from './Footer/FooterForm';
import Reviews from './Reviews';


const Software =() =>{
    const[showMoreIndustries, setShowMoreIndustries] = useState(false);
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);
    const navigate = useNavigate();

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability , software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='Elevate your business with cutting-edge software solutions from Global Mclien Software Solutions.
                     Our expert team delivers custom software development services tailored to your needs. From web and mobile applications to enterprise solutions, trust us to bring your vision to life. Contact us today'
                    />
                <title>Global Mclien Software Solutions - Software | Custom Software Solutions</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/software" />
            </Helmet>
            <div className='component_container'>
                <div className="hero_container softwarePage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Software Development</h1>
                                <ul className="hero_message_ul">
                                    <li>Software Engineering &amp; Consultation</li>
                                    <li>Desktop Applications</li>
                                    <li>Saas Application Development</li>
                                    <li>Software Development Outsourcing Services</li>
                                    <li>Custom Software Development</li>
                                </ul>
                                <p className="hero_message_p">
                                    Get a Custom Software developed by our expert Software developers with
                                    Software designs, development &amp; Continous integration. 
                                    Get Software Solutions built from scratch or from code-takeover Software projects.
                                </p>
                                <div>
                                    <a className="hero_message_button" href="#content_form">Get Started</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/softwarebackground.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Project Consultation</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>

                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="reason_container">
                        <div className="reason_element">
                            <IoCodeSlashOutline />
                            <p>Source Code Ownership</p>
                            <div>
                                <p className="reason_elementP">
                                We build it, you own it. It's that simple. Once we finish your software development,
                                we pass over the entire source code so that your software project is
                                yours - no fees or strings attached. Whether you want to continue 
                                working with us for software maintenance and updates, or take the project internal 
                                is up to you.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <BiUserX />
                            <p>No third Parties</p>
                            <div>
                                <p className="reason_elementP">
                                You know what they say - three is a crowd. That's why we keep everything 
                                in-house. Our  <Link className="link-builder" to="/about">software developers, designers, project managers,  </Link> 
                                and everything in 
                                between are all our own vetted and trained development team members. This allows us
                                to ensure high-quality and on-time projects.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <FaRegHandshake/>
                            <p>Local & Accountable</p>
                            <div>
                                <p className="reason_elementP">
                                We value in-person interactions and believe that face-to-face problem 
                                solving is critical for a successful software project. We know trust is earned,
                                so we want to be more than just a brand name on a computer screen - we want
                                you to see our office and to meet our development team. By being local 
                                , we know that you can hold us accountable.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <BiGlassesAlt />
                            <p>Transparency</p>
                            <div>
                                <p className="reason_elementP">
                                <Link className="link-builder" to="/">Global Mclien Software solutions </Link>is built on long-term partnerships, which means that we
                                want to earn your trust and keep it. We do this through our commitment 
                                to absolute transparency about us, our software processes, our software projects, and your project. 
                                While the frequency of  software updates will be at your discretion, we will always
                                keep you up to date on the status of your project, budget, risks, timelines, 
                                and blockers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buyerpersona_container persona_one">
                    <div className="persona_content">
                        <div className="personacontentside">
                            <p className="personaheader_p">Looking for custom code tailored for your business?</p>
                            <h3 className="personacontentside_hthree">
                                Create Scalable Solutions with Custom-codes.
                            </h3>
                            <div className="personacontentside_div">
                                <p>With solutions such as: </p>
                                <ul className="personacontentside_ul">
                                    <li>Point-to-point integration</li>
                                    <li>Easy Features modification</li>
                                    <li>Reduced dependency on third party apps</li>
                                    <li>Code Documentaion</li>
                                    <li>Flexibility and compatibility with borwsers </li>
                                </ul>
                            </div>
                            <div>
                                <p className="personacontentside_p">
                                    Saving your business time and resources when making 
                                    changes to your <Link className="link-builder" to="/digitalmarketing" >digital solutions, </Link>refactoring and adding new features.
                                </p>
                            </div>
                            <div className="persona_button">
                                <Link className="persona_buttonOne" to='/contact'>Request A Free Demo</Link>
                                <Link className="persona_buttonTwo" to='/softwarepackages'>View Packages</Link>
                            </div>
                        </div>
                        <div className="personacontentside">
                            <img className="personacontentside_img" src="/images/backdropp.png" alt="Global Mclien software solutions" />
                        </div>
                    </div>
                </div>
                <div className="buyerpersona_container persona_two">
                    <div className="persona_content">
                        <div className="personacontentside">
                            <p className="personaheader_p">Are You an property owner, realestate business, commercial real estate ?</p>
                            <h3 className="personacontentside_hthree">
                                Real Estate Automation with Software Solutions
                            </h3>
                            <div className="personacontentside_div">
                                <p>With solutions such as: </p>
                                <ul className="personacontentside_ul">
                                    <li>Automated Tenant Screening</li>
                                    <li>Automated Rent collection</li>
                                    <li>Easy rental listings</li>
                                    <li>One-click Finacial report download</li>
                                    <li>Light weight application form downloads </li>
                                </ul>
                            </div>
                            <div>
                                <p className="personacontentside_p">
                                    Save lots of hours by automating your real estate processes and making client's portfolio creation
                                    a walk in the park.
                                </p>
                            </div>
                            <div className="persona_button">
                                <Link className="persona_buttonOne" to='/contact'>Request A Free Demo</Link>
                                <Link className="persona_buttonTwo" to='/softwarepackages'>View Packages</Link>
                            </div>
                        </div>
                        <div className="personacontentside">
                            <img className="personacontentside_img" src="/images/realestate.png" alt="Global Mclien software solutions" />
                        </div>
                    </div>
                </div>
                <div className="buyerpersona_container persona_three">
                    <div className="persona_content">
                        <div className="personacontentside">
                            <p className="personaheader_p">Are You an entrepreneur, startup, local business owner ?</p>
                            <h3 className="personacontentside_hthree">
                                Software Solutions for Businesses
                            </h3>
                            <div className="personacontentside_div">
                                <p>With Features like: </p>
                                <ul className="personacontentside_ul">
                                    <li>Offline Access</li>
                                    <li>Easy Accounting and reporting</li>
                                    <li>Fast Scheduling</li>
                                    <li>Automated booking</li>
                                    <li>Integrated Payment </li>
                                </ul>
                            </div>
                            <div>
                                <p className="personacontentside_p">
                                    scale your business by automating the booking process with little or no technical experience 
                                    and no account creation 
                                </p>
                            </div>
                            <div className="persona_button">
                                <Link className="persona_buttonOne" to='/contact'>Request A Free Demo</Link>
                                <Link className="persona_buttonTwo" to='/softwarepackages'>View Packages</Link>
                            </div>
                        </div>
                        <div className="personacontentside">
                            <img className="personacontentside_img" src="/images/smb.png" alt="Global Mclien software solutions" />
                        </div>
                    </div>
                </div>
                <div className="buyerpersona_container persona_four">
                    <div className="persona_content">
                        <div className="personacontentside">
                            <p className="personaheader_p">Are You an Hospital, blood bank, looking for software developer near you ?</p>
                            <h3 className="personacontentside_hthree">
                                Enterprise Software Solutions for Healthcare Field.
                            </h3>
                            <div className="personacontentside_div">
                                <p>With key Features like: </p>
                                <ul className="personacontentside_ul">
                                    <li>Importing Existing Data</li>
                                    <li>Capturing New Data</li>
                                    <li>User Friendly Interfaces</li>
                                    <li>Data Management</li>
                                    <li>Incident Capturing interfaces</li>
                                </ul>
                            </div>
                            <div>
                                <p className="personacontentside_p">
                                    Empowering facilies to reduce overall risk, increase employee safety 
                                    ,improved operational performance and achieve a streamlined compliance that 
                                    regulates both internal and third party audit.
                                </p>
                            </div>
                            <div className="persona_button">
                                <Link className="persona_buttonOne" to='/contact'>Request A Free Demo</Link>
                                <Link className="persona_buttonTwo" to='/softwarepackages'>View Packages</Link>
                            </div>
                        </div>
                        <div className="personacontentside">
                            <img className="personacontentside_img" src="/images/personaimage.png" alt="Global Mclien software solutions" />
                        </div>
                    </div>
                </div>
                <div className="other_industries">
                    <h4>We design, develop, and maintain Software solutions for over 100 industries</h4>
                    <div className="otherindustrie_items">
                        {showMoreIndustries ? (
                            <>
                                <div className="other_industrieselement">
                                    <div>
                                        <Link to="/">Software development near me</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Solar Industry</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Attornneys</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Injury Law Firms</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Financial Institutions</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Entertainment Industries</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Media Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Retail</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Manufcaturing</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Insurance Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Construction Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Education</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Mining Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Transportation and Logistics Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Auto dealers </Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Agriculture</Link>
                                    </div>  
                                </div>
                                <div className="persona_button"> 
                                    <button type="button" className="persona_buttonOne" onClick={()=>navigate('/moreindustrylist')}>See All Industry Lists</button>
                                    <button type="button" className="persona_buttonOne" onClick={()=>setShowMoreIndustries(false)}>Close List</button>
                                </div>
                            </>
                        ) : (
                            <button className="other_industriesbutton" onClick={()=>setShowMoreIndustries(true)} type='button'>Click to see more Industries</button>
                        )}
                    </div>
                </div>
                <div className="reviewsheader_container">
                    <div className="main_reviewsheader">
                        <div>
                            <h4>Our Reviews</h4>
                            <p>& Clients Feedbacks</p>
                        </div>
                        <Link to="/all-reviews" >See all Reviews</Link>
                    </div>
                    <Reviews />
                </div>
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    What is the First step to start my project?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    Every Software project is a bit complicated and different, so the very first step that 
                                    mark the start of any project is creating a comprehensive plan that will show 
                                    foreseeable delays, mitigation plans, failures, resource allocation, technology stacks 
                                    to be used, user requirements.
                                    The Plan will be sent to you with agreement before taking the next step.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                    Will my Business outgrow the software?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    We understand that as your company grows, your software needs may change. All our 
                                    projects are custom built software solutions which makes it scalable and adapted to 
                                    accomodate your evolving requirements.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    Will it be Secure from attacks?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    Off-the-shelf softwares are a target for cyberattacks due to their vulnerabilities.
                                    your project is custom built and it makes room for robust security implementation 
                                    including user access controls, encryption specific to the project.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                   Will it disrupt the software tools we use?
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    Custom software can be seamlessly integrated with your existing software, 
                                    <Link className="link-builder" to="/data" >database, </Link> 
                                    API's. This integration facilitates smooth data flow and <Link className="link-builder" to="/data" >data migration </Link>synchronization
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                    How do I communicate with <Link to="/usadevelopers" >developers </Link>
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                    All our <Link className="link-builder" to="/menifeedevelopers" >software developers </Link>are local here in USA and speak english which 
                                    is the world most-spoken language in the world.
                                    for every project, we establish more than 2 channels of communication with every 
                                    clients making it easy and certain that we'll get and respond to any communication.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                    The Project Cost is too high
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <p>
                                    While custom software development may have a higher upfront cost than 
                                    off-the-shelf solutions, it can be more cost-effective in the long run.
                                    Custom software eliminates additional licenses or subscriptions. 
                                    It can reduce costs associated with manual processes, inefficiencies,
                                    or workarounds required with generic software.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                    The Software stops working
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                    Custom software development typically includes ongoing support and 
                                    maintenance services from our <Link className="link-builder" to="/about">development team. </Link>This ensures prompt
                                    assistance in case of issues, updates, or enhancements. 
                                    The <Link className="link-builder" to="/about">development team. </Link>has in-depth knowledge of the software's 
                                    architecture, making it easier to address any challenges that may arise.
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <FooterForm field="Get started"/>
            </div>
        </>
    )
}

export default React.memo(Software);