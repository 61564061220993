import React ,{useState} from 'react';
import FooterForm from './Footer/FooterForm';
import Reviews from './Reviews';
import { Helmet } from 'react-helmet';
import {  Link, useNavigate } from 'react-router-dom';
import TrustFactors from './TrustFactors';
import { TfiWrite } from "react-icons/tfi";
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";
import {FaChalkboardTeacher, FaRegLightbulb , FaUserSecret ,FaGlobe,FaRocket } from "react-icons/fa";
import { EmailJsComponent } from '../utility/EmailJsComponent';




const DigitalMarketing =() =>{
    const navigate = useNavigate();
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='seo, ppc, branding, digital marketing, design, traffic, social media management, run ads ,online advertising'/>
                <meta name='description' 
                    content='Global Mclien Software Solutions is a leading start-to-finish digital marketing agancy offering wide range of 
                    online solutions including seo, ppc campaigns,content creation,online traffic, branding, design, and online advertising'
                    />
                <title>Digital Marketing Solutions | Full Service Marketing Agency</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/digitalmarketing" />
            </Helmet>
            <div className='component_container'>
                <div className="hero_container digitalPage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Digital Marketing & Branding</h1>
                                <ul className="hero_message_ul">
                                    <li>Local | Global Seo Ranking</li>
                                    <li>Content creativity</li>
                                    <li>PPC | Campaign Strategies</li>
                                    <li>Google Ads | Facebook Ads</li>
                                    <li>Social media Advertising</li>
                                </ul>
                                <p className="hero_message_p">
                                    Showcase Your Solutions/Products to your targetted audience, helping them find you 
                                    easily online - <a href="/websites" className="backlinks">websites, </a> 
                                    <a href="/mobileapp" className="backlinks">mobile-apps, </a> thereby generataing sales, leads and interest.
                                </p>
                                <div>
                                    <a className="hero_message_button" href="#content_form">Book A Free Strategy Call</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/digitalmarketing.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="digitalmarketing_offer">
                    <div>
                        <span className="digitalmarketing_spanoffer">New Offer</span>
                        <h3 className="digitalmarketing_hthree">
                            Free <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">Google My business </a>Listing
                        </h3>
                        <p className="digitalmarketing_paragraph">
                            Google is the biggest search engine on the internet, so we are going to help boost your 
                            GMB listing 
                        </p>
                        <ul className="digitalmarketing_ul">
                            <li className="digitalmarketing_li">5 Steps to Optimise your Google My Business Listing </li>
                            <li>Optimise your listing with the current relevant keywords in your industry </li>
                            <li>Increased ranking on google </li>
                            <li>Higher percentage of your <a href="/websites" className="backlinks">website </a>showing on the 3 google boxes</li>
                            <li>high ranking for location based search</li>
                            <li>Geo tagging all your relevant images on your <a href="/websites" className="backlinks">websites.</a></li>
                        </ul>
                        <div>
                           <button className="hero_message_button" onClick={()=>navigate('/contact', {state: "Google My Business"})} type="button">Get Started with your free GMB Listing</button>
                        </div>
                    </div>
                    <div><img src="/images/googletrust.png" alt="Global mclien gear on a screen" /></div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Marketing Strategy</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Detailed strategy</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home_aboutcontainer">
                    <div className="about_content">
                        <div className="about_sideone">
                            <span>ABOUT</span>
                            <h3>
                                Based out of California, we're a Top-notch <a href="/software" className="backlinks">Software & </a> 
                                <a href="/mobileapp" className="backlinks">Mobile Development  Company!</a>
                            </h3>
                            <p>
                                Established in 2018, <a href="/software" className="backlinks">Global Mclien Software Solutions </a> is,
                                 a team of marketing experts 
                                and <a href="/websites" className="backlinks">website developers</a> with over 14 years of experience
                                in a wide variety of different industries. We specialize in <a href="/seo" className="backlinks">SEO, </a> 
                                group SEM, analytics, 
                                destination marketing, website building, <a href="/mobileapp" className="backlinks">mobile app development</a>
                                 and social media management. Clients have been
                                choosing us, time after time, because of our measurable results, great service, and high ROI.
                            </p>
                            <div >
                                <Link className="about_sideonelink" to="/about">READ MORE &rarr; </Link>
                            </div>
                        </div>
                        <div className="about_sidetwo">
                            <img src="/images/aboutnobg.png" alt="Global Mclien Software Solutions about us" />
                        </div>
                    </div>
                    <div className="about_ourservices">
                        <span>We Help You</span>
                        <h4>Gain A Competitive Edge</h4>
                        <div className="about_illustration">
                            <div className="illustratin_container">
                                <FaRegLightbulb />
                                <div className="illustration_content">
                                    <h6>Gain Insights on your Competition</h6>
                                    <p>
                                        We reveal how you stack up against who
                                         <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">Google </a>
                                        thinks is your competition  and the areas you need the most improvement, 
                                        then show you what your
                                        competition is doing behind the scenes to improve their own online visibility.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaUserSecret />
                                <div className="illustration_content">
                                    <h6>Grow Leads and Sales</h6>
                                    <p>
                                        Understanding your local market and the competition in it is critical for growth.
                                        That's why we use our exclusive Footprint Technology to conduct a competitor and
                                        market analysis, with <a href="/websites" className="backlinks">website </a> and <a href="/seo" className="backlinks">SEO assessment </a>
                                        to reveal what's impacting your success.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaGlobe />
                                <div className="illustration_content">
                                    <h6>Track your Result</h6>
                                    <p>
                                        Managing a small business near me in Los Angeles, California can be time-consuming without 
                                        the worry of managing your digital marketing. With <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link> exclusive 
                                        Dashboard reports, you can easily keep track of our team's work, 
                                        leads, and channel performance in real time and provide feedback to
                                        our team on the lead quality you're getting.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaRocket />
                                <div className="illustration_content">
                                    <h6>Strenghten your Brand</h6>
                                    <p>
                                        A solid online presence for small businesses is crucial.
                                        We start by creating a professional, fast <a href="/websites" className="backlinks">website </a>
                                        to ensure an excellent first impression. Then we, recommend getting a 
                                        <a href="/mobileapp" className="backlinks"> mobile app </a> to scale and strenghten your brand.
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>we develop marketing strategy that makes your business stay <span className="container_span">Relevant & visible online.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/marketingdata.png" alt="branding" />
                            <h5>Generate you online data</h5>
                            <p>
                                We build a comprehensive data for your marketing campaign 
                                to perform at it's highest capabilities
                                on the major <a href="/seo" className="backlinks">search engines.</a>
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/keyword.png" alt="marketing"  />
                            <h5>Build your Keyword List</h5>
                            <p>
                                We create a detailed keyword list of your top ranking competitors 
                                plug it into your campaign with periodic updates.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/contract.png" alt="presentation"  />
                            <h5>No Contracts</h5>
                            <p>
                                You decide if you want to run a month-to-month 
                                digital marketing campaign or retain a contract 
                                sales campaign.
                            </p>
                        </div>
                    </div>
                    <div className="other_subfeatures">
                            <div className="subfeatures_title">
                                <h2 >Our Services</h2>
                            </div>
                            <div className="subfeatures_contentcontainer">
                                <div className="subfeatures_content">
                                    <h4>SEO</h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/seo.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        We re-edit your keyword listings, update your <Link to='/seo'>seo</Link> process,
                                         get high ranking keywords from your competitors ranking top on search engines,
                                        create/revamp your <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">google my business page </a>and other local channels, create 
                                        profiles on other search engines such as bing ,start new google ad campaign 
                                        for your products/listings and create a detailed digital marketing strategy with 
                                        measurable progress at every stage of the process 
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card"> Google PPC Advertising </h4> 
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/ppc.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        We create an engaging and user friendly <a href="/websites" className="backlinks">website </a>that
                                         will be easy to use for your customers, which will
                                        reduce your <a href="/websites" className="backlinks">website </a>
                                         bounce rates increasing your <a href="/websites" className="backlinks">website </a>
                                         score on all <a href="/seo" className="backlinks">seacrh engines.</a>
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card">Content Marketing </h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/content.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button' className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        Building a progressive <a href="/websites" className="backlinks">web </a> app isn't just enough in the <a href="/mobileapp" className="backlinks">app </a>business,that's the reason we make sure all
                                        <a href="/websites" className="backlinks"> web </a> application is scalable from initial app versioning, multilevel stages of user authorisation,  
                                        authentication and verification all in a streamlined flow of operation.
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card"> Social Media Marketing</h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/socialmedia.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        Landing pages with a high conversion ratio is the bedrock of any digital campaign.We help both your sales team and 
                                        marketing team close, build pipelines and close more leads with our conversion-optimised <a href="/websites" className="backlinks">web pages.</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
                <TrustFactors />
                <div className="reviewsheader_container">
                    <div className="main_reviewsheader">
                        <div>
                            <h4>Our Reviews</h4>
                            <p>& Clients Feedbacks</p>
                        </div>
                        <Link to="/all-reviews" >See all Reviews</Link>
                    </div>
                    <Reviews />
                </div>
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                   What digital marketing services are best for small businesses?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    Are you a small business looking for a more affordable path to online marketing
                                    success? Our Platinum digital strategy may be the perfect fit for you. The Platinum digital strategy program
                                    offers a monthly payment option for web design as well as a full suite of affordable
                                    digital marketing and design add-on services.                              
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                    What is a digital marketing agency and why do i need one?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    A digital marketing agency should simply work as an extension of your marketing team.
                                    Whether you have a well staffed marketing department or not, a digital agency employs 
                                    several experts in many different aspects of marketing and design. This affords you 
                                    the ability to have all the marketing expertise you need without having to staff all 
                                    of those roles yourself.
                                    As a full service digital marketing agency in Los Angeles, CA, Global Mclien Software Solutions has expertise 
                                    in website design and development, graphic design, logo design, market research, search 
                                    engine optimization. paid advertising, email campaigns, social media strategy, digital 
                                    strategy, reputation management, content strategy, web hosting services and more.
                                    Check out this <Link to="/blog">blog</Link> for more ways a digital marketing agency can help your business grow.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    What should i look for in a digital marketing firm?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    Some of the best digital marketing agencies don't always offer every digital marketing service
                                    out there. It is true, you want your digital marketing agency to be very well rounded with 
                                    complimentary services to fit your needs, however you also want your digital marketing agency 
                                    to be good at what they do.
                                    As a local digital marketing agency in Los Angeles, California, Global Mclien Software Solutions strives to deliver premium 
                                    quality to all of the services we offer. For any services that we may not offer, such as 
                                    printing or video production, we have relationships with amazing partners that can produce 
                                    these types of outlier services at a high level.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                  What services should a digital marketing agency near me offer?
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    Digital marketing agencies should host a comprehensive suite of design and digital marketing services
                                    that effortlessly complement one another. As top digital marketing experts, these services should 
                                    include the following:

                                    <h6>Digital Advertising</h6> 
                                    A powerful suite of online advertising campaign tactics that include Google ads 
                                    (also known as search engine marketing or pay per click), social media advertising platforms, and 
                                    Programmatic advertising services.

                                    <h6>Search Engine Optimization</h6> 
                                    We strive to get your website search results and rankings listed organically on page one of search
                                     engines like Google and Bing, for all of your chosen SEO or search engine optimized focus key terms.

                                    <h6>Web Design and Development</h6> 
                                    Now, more than ever, a powerful and responsive website design is more important than ever. 
                                    Professional web development is crucial to your online marketing efforts.

                                    <h6>Graphic Design Services</h6> 
                                    Branding and marketing materials that truly convey your message clearly and effectively for any 
                                    medium. This includes logo design, brochure design and all supporting forms of collateral design.

                                    <h6>Content Marketing</h6> 
                                    Our talented team of writers create high-quality blog content that boosts organic website traffic 
                                    through the use of crafted content calendars and A.I. driven SEO software tools.

                                    <h6>Email Marketing</h6> 
                                    Connect more personally with your audience and expand your reach with meaningful email marketing.

                                    <h6>Review Management</h6> 
                                    Increase credibility and online reputation by getting your reviews above 4 stars or better with the
                                     use of custom email and text campaigns.

                                    <h6>Strategy Services</h6> 
                                    Get clarity around your brand and build a clear vision of your digital marketing efforts. Everything 
                                    else falls into place once you have a clearly developed brand and digital marketing strategy.

                                    <h6>Listings & Location Management</h6> 
                                    This powerful SEO tactic is perfect for businesses with one or multiple locations. Organize, optimize
                                     and maintain your location listings across the web.

                                    <h6>HubSpot CRM Partner</h6> 
                                    A powerful CRM is the key to tying your sales and marketing initiatives together. HubSpot is a CRM 
                                    platform that connects everything scaling companies need to deliver a best-in-class customer
                                     experience in one place. As a certified HubSpot partner, we can help you get your sales processes 
                                     fully automated and optimized.

                                    <h6>Social Media Marketing</h6> 
                                    Having strong social media management will enhance brand awareness and help fortify your brand 
                                    authority in your space. Engage with your followers with thoughtful messaging, content and imagery.

                                    At Global Mclien Software Solutions, our versatile experience in digital marketing services in Columbus, OH enables us to 
                                    create pragmatic solutions and campaigns that genuinely have the power to transform brands into 
                                    something spectacular by attracting new customers, inspiring existing customers, and presenting 
                                    new possibilities.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                   Do you think SEO will help my business?
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                   It’s difficult to say without doing a keyword, website, and market analysis. That said, for B2C and B2B organizations operating in competitive markets, SEO can be a very productive service that drives significant value. 70% of purchases start and end with an online search, so there aren’t many scenarios where having stronger organic Google visibility will hurt.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                    How much is digital marketing in California ?
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <p>
                                    It’s difficult to provide a price without knowing what you’d like to accomplish. For instance, if you want to grow by 10 new customers per month the investment needed will vary compared to a similar company that wants to secure 20 new customers per month. We first need to understand the tangible outcomes you desire, then we can do keyword research and market analysis to provide a roadmap and investment level to help you get there; all within the context of a desirable return on investment.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                   What kind of assisstance can i expect after my campaign go live?
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                   You’ll receive the support of your Digital Consultant as well as a Customer Success Manager who will help with strategy, reporting, internal communication, and asset trafficking. In addition, you’ll have specialists working on every aspect of your campaign to ensure consistent optimizations to deliver desired outcomes.                              
                                </p>
                            }
                        </div>
                    </div>
            </div>
                <FooterForm field="Book A Free Strategy Call"/>
            </div>
        </>
    )
}

export default React.memo(DigitalMarketing);