import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { AiFillWechat } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import servicePackage from '../../utility/servicePackage';

const PackageDetails =() =>{
    let location = useLocation();
    const [showPackageLeft, setSHowPackageLeft] = useState(true);
    const packageName = location?.state?.packageName;
    const element = servicePackage.find(item => item.package_name === packageName);
    const featuresList = element?.features

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>{location?.state?.packageName}</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/package_details" />
            </Helmet>
            <div className="package_details">
                <div>
                    <h2 className="package_header">{location?.state?.packageName}</h2>
                </div>
                <div className="packagedetails_container">
                    {showPackageLeft && 
                    <>
                         <div className="showOnMobile">
                            <button onClick={()=>setSHowPackageLeft(false)} type='button'>More Detail</button>
                        </div>
                        <div className="packagedetails_left">
                            <h3>{location?.state?.packageName}</h3>
                            <h6>$4799</h6>
                            <h4>{element?.price}</h4>
                            <p>
                                Suitable for potential super-startups and brand revamps for
                                companies.
                            </p>
                            <div>
                                <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                    Order Now
                                </a>
                            </div>
                            <div className="packagedetails_leftfooter">
                                <div className="packagesContent livechat">
                                    <div className="packagesContent">
                                        <AiFillWechat />
                                        <p> Live Chat</p>
                                    </div>
                                    <div>
                                        <BsTelephone  />
                                        <a href="tel:+7472498760">7472498760</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                    <div className="packagedetails_right">
                        <div className="showOnMobile">
                            <a className='packageright_mobilebutton'  href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <span>{element?.price}</span>
                            <button onClick={()=>setSHowPackageLeft(true)} type='button'>X</button>
                        </div>
                        <h6>Package Includes</h6>
                        <div className="packageDetailssul_container">
                            <ul className="packagesElement_ul">
                               {featuresList?.map((item,index) => (
                                    <li key={index}>
                                        <div className="packagesContent details_li">
                                            <p >{item}</p>
                                            <p className="details_check">&#10004;</p>
                                        </div>
                                    </li>
                               ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default React.memo(PackageDetails);