import React from 'react';
import { useLocation } from 'react-router-dom';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import {FcAddressBook, FcCellPhone ,FcHome ,FcGlobe} from "react-icons/fc";
import { Helmet } from 'react-helmet';


const Contact =() =>{
    const location  = useLocation();
    const data = location?.state

    return(
        <div className='contact_container'>
             <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='cloud management, optimisation , aws cloud, azure cloud, deployments ,cloud platform, cloud solutions, cloud managed services'/>
                <meta name='description' 
                    content="Unlock the power of the cloud with Global Mclien Software Solutions. 
                    Our expert team specializes in crafting innovative cloud applications tailored to your needs. From scalable infrastructure to seamless integration, we'll help your business thrive in the digital age. Discover how our cloud solutions can elevate your operations and drive growth. Contact us today to embark on your cloud journey!"
                    />
                <title>AI Powered Cloud Data Management - Driving Digital Transformation</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/contact" />
            </Helmet>
            <div className="contact_header">
                <h1>{data ? data : 'Get In Touch'}</h1>
            </div>
            <div className="contact_element">
                <div className="contact_elementone">
                    <EmailJsComponent callToAction= {data ? data : "Submit"}/>
                </div>
                <div className="contact_address">
                    <div className="adress_design">

                    </div>
                    <div className="contact_item">
                        <FcHome />
                        <address>
                            25630, Felicia Avenue, Menifee CA92586
                        </address>
                    </div>
                    <div title='click to call' className="contact_item">
                        <FcCellPhone />
                        <a href="tel:+17472498760">
                           +17472498760
                        </a>
                    </div>
                    <div title='click to send mail' className="contact_item">
                        <FcAddressBook />
                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                            info@globalmcliensoftwaresolutions.com
                        </a>
                    </div>
                    <div title='click to visit site' className="contact_item">
                        <FcGlobe />
                        <a href="https://www.globalmcliensoftwaresolutions.com">
                            www.globalmcliensoftwaresolutions.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Contact);