import React ,{useState} from 'react';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import {Helmet} from "react-helmet";
import {Link, useNavigate} from 'react-router-dom';
import { IoCodeSlashOutline ,IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { BiUserX ,BiGlassesAlt } from "react-icons/bi";
import { FaRegHandshake ,FaChalkboardTeacher, FaRegLightbulb , FaUserSecret ,FaGlobe,FaRocket } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import Reviews from './Reviews';


const Home =() =>{
    const[showMoreIndustries, setShowMoreIndustries] = useState(false);
    const navigate = useNavigate();

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>Global Mclien Software Solutions - Software, Mobile Apps | Custom Software Solutions</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions" />
            </Helmet>
            <div className='component_container'>
                <div className="hero_container homePage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div >
                                <p className="hero_message_contentP">USA Based with over 14+ Years of Experience</p>
                                <h1 className="hero_message_h1"><a className='backlinks' href="/software">Software Development </a>&amp; <a className='backlinks' href="/mobileapp">Custom Mobile App</a></h1>
                                <p className="hero_message_p">
                                    Looking for a reliable <a href="/mobileapp" className="backlinks">App Development</a>,
                                    web development company near you for your next project? Hire experienced fullstack developers at Global Mclien Software
                                    Solutions and get your <a href="/websites" className="backlinks">website</a> , scalable
                                     <a href="/software" className="backlinks"> web applications</a> or mobile app developed 
                                    within your budget and on-time.
                                </p>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/contact' , {state:'Get A Quote'})}>Get A Free Quote</button>
                                </div>
                            </div>
                            <div><img src="/images/homenobg1.png" alt="Global mclien gear on a screen" /></div>
                        </div>
                    </div>
                </div>
                <div className="home_aboutcontainer">
                    <div className="about_content">
                        <div className="about_sideone">
                            <span>ABOUT</span>
                            <h3>
                                Based out of California, we're a Top-notch <a href="/software" className="backlinks"> Software</a> & 
                                <a href="https://www.softwaredevbytes.com/mobileapp/" className="backlinks"> Mobile Development  Company</a> !
                            </h3>
                            <p>
                                Established in 2018,<a href="/home" className="backlinks">Global Mclien Software Solutions</a> is an end-to-end 
                                <a href="/software"> Software development</a> , 
                                <a href="/mobileapp" className="backlinks">Mobile App</a> & Web Development firm backed by a team of 
                                highly-skilled and passionate designers,
                                developers, and testers who specialize in developing client-oriented
                                and income-generating <a href="https://www.softwaredevbytes.com/mobileapp/" className="backlinks">software solutions, websites,</a> 
                                <a href="/mobileapp" className="backlinks"> iOS APP, and Android APP.</a> 
                            </p>
                            <div >
                                <Link className="about_sideonelink" to="/about">READ MORE &rarr; </Link>
                            </div>
                        </div>
                        <div className="about_sidetwo">
                            <img src="/images/aboutnobg.png" alt="Global Mclien Software Solutions about us" />
                        </div>
                    </div>
                    <div className="about_ourservices">
                        <span>WE SERVE</span>
                        <h4>Business of all types and sizes.</h4>
                        <div className="about_illustration">
                            <div className="illustratin_container">
                                <FaRegLightbulb />
                                <div className="illustration_content">
                                    <h6>Small Businesses</h6>
                                    <p>
                                        Using the cutting-edge technologies, we help small businesses
                                        grow, succeed and stand out from the crowd.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaUserSecret />
                                <div className="illustration_content">
                                    <h6>Agencies</h6>
                                    <p>
                                        Our strong software strategic planning and execution skills help 
                                        agencies achieve their goals faster and more efficiently.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaGlobe />
                                <div className="illustration_content">
                                    <h6>Entreprises</h6>
                                    <p>
                                        With our in-depth technical knowledge and expertise, we help 
                                        enterprises gain a competitive edge over rivals.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaRocket />
                                <div className="illustration_content">
                                    <h6>Startups</h6>
                                    <p>
                                    Our solution-driven and result-oriented development
                                     approach helps small startups turn into big businesses.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Project Consultation</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>

                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="reason_container">
                        <div className="reason_element">
                            <IoCodeSlashOutline />
                            <p>Source Code Ownership</p>
                            <div>
                                <p className="reason_elementP">
                                We build it, you own it. It's that simple. Once we finish your software development,
                                we pass over the entire source code so that your software project is
                                yours - no fees or strings attached. Whether you want to continue 
                                working with us for software maintenance and updates, or take the project internal is up to you.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <BiUserX />
                            <p>No third Parties</p>
                            <div>
                                <p className="reason_elementP">
                                You know what they say - three is a crowd. That's why we keep everything 
                                in-house. Our software developers, designers, project managers, and everything in 
                                between are all our own vetted and trained development team members. This allows us
                                to ensure high-quality and on-time projects.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <FaRegHandshake/>
                            <p>Local & Accountable</p>
                            <div>
                                <p className="reason_elementP">
                                We value in-person interactions and believe that face-to-face problem 
                                solving is critical for a successful software project. We know trust is earned,
                                so we want to be more than just a brand name on a computer screen - we want
                                you to see our office and to meet our development team. By being local 
                                , we know that you can hold us accountable.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <BiGlassesAlt />
                            <p>Transparency</p>
                            <div>
                                <p className="reason_elementP">
                                Global Mclien Software solutions is built on long-term partnerships, which means that we
                                want to earn your trust and keep it. We do this through our commitment 
                                to absolute transparency about us, our software processes, our software projects, and your project. 
                                While the frequency of  software updates will be at your discretion, we will always
                                keep you up to date on the status of your project, budget, risks, timelines, 
                                and blockers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buyerpersona_container persona_one">
                    <div className="persona_content">
                        <div className="personacontentside">
                            <p className="personaheader_p">Looking for custom code tailored for your business?</p>
                            <h3 className="personacontentside_hthree">
                                Scalable solutions created with <a href="/software" className="backlinks"> Custom-Code</a> 
                            </h3>
                            <div className="personacontentside_div">
                                <p>With solutions such as: </p>
                                <ul className="personacontentside_ul">
                                    <li>Point-to-point integration</li>
                                    <li>Easy Features modification</li>
                                    <li>Reduced dependency on third party apps</li>
                                    <li>Code Documentaion</li>
                                    <li>Flexibility and compatibility with browsers </li>
                                </ul>
                            </div>
                            <div>
                                <p className="personacontentside_p">
                                    Saving your business time and resources when making 
                                    changes to your <a href="digitalmarketing" className="backlinks">digital solutions,</a> refactoring and adding new features.
                                </p>
                            </div>
                            <div className="persona_button">
                                <button className="persona_buttonOne" onClick={()=>navigate('/contact', {state: "Request a demo"})} type="button">Request A Free Demo</button>
                                <button className="persona_buttonTwo" onClick={()=>navigate('/software')} type="button">Learn More</button>
                            </div>
                        </div>
                        <div className="personacontentside">
                            <img className="personacontentside_img" src="/images/backdropp.png" alt="Global Mclien software solutions" />
                        </div>
                    </div>
                </div>
                <div className="buyerpersona_container persona_two">
                    <div className="persona_content">
                        <div className="personacontentside">
                            <p className="personaheader_p">Are You an property owner, realestate business, commercial real estate ?</p>
                            <h3 className="personacontentside_hthree">
                                Automate your Real Estate with <a href="https://www.softwaredevbytes.com/mobileapp/" className="backlinks">Software Solutions.</a> 
                            </h3>
                            <div className="personacontentside_div">
                                <p>With solutions such as: </p>
                                <ul className="personacontentside_ul">
                                    <li>Automated Tenant Screening</li>
                                    <li>Automated Rent collection</li>
                                    <li>Easy rental listings</li>
                                    <li>One-click Finacial report download</li>
                                    <li>Light weight application form downloads </li>
                                </ul>
                            </div>
                            <div>
                                <p className="personacontentside_p">
                                    Save lots of hours by automating your real estate processes and making client's portfolio creation
                                    a walk in the park.
                                </p>
                            </div>
                            <div className="persona_button">
                                <button className="persona_buttonOne" onClick={()=>navigate('/contact', {state: "Request a demo"})} type="button">Request A Free Demo</button>
                                <button className="persona_buttonTwo" onClick={()=>navigate('/websites')}type="button">Learn More</button>
                            </div>
                        </div>
                        <div className="personacontentside">
                            <img className="personacontentside_img" src="/images/realestate.png" alt="Global Mclien software solutions" />
                        </div>
                    </div>
                </div>
                <div className="buyerpersona_container persona_three">
                    <div className="persona_content">
                        <div className="personacontentside">
                            <p className="personaheader_p">Are You an entrepreneur, startup, local business owner ?</p>
                            <h3 className="personacontentside_hthree">
                                Create <a href="https://www.softwaredevbytes.com/index.html" className="backlinks">Software Solutions</a>  for your business.
                            </h3>
                            <div className="personacontentside_div">
                                <p>With Features like: </p>
                                <ul className="personacontentside_ul">
                                    <li>Offline Access</li>
                                    <li>Easy Accounting and reporting</li>
                                    <li>Fast Scheduling</li>
                                    <li>Automated booking</li>
                                    <li>Integrated Payment </li>
                                </ul>
                            </div>
                            <div>
                                <p className="personacontentside_p">
                                    scale your business by automating the booking process with little or no technical experience 
                                    and no account creation 
                                </p>
                            </div>
                            <div className="persona_button">
                                <button className="persona_buttonOne" onClick={()=>navigate('/contact', {state: "Request a demo"})} type="button">Request A Free Demo</button>
                                <button className="persona_buttonTwo" onClick={()=>navigate('/websites')} type="button">Learn More</button>
                            </div>
                        </div>
                        <div className="personacontentside">
                            <img className="personacontentside_img" src="/images/smb.png" alt="Global Mclien software solutions" />
                        </div>
                    </div>
                </div>
                <div className="buyerpersona_container persona_four">
                    <div className="persona_content">
                        <div className="personacontentside">
                            <p className="personaheader_p">Are You an Hospital, blood bank, looking for software <a href="https://www.softwaredevbytes.com/contact/" className="backlinks"> developer</a> near you ?</p>
                            <h3 className="personacontentside_hthree">
                                Create Enterprise  <a href="https://www.softwaredevbytes.com/index.html" className="backlinks">Software Solutions.</a> for Healthcare Facilities.
                            </h3>
                            <div className="personacontentside_div">
                                <p>With key Features like: </p>
                                <ul className="personacontentside_ul">
                                    <li>Importing Existing Data</li>
                                    <li>Capturing New Data</li>
                                    <li>User Friendly Interfaces</li>
                                    <li>Data Management</li>
                                    <li>Incident Capturing interfaces</li>
                                </ul>
                            </div>
                            <div>
                                <p className="personacontentside_p">
                                    Empowering facilies to reduce overall risk, increase employee safety 
                                    ,improved operational performance and achieve a streamlined compliance that 
                                    regulates both internal and third party audit.
                                </p>
                            </div>
                            <div className="persona_button">
                                <button className="persona_buttonOne" onClick={()=>navigate('/contact', {state: "Request a demo"})} type="button">Request A Free Demo</button>
                                <button className="persona_buttonTwo" onClick={()=>navigate('/software')} type="button">Learn More</button>
                            </div>
                        </div>
                        <div className="personacontentside">
                            <img className="personacontentside_img" src="/images/personaimage.png" alt="Global Mclien software solutions" />
                        </div>
                    </div>
                </div>
                <div className="other_industries">
                    <h4>We design, develop, and maintain  <a href="https://www.softwaredevbytes.com/index.html" className="backlinks">Software Solutions.</a> for over 100 industries</h4>
                    <div className="otherindustrie_items">
                        {showMoreIndustries ? (
                            <>
                                <div className="other_industrieselement">
                                    <div>
                                        <Link to="/">Software development near me</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Solar Industry</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Attornneys</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Injury Law Firms</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Financial Institutions</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Entertainment Industries</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Media Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Retail</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Manufcaturing</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Insurance Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Construction Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Education</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Mining Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Transportation and Logistics Companies</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Auto dealers </Link>
                                    </div>
                                    <div>
                                        <Link to="/">Software for Agriculture</Link>
                                    </div>  
                                </div>
                                <div className="persona_button"> 
                                    <button type="button" className="persona_buttonOne" onClick={()=>navigate('/moreindustrylist')}>See All Industry Lists</button>
                                    <button type="button" className="persona_buttonOne" onClick={()=>setShowMoreIndustries(false)}>Close List</button>
                                </div>
                            </>
                        ) : (
                            <button className="other_industriesbutton" onClick={()=>setShowMoreIndustries(true)} type='button'>Click to see more Industries</button>
                        )}
                    </div>
                </div>
                <div className="reviewsheader_container">
                    <div className="main_reviewsheader">
                        <div>
                            <h4>Our Reviews</h4>
                            <p>& Clients Feedbacks</p>
                        </div>
                        <Link to="/all-reviews" >See all Reviews</Link>
                    </div>
                    <Reviews />
                </div>
            </div>
        </>
    )
}

export default React.memo(Home);