import React from 'react';
import { Link } from 'react-router-dom';
import {AiOutlineCoffee } from "react-icons/ai";
import { Helmet } from 'react-helmet';


const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const SoftwareBlog =() =>{
    const date = new Date();
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' 
                content=
                'Cryptocurrency,ethereum  exchanges,wallet, ethereum  algorithms,Defi, ethereum  platform DAOs, OpenZepplin COntracts, ethereum '
                />
                <meta name='description' 
                    content='Global Mclien Software Solutions is a leading Software application development company with over 14 years of experience 
                    building Software application applications by a competent team of the best Software application developers, R&D department and mobile application consultants'
                    />
                <title>Blog Software App Development - Global Mclien Software Solutions </title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/softwareblog" />
            </Helmet>
            <div className='component_container' >
                <div className="hero_container blog">
                    <div className="blog_donation" title='click to support us'>
                        <Link to="/donations">Buy Me Coffee</Link>
                        <AiOutlineCoffee />
                    </div>
                    <div className="blog_content">
                        <h1>Software Blog!</h1>
                        <p>First, solve the problem. Then, write the code.</p>
                        <Link className="hero_message_button" to="/about">Write for Us</Link>
                    </div>
                </div>
                <div className="blog_primarycontainer">
                    <div className="blog-subnav">
                        <div>
                            <Link to="/blog">Software Blogs</Link>
                            <Link to="/mobileappblog">Mobile App Blogs</Link>
                            <Link to="/digitalmarketingblog">Digital Marketing Blogs</Link>
                            <Link to="/blockchainblog">Blockchain Blog</Link>
                            <Link to="/websiteblog">Website Blog</Link>
                        </div>
                    </div>
                    <div className="blogitem_contents">
                        <div className="blogitem_one">
                            <h3>The State of Search {date.getFullYear()}</h3>
                            <p>
                                The analysis highlights trends by using recent search data, but also comparing
                                2022 to 2021 to unpick how people searched, 
                                <Link to="/moreindustrylist">industries</Link> performed, and
                                <a href="https://www.semrush.com/blog/serp/"> SERPs </a> 
                                changed across the world. 
                            </p>
                            <div>
                                <p>{monthNames[date.getMonth()]} , {date.getFullYear()}</p>
                                <h5 className="blogitem_onehfive"><Link to="/software">Software Sector</Link></h5>
                                <p>4 min read</p>
                            </div>
                        </div>
                        <div className="blogitem_one">
                            <img className="blogitem_picture" src="/images/blog1.png" alt='Globa Mclien Software Solutions blog' />
                        </div>
                    </div>
                
                    <div className="blog_newsletter">
                        <div>
                            <img className="blogitem_picture" src="/images/blog1.png" alt='Globa Mclien Software Solutions blog' />
                        </div>
                        <div className="blognewsletter_div">
                            <h3>Give me the latest Software news</h3>
                            <p className="blognewsletter_para">
                                Want to know which websites saw the most traffic growth in your industry? 
                                Not sure why your SEO strategy doesn't work? Or simply looking for SEO tips?
                                Subscribe to our newsletter to receive updates on the content you care about.
                            </p>
                            <div className="blognewsletter_inputcontainer">
                                <input type="text" placeholder='Your email' />
                                <input type="submit"  value="Subscribe"/>
                            </div>
                            <div className="blogterms_andcondition">
                                <p>
                                    By clicking “Subscribe” you agree to Global Mclien Software Solutions and consent 
                                    to Global Mclien Software Solutions using your contact data for newsletter purposes
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="blog_advert">
                        <span className="blog_span"> 4days Online Course</span>
                        <div className="blog_advertcontents">
                            <div className="blogads_one">
                                <div><img src="/images/afeezbadmos.png" alt="Afeez Badmos" /></div>
                            </div>
                            <div className="blogads_two">
                                <h3>
                                    Mastering Digital Media with Afeez Badmos
                                </h3>
                                <p>
                                    Grow more than a business by learning exactly how to get
                                    press using a proven, repeatable and step-by-step system.
                                </p>
                                <div>
                                    <Link className="hero_message_button" to="/about">Enroll Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default React.memo(SoftwareBlog);