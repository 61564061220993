import React from 'react';
import { Helmet } from 'react-helmet';
import { AiFillWechat } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import {Link, useNavigate} from 'react-router-dom';


const PackagesPage =() =>{
    const navigate = useNavigate();

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>Packages - Global Mclien Software Solutions</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/websitespackages" />
            </Helmet>
            <div className="hero_container homePage">
                <div className="hero_message">
                    <div className="hero_message_content">
                        <div >
                            <p className="hero_message_contentP">Take Control, Choose one / more package OR customise your package</p>
                            <h1 className="hero_message_h1">Web Apps' Package List</h1>
                            <p className="hero_message_p">
                                Find the package that suits your needs and the scale of your business at an affordable and transparent cost.
                                Each package is customised and fully scalable to provide a combination of services that work effectively 
                                together for your business scalability.
                            </p>
                            <div className="hero_packagebutton">
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Web Design'})}>Web Design</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'eCommerce Packages'})}>eCommerce Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Shopify Packages'})}>Shopify Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'SEO Packages'})}>SEO Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Digital Marketing'})}>Digital Marketing</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Web Maintainance'})}>Web Maintainance</button>
                                </div>
                            </div>
                        </div>
                        <div className="heropackage_img"><img src="/images/package.png" alt="Global mclien package" /></div>
                    </div>
                </div>
            </div>
            <div className="packagesContainer">
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>2 Revisions</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Basic <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$1250</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        Basic Website Package - $1,000:







                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Consultation session to discuss website requirements and goals</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a basic website with up to 5 pages (e.g., Home, About Us, Services, Contact)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Responsive design for mobile and desktop</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Basic SEO optimization (meta tags, sitemap submission)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>One round of revisions</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Hosting setup and domain integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>One month of technical support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Basic Package',
                                        price:'1250'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>5 Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Standard <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$3,000</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        Standard Website Package - $3,000:








                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Detailed requirement analysis and wireframe creation</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a medium-sized website with up to 10 pages</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                    <p className="packagesContentcheck">&#10004;</p>
                                        <p>Custom design tailored to brand identity</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integration of contact forms, social media links, and Google Maps</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Enhanced SEO optimization (keyword research, on-page optimization)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Two rounds of revisions</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Hosting setup, domain integration, and SSL certificate installation</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Two months of technical support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Mobile Responsive</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Standard Package',
                                        price:'1750'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>10 Revisions</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">E-commerce Website <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$2,250</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                         Package - $5,000:









                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Comprehensive consultation on e-commerce strategy and platform selection</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Development of an e-commerce website with product catalog, shopping cart, and checkout functionality</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Custom design with emphasis on user experience and conversion optimization</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integration of payment gateways (e.g., PayPal, Stripe)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Product management system with inventory tracking and order management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Advanced SEO optimization for product pages</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Hosting setup, domain integration, and SSL certificate installation</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Codebase Ownership</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Three months of technical support and maintenance</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'Elite Package',
                                            price:'2250'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="packagesContainer lower">
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br /> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Custom Web Application <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$10,000</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                         Package - $10,000:








                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >In-depth discovery phase to define project scope, requirements, and user stories</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a custom web application tailored to specific business needs</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >UX | UI</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Advanced features and functionalities, such as user authentication, admin panel, and data visualization</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Responsive design optimized for various devices and screen sizes</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integration with third-party APIs and services</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Comprehensive testing, including unit testing and user acceptance testing (UAT)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Deployment to a production environment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Four months of technical support and post-launch maintenance</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Functional Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'eCommerce Package',
                                            price:'4250'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br /> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Enterprise Web Solution <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$20,350</h3>
                            <p className="packagesElement_description">
                                Suitable for B2B & Established business with Scalable business concepts.
                            </p>
                        </div>
                         Package - $20,000:







                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Strategic consultation and project roadmap development</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a large-scale enterprise web solution with complex workflows and integrations</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Scalable architecture designed for high traffic and performance</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Advanced security measures, including encryption and access controls</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Continuous integration and deployment (CI/CD) pipeline</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Mobile Responsive Design</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Ongoing monitoring and optimization for performance and reliability</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Customized support and maintenance plans based on enterprise requirements</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique functional Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'Web Application Package',
                                            price:'8750'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br/> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Custom <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$Custom Pricing</h3>
                            <p className="packagesElement_description">
                                Suitable for potential Social Media contents startegy for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Tailored solutions for unique project requirements</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Flexible pricing based on project complexity, size, and timeline</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Customized design, development, and testing</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Continuous support and maintenance as per client's needs</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>SEO Meta Tags</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Online Integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Custom Dynamic Forms</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Third Party Integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Easy Search</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>User Profile Management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Signup Area(Newsletter, Offers)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Social Media Package',
                                        price:'16599'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(PackagesPage);