import React from 'react';
import { Helmet } from 'react-helmet';
import { AiFillWechat } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import {Link, useNavigate} from 'react-router-dom';


const PackagesPage =() =>{
    const navigate = useNavigate();

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>Packages - Global Mclien Software Solutions</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/mobileapppackages" />
            </Helmet>
            <div className="hero_container homePage">
                <div className="hero_message">
                    <div className="hero_message_content">
                        <div >
                            <p className="hero_message_contentP">Take Control, Choose one / more package OR customise your package</p>
                            <h1 className="hero_message_h1">Mobile App Package List</h1>
                            <p className="hero_message_p">
                                Find the package that suits your needs and the scale of your business at an affordable and transparent cost.
                                Each package is customised and fully scalable to provide a combination of services that work effectively 
                                together for your business scalability.
                            </p>
                            <div className="hero_packagebutton">
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Web Design'})}>Web Design</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'eCommerce Packages'})}>eCommerce Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Shopify Packages'})}>Shopify Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'SEO Packages'})}>SEO Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Digital Marketing'})}>Digital Marketing</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Web Maintainance'})}>Web Maintainance</button>
                                </div>
                            </div>
                        </div>
                        <div className="heropackage_img"><img src="/images/package.png" alt="Global mclien package" /></div>
                    </div>
                </div>
            </div>
            <div className="packagesContainer">
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>2 Revisions</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Basic <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$7,000</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Consultation session to discuss app idea and requirements</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a simple mobile application with up to 3 basic features</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Basic UI/UX design</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Testing and debugging</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Delivery of APK file (Android) or IPA file (iOS)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>One month of technical support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Deployment to Google Play Store (Android) or Apple App Store (iOS) $3000</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Basic Package',
                                        price:'1250'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>5 Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Starter <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$12,150</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Detailed requirement gathering and project planning</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a small-scale mobile application with up to 5 features</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                    <p className="packagesContentcheck">&#10004;</p>
                                        <p>Custom UI/UX design with basic animations and transitions</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integration of basic functionalities like push notifications and social media sharing</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Comprehensive testing, including compatibility testing on multiple devices</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Delivery of source code and documentation</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Two months of technical support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Deployment to Google Play Store (Android) or Apple App Store (iOS)$3000</p>
                                    </div>
                                </li>
                               
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Standard Package',
                                        price:'1750'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>10 Revisions</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Standard <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$16,650</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Strategic consultation and app strategy development</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Development of a complex mobile application with unlimited features</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>High-fidelity UI/UX design with user research and usability testing</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integration with backend systems and cloud services</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Implementation of advanced security measures and encryption</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Performance optimization and scalability planning</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Continuous integration and continuous deployment (CI/CD)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Six months of technical support and priority assistance</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Codebase Ownership</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Deployment to Google Play Store (Android) or Apple App Store (iOS)</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'Elite Package',
                                            price:'2250'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="packagesContainer lower">
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br /> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Professional <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$22,500</h3>
                            <p className="packagesElement_description">
                            Tailored solutions for large enterprises and corporations
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Strategic consultation and app strategy development</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a complex mobile application with unlimited features</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >High-fidelity UI/UX design with user research and usability testing</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integration with backend systems and cloud services</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Implementation of advanced security measures and encryption</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment on Playstore | iOS store</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Performance optimization and scalability planning</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Continuous integration and continuous deployment (CI/CD)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Six months of technical support and priority assistance</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'eCommerce Package',
                                            price:'4250'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br /> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Enterprise <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$30,000</h3>
                            <p className="packagesElement_description">
                            Tailored solutions for large enterprises and corporations
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Tailored solutions for large enterprises and corporations</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Comprehensive consultation and collaboration with stakeholders</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Development of a large-scale enterprise mobile application with custom modules and integrations</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Advanced security features and compliance with industry standards</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Continuous monitoring and analytics for performance optimization</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Customized support and maintenance plans based on enterprise requirements</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment on Playstore | iOS</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'Web Application Package',
                                            price:'8750'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br/> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Custom <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$Custom Pricing</h3>
                            <p className="packagesElement_description">
                                Suitable for potential Social Media contents startegy for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Tailored solutions for unique project requirements</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Flexible pricing based on project complexity, size, and timeline</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Customized UI/UX design, development, and testing</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Continuous support and maintenance as per client's needs</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment on Playstore | iOS</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Social Media Package',
                                        price:'16599'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(PackagesPage);