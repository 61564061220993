import { useState } from 'react';
import {  AiFillStar} from "react-icons/ai";


const Reviews =() =>{
    const [reviewNumber, setReviewNumber] = useState(0);

    return(
        <div className="main_reviews">
            <div className="review_container">
                {
                    reviewNumber === 0 ? (
                        <div className="review_content">
                        <div className="reviewcontent_header">
                            <div className="review_name">
                                <p className="review_namepara">PB</p>
                            </div>
                            <div >
                                <h6>Patricia Brock</h6>
                                <p className="review_nameparatwo">Perris, California</p>
                            </div>
                        </div>
                            <div className="star-container">
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                            </div>
                        <div className="review_message">
                            <h6>Awesome Team!!!</h6>
                            <p>
                                "I needed help with literally everything!!!  I was taken care of completely! 
                                Afeez asked me questions about what I needed and what I wanted and he did 
                                everything perfectly.  I will be using this service for all my computing 
                                needs from now on.  He is courteous, professional, kind and very knowledgeable. 
                                He took care of everything for me and he never let me feel bad for not knowing 
                                what I was doing.  He graciously took control and got me exactly what I needed and wanted.
                                He kept to his original timeline.  I was able to keep mine as well.  There were no delays. 
                                Thank you once again.  I will be calling again with any future needs.”
                            </p>
                        </div>
                        </div>
                    ) : reviewNumber === 1 ? (
                        <div className="review_content">
                        <div className="reviewcontent_header">
                            <div className="review_name">
                                <p className="review_namepara">PB</p>
                            </div>
                            <div >
                                <h6>Patricia Brock</h6>
                                <p className="review_nameparatwo">Perris, California</p>
                            </div>
                        </div>
                            <div className="star-container">
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                {/* <AiOutlineStar /> */}
                            </div>
                        <div className="review_message">
                            <h6>Awesome Team!!!</h6>
                            <p>
                                "I needed help with literally everything!!!  I was taken care of completely! 
                                Afeez asked me questions about what I needed and what I wanted and he did 
                                everything perfectly.  I will be using this service for all my computing 
                                needs from now on.  He is courteous, professional, kind and very knowledgeable. 
                                He took care of everything for me and he never let me feel bad for not knowing 
                                what I was doing.  He graciously took control and got me exactly what I needed and wanted.
                                He kept to his original timeline.  I was able to keep mine as well.  There were no delays. 
                                Thank you once again.  I will be calling again with any future needs.”
                            </p>
                        </div>
                        </div>
                    ): reviewNumber === 2 ?(
                        <div className="review_content">
                        <div className="reviewcontent_header">
                            {/* <img src="/images/bimma.jpg" alt="global mclien software solutions review images" /> */}
                            <div className="review_name">
                                <p className="review_namepara">BT</p>
                            </div>
                            <div >
                                <h6>Baba Tee</h6>
                                <p className="review_nameparatwo">Chesapeak, North Carolina</p>
                            </div>
                        </div>
                            <div className="star-container">
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                {/* <AiOutlineStar /> */}
                            </div>
                        <div className="review_message">
                            <h6>Highly Recommended</h6>
                            <p>
                                “Wonderful company to work with”
                            </p>
                        </div>
                        </div>
                    ): reviewNumber === 3 ?(
                        <div className="review_content">
                        <div className="reviewcontent_header">
                            {/* <img src="/images/bimma.jpg" alt="global mclien software solutions review images" /> */}
                            <div className="review_name">
                                <p className="review_namepara">AB</p>
                            </div>
                            <div >
                                <h6>Ayoola Badmus</h6>
                                <p className="review_nameparatwo">London, United Kingdom</p>
                            </div>
                        </div>
                            <div className="star-container">
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                {/* <AiOutlineStar /> */}
                            </div>
                        <div className="review_message">
                            <h6>Awesome Team!!!</h6>
                            <p>
                                Global McLien built my website and made the process smoothly.
                                 He explained everything in details and met my expectations, 
                                 website completed within deadline”
                            </p>
                        </div>
                        </div>
                    ): (
                        <div className="review_content">
                        <div className="reviewcontent_header">
                            {/* <img src="/images/bimma.jpg" alt="global mclien software solutions review images" /> */}
                            <div className="review_name">
                                <p className="review_namepara">AB</p>
                            </div>
                            <div >
                                <h6>Abimbola Badmos</h6>
                                <p className="review_nameparatwo">London, United Kingdom</p>
                            </div>
                        </div>
                            <div className="star-container">
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                <AiFillStar />
                                {/* <AiOutlineStar /> */}
                            </div>
                        <div className="review_message">
                            <h6>On-time delivery</h6>
                            <p>
                                Global McLien built my mobile app within the stipulated time frame 
                                and it was very easy when talking to the team about revisions for the UI|UX”
                            </p>
                        </div>
                        </div>
                    )
                }
            </div>
            <div>
                <div className="review_dots">
                    <ul className="review_ul">
                        <li className={reviewNumber === 0 ? "review_liactive" : "review_li"}>&#11044; </li>
                        <li className={reviewNumber === 1 ? "review_liactive" : "review_li"}>&#11044; </li>
                        <li className={reviewNumber === 2 ? "review_liactive" : "review_li"} >&#11044; </li>
                        <li className={reviewNumber === 3 ? "review_liactive" : "review_li"}>&#11044; </li>
                        <li className={reviewNumber === 4 ? "review_liactive" : "review_li"}>&#11044; </li>
                    </ul>
                </div>
                <div className="reviewbutton_container">
                    { (reviewNumber > 0 && reviewNumber < 4 )? (
                        <div>
                            <button type='button' onClick={()=>setReviewNumber(reviewNumber - 1)}>Prev</button>
                            <button type='button'  onClick={()=>setReviewNumber(reviewNumber + 1)}>Next</button>
                        </div>

                        ) : reviewNumber === 0 ?(
                            <div>
                            <button type='button'  onClick={()=>setReviewNumber(reviewNumber + 1)}>Next</button>
                        </div>
                        ): reviewNumber > 4 ?(
                            <div>
                                <button type='button'  onClick={()=>setReviewNumber(reviewNumber - 1)}>Prev</button>
                            </div>
                        ) : (
                            <div>
                                <button type='button'  onClick={()=>setReviewNumber(reviewNumber - 1)}>Prev</button>
                            </div>
                        )
                    }
                </div>
            </div> 
        </div>
    )
}

export default Reviews;
