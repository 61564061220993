import React from 'react';
import { FaFacebookF ,FaYoutube, FaLinkedinIn, FaInstagram, FaTwitter} from "react-icons/fa";
import { Link, useLocation} from 'react-router-dom';



const SucessPage =() =>{
    const queryValue = useLocation();

    return(
        <div className="success_container">
            <div className="success_content">
                <div>
                    {queryValue.state?.callToAction === 'Get In Touch' ? (
                        <div className="success_element">
                            <h1>Thank you for Getting in Touch</h1>
                            <p>we'll be in touch very soon</p>
                        </div>
                    ): queryValue.state?.callToAction === 'submit' ? (
                        <div className="success_element">
                            <h1>Thank you for Contacting Us</h1>
                            <p>We'll be in touch very soon</p>
                        </div>
                    ) : queryValue.state?.callToAction === 'Scope your Project' ?(
                        <div className="success_element">
                            <h1>We looking forward discussing your project</h1>
                            <p>We'll be in touch very soon</p>
                        </div>
                    ) : queryValue.state?.callToAction === 'Book A Free Strategy Call' ? (
                        <div className="success_element">
                            <h1>Thank you for Booking a Strategy call</h1>
                            <p>We'll be in touch very soon</p>
                        </div>
                    ):(
                        <div className="success_element">
                            <h1>Let's Get your project started!!!</h1>
                            <p>We'll be in touch very soon</p>
                        </div>
                        )
                    }
                </div>
                <div className="component_buttons">
                    <Link  to={'..'}>&larr; Go back</Link>
                    <Link to="/">Home</Link>
                </div>
               
                <div className="success_socialmedia">
                    <h2>Don't forget to follow us on your favourite social network</h2>
                    <ul className="footerlinks_socialmedia">
                        <li>
                            <a href="https://www.facebook.com/softwaredevbytes">
                                <FaFacebookF/>
                            </a>
                        </li>
                        <li>
                            <a href="www.youtube.com/afeez20">
                                <FaYoutube/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/afeez-badmos-19962333">
                                <FaLinkedinIn/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/softwaredev__bytes">
                                <FaInstagram/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitter.com/afeez20">
                                <FaTwitter/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default React.memo(SucessPage);