import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Reviews from '../../Reviews';
import {IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { TfiWrite } from "react-icons/tfi";
import {FaChalkboardTeacher } from "react-icons/fa";
import { BsTelephone } from 'react-icons/bs';
import { EmailJsComponent } from '../../../utility/EmailJsComponent';


const WebsiteDesignInLaVerne =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='website, website design, ETSY, Shopify, Wordpress,E-commerce,Web pages, funnels '/>
                <meta name="description" content="A top Website development company in  La Verne, California, USA, Canada , Australia , West Africa Countries delivering
                    web and mobile app solutions to global startups, businesses & enterprises."/>
                <meta property="og:locale" content="en_US"/>
                <meta name="author" content="Afeez Badmos"/>
                <meta property="og:site_name" content="Global Mclien Software Solutions | Mobile Applications, Web Applications, Websites"/>
                <meta name="title" content="Website Development and Design Company"/>
                <title>Global Mclien Software Solutions - Website Designer in La Verne</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/website-design-in-la-verne" />
            </Helmet>
            <div className="landingpage-content">
                <div className='lpleft-side'>
                    <p className='lpage-top'>Looking for a 
                        <Link className="link-builder" to="/websites"> Website Designer </Link>
                        near me?</p>
                    <h1>
                       Website Design in <span className='lphero-span'>La Verne.</span>
                    </h1>
                    <p className='lp-subheader'>
                        Innovative <Link className="link-builder" to="/websites"> Websites designer | developer </Link>for Your Business Growth.
                        <br />We design, develop, and deploy your complete <Link className="link-builder" to="/websites"> Website </Link>online in 7days.
                    </p>
                    <div>
                        <ul className='lpleft-ul'>
                            <li>&#x2714; Free Domain Name</li>
                            <li>&#x2714; Free SSL Certificate</li>
                            <li>&#x2714; Can be Accessed on Any Device.</li>
                            <li>&#x2714; <Link to="/seo">SEO </Link>Integrations</li>
                            <li>&#x2714; Design & Deploy in 7-days</li>
                        </ul>
                    </div>
                   <div className="button_containers">
                        <div className='cta-button'>
                            <Link to='#contactform'>Free Consultation</Link>
                        </div>
                        <div className='cta-otherbutton'>
                            <Link to="/websites">Learn More</Link>
                        </div>
                   </div>
                </div>
                <div className='lpright-side'>
                    <img className='img_div' src="/images/losangeles.png" alt="Global Mclien Software Solutions" />                    
                </div>
            </div>
            <div className='lp-spacer'>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                        <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Idea and Concept Consultation</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                <div  className='lpagebody-content lp-conclusion'>
                    <h2>Our Process Overview</h2>
                    <div>
                        <h4>1. Free Initial Consultation</h4>
                        <p >
                            We start with a free consultation to discuss your project, understand your
                            needs, and outline the best strategy for your<Link className='color-links'  to="/websites"> website.</Link>
                        </p>
                    </div>
                    <div>
                        <h4>2. Custom Design & Development</h4>
                        <p>
                            Our team crafts a unique design that aligns with your brand
                            and develops a responsive, user-friendly 
                            <Link className='color-links' to="/websites"> website </Link>that engages your audience.
                        </p>
                    </div>
                    <div>
                        <h4>3. Review & Revisions</h4>
                        <p>
                            We collaborate with you throughout the process, incorporating your feedback
                            to ensure the final product meets your expectations.
                        </p>
                    </div>
                    <div>
                        <h4>4. Launch & Support</h4>
                        <p>
                            Once your <Link className='color-links' to="/websites"> website </Link> is ready,
                             we handle the launch and provide ongoing support to 
                            ensure your site remains up-to-date and performs optimally.
                        </p>
                    </div>
                </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:developer@softwaredevbytes.com">
                                            developer@softwaredevbytes.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lpage-body">
                <div className='lpagebody-content'>
                    <h2>Is Your Website Design Services Near Me La Verne.</h2>
                    <p>
                      We successfully work with clients worldwide through various communication 
                      tools such as video conferencing, emails, and instant messaging. Our team 
                      is flexible and can schedule meetings at times convenient for you, ensuring
                       smooth and consistent communication.Get in Touch with us for <Link className="link-builder" to="/websites">website </Link>design 
                       near me.
                    </p>
                    <div className='cta-button'>
                        <Link to='#contactform'>Free Consultation</Link>
                    </div>
                </div>
                <div className='lpagebody-content lp-conclusion'>
                    <h2>Why Choose Our website design Services in Alabama</h2>
                    <p>
                        <Link className="link-builder" to='/'>Global Mclien Software Solutions </Link>is a leading 
                        <Link className="link-builder" to="/mobileapp">website design </Link> 
                        company dedicated to delivering innovative solutions that drive business 
                        success. With years of experience and a passion for technology, 
                        we help businesses create impactful <Link className="link-builder" to="/websites">website designs.</Link>
                    </p>
                    <div className='cta-button'>
                        <Link to='#contactform'>Free Consultation</Link>
                    </div>
                </div>
                <div className='lpagebody-content'>
                    <h2>How will you understand what the Alabama local market needs?</h2>
                    <p>
                        We conduct thorough market research and take the time to understand your 
                        local market and target audience. Our team collaborates with you closely to
                        ensure the <Link className="link-builder" to="/websites">website </Link> 
                        meets the specific needs of your region and customers in Alabama.
                    </p>
                    <div className='cta-button'>
                        <Link to='#contactform'>Free Consultation</Link>
                    </div>
                </div>
                <div className='lpagebody-content'>
                    <h2> Our <Link className="link-builder" to="/websites">Website </Link>Development Services</h2>
                    <p>
                        We offer a comprehensive suite of 
                        <Link className="link-builder" to="/websites">Website design and Development services </Link>
                        tailored to meet your business needs:
                    </p>
                    <ul className='lpagebody_ul'>
                        <li>
                            <span className='lpagebody_span'>Custom Web Design:</span>
                            Tailored designs that capture the essence of your brand.
                        </li>
                        <li><span className='lpagebody_span'><Link className="link-builder" to="/websites">Web Development:</Link></span>Engaging and intuitive user interfaces.</li>
                        <li><span className='lpagebody_span'>E-commerce Solutions: </span>Comprehensive online stores that drive sales.</li>
                        <li><span className='lpagebody_span'>Consultation Services: </span>Expert guidance from concept to launch.</li>
                        <li><span className='lpagebody_span'><Link to="/seo">SEO &</Link><Link to="/digitalmarketing"> Digital Marketing: </Link></span>Strategies to increase visibility and attract more customers.</li>
                        <li><span className='lpagebody_span'>Maintenance & Support: </span>Reliable support to keep your website running smoothly.</li>
                    </ul>
                    <div className='cta-button'>
                        <Link to='#contactform'>Free Consultation</Link>
                    </div>
                </div>
                <div className='lppage-margin'>
                    <div className="reviewsheader_container ">
                        <div className="main_reviewsheader">
                            <div>
                                <h4>Our Reviews</h4>
                                <p>& Clients Feedbacks</p>
                            </div>
                            <Link to="/all-reviews" >See all Reviews</Link>
                        </div>
                        <Reviews />
                    </div>
                    <div className="app_faqscontainer">
                        <h3>Frequently Asked Questions</h3>
                        <div className="app_faqselement">
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(1)}>
                                        What is the First step to develop my website application?
                                    </h3>
                                    {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                                </div>
                                {(showFaqParagraph === 1) && 
                                    <p>
                                        Every website project is a bit complicated and different, so the very first step that 
                                        mark the start of any project is creating a comprehensive plan that will show 
                                        designs, number of pages, features and contents, technology stacks 
                                        to be used, user requirements.
                                        The Plan and agreement will be sent to you before taking the next step.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(2)}>
                                        Who Pays for Hosting?
                                    </h3>
                                    {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 2) && 
                                    <p>
                                        We take care of the yearly hosting of your web application and you don't have to worry about 
                                        it until the following year when it is due for renewal.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(3)}>
                                        Will it be Secure from attacks?
                                    </h3>
                                    {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 3) && 
                                    <p>
                                        Yes, your website or web application will be secured with the SSL certificate implementation 
                                        combined with the entire website package.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(4)}>
                                    Will I be able to edit my Website?
                                    </h3>
                                    {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 4) && 
                                    <p>
                                        We create both custom code and CMS based website or web applications and grant you access to 
                                        the files.
                                        it is straightforward to edit on our CMS based web pages and you need a bit of coding 
                                        skills to edit the custom code based web applications
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(5)}>
                                        I have A Facebook Page do I need a website?
                                    </h3>
                                    {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 5) && 
                                    <p>
                                        Your Costumers are more inclined to get information regarding the product on the
                                        website rather than on face book app. Websites have always been ways to get 
                                        information about the product and services of the company.You have entire control of your 
                                        content and there is no limitation on how to showcase your service/products.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(6)}>
                                        How much does it cost ?
                                    </h3>
                                    {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 6) && 
                                    <p>
                                        While custom website application development may have a higher upfront cost than 
                                        off-the-shelf solutions, it can be more cost-effective in the long run.
                                        Custom software eliminates additional licenses or subscriptions. 
                                        It can reduce costs associated with manual processes, inefficiencies,
                                        or workarounds required with generic software.
                                    </p>
                                }
                            </div>
                            <div className="faq_element">
                                <div className="faq_elementdiv">
                                    <h3 onClick={()=>setShowFaqParagraph(7)}>
                                        Can I add new Features to my website?
                                    </h3>
                                    {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                                </div>
                                {(showFaqParagraph === 7) && 
                                    <p>
                                        Custom Website development typically includes ongoing support and 
                                        maintenance services from our development team. This ensures prompt
                                        assistance in case of adding new features, new updates, or enhancements. 
                                        The development team has in-depth knowledge of the software's 
                                        architecture, making it easier to address update and changes.
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lpagebody-content lp-conclusion'>
                <h2>About Global Mclien Software Solutions</h2>
                    <p>
                        <Link className="link-builder" to="/">Global Mclien Software Solutions </Link>is a leading 
                        <Link className="link-builder" to="/websites"> website development </Link>
                        company dedicated to delivering innovative solutions that drive business 
                        success. With years of experience and a passion for technology, 
                        we help businesses create impactful <Link className="link-builder" to="/websites">Web applications.</Link>
                    </p>
                <div className='cta-button'>
                    <Link to='#contactform'>Free Consultation</Link>
                </div>
            </div>
        </>
    )
}

export default React.memo(WebsiteDesignInLaVerne);