import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { FaFacebookF ,FaYoutube, FaLinkedinIn, FaInstagram, FaTwitter} from "react-icons/fa";


const Footer =() =>{
    const [showSuccessUi, setShowSuccessUi] = useState(true);
    const [email, setEmail] = useState('');

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
      }

    const handleSubmit = async (e:any) =>{
        if(!email) return alert('Please enter valid email');
        e.preventDefault();
        const data:any = {
            email,
            subscriptionsource:'Global McLien Software Solutions'
        }
        fetch("https://generalserver-d8b495642206.herokuapp.com/newslettersubscription",
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(data)
            })
            .then(function(res){ setShowSuccessUi(true) })
            .catch(function(res){ setShowSuccessUi(true) })

    }
    return(
        <>
            <div className="footer_content">
                <div className="footer_links">
                    <div className="footerlinks_content">
                        <div>
                            <Link to="/">
                                <img className="topbar-logo" src="/images/nobglogo.png" alt="Global Mclien Software Solutions logo"  />
                            </Link>
                        </div>
                        <ul className="footerlinks_content_ul">
                            <li><a href="tel:+7472498760">+1(747) 249 8760</a></li>
                            <li><a href="mailto:info@globalmcliensoftwaresolutions.com">info@globalmcliensoftwaresolutions.com</a></li>
                            <li>
                                <div>
                                    <ul className="footerlinks_socialmedia">
                                        <li>
                                            <a href="https://www.facebook.com/softwaredevbytes">
                                                <FaFacebookF/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="www.youtube.com/afeez20">
                                                <FaYoutube/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/in/afeez-badmos-19962333">
                                                <FaLinkedinIn/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/softwaredev__bytes">
                                                <FaInstagram/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.twitter.com/afeez20">
                                                <FaTwitter/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="footerlinks_content">
                        <h2>Services</h2>
                        <ul className="footerlinks_content_ul">
                            <li><Link to="/data">Data Engineering &amp; AI</Link></li>
                            <li><Link to="/software">Software Services</Link></li>
                            <li><Link to="/cloud">Cloud Engineering</Link></li>
                            <li><Link to="/mobileapp">Mobile App Development</Link></li>
                            <li><Link to="/websites">Web Applications</Link></li>
                            <li><Link to="/digitalmarketing">Digital Branding | Strategy</Link></li>
                        </ul>
                    </div>
                    <div className="footerlinks_content">
                        <h2>Company</h2>
                        <ul className="footerlinks_content_ul"> 
                            <li><Link to="/press">Press</Link></li>
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/privacy">Privacy Policy</Link></li>
                            <li><Link to="/legal">Legal</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/contact">Contact us</Link></li>
                        </ul>
                    </div>
                    <div className="footerlinks_content">
                        <h2>Newsletter</h2>
                        <div className="newsletter">
                        <p>Join our newsletter to stay updated about our latest news and articles</p>
                        {showSuccessUi ?
                            <div className='success-ui'>
                                <p>Subscribed successfully!!!</p>
                                <button onClick={()=>setShowSuccessUi(false)} type='button'>Close</button>
                            </div>  
                            : 
                            <form  onSubmit={handleSubmit}>
                                <div className="newsletter_form" >
                                    <input className="newsletter_input" onChange={onChange} type="email" placeholder='Your best email...' required/>
                                    <input className="newsletter_submit" type="submit" value="SUBSCRIBE" />
                                </div>
                            </form>
                        }
                    </div>
                    </div>
                </div>
                <div className="map_container">
                    <h2>Where We Operate</h2>
                    <div className="map_content">
                        <div className="map">
                            <img 
                                src="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/609147088669907f652110b0_report-an-issue(about-maps).jpeg" 
                                alt="Global Mclien Software Solutions" 
                                className='footer_mapbox'
                            />
                        </div>
                        <div className="map_locations">
                            <ul className="map_locationsUl"> 
                                <li>
                                    <Link to="/landingpage">Software Development in California</Link>
                                </li>
                                <li>
                                    <Link to="/landingpage">Software Development in Newyork</Link>
                                </li>
                                <li>
                                    <Link to="/landingpage">Software Development in Europe</Link>
                                </li>
                                <li>
                                    <Link to="/landingpage">Software Development in North America</Link>
                                </li>
                                <li>
                                    <Link to="/landingpage">Software Development in Africa</Link>
                                </li>
                                <li>
                                    <Link to="/sitemaps">See More locations...</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-container">
                <p>&copy;  {`${new Date().getFullYear()}`} Global Mclien Software Solutions. </p>
                <div className='copyright_element'>
                    <p>
                        <Link to="/sitemaps"> Sitemaps</Link>
                    </p>
                    <p><Link to='/privacy'>Privacy Policy</Link>  | <Link to='/termsandconditions'>Terms</Link>  </p>
                </div>
            </div>
        </>
    )
}

export default React.memo(Footer);