import React from 'react';
import { Link } from 'react-router-dom';

const Legal =() =>{

    return(
        <div className='privacydiv'>
        <h1>Legal Information</h1>
        <div>
            <h3 className='privacy-hthree'>Introduction</h3>
        </div>
        <div className="privacycontent">
            <p className='privacyp-tag'>
                Welcome to Global Mclien Software Solutions  ("we," "our," "us"). This Legal Information page outlines
                the terms and conditions governing the use of our website and services. By accessing or using our website, 
                you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, 
                please do not use our website.
            </p>
        </div>
        <div className="privacy-body">
            <h2>1. Intellectual Property</h2>
            <p className='privacyp-tag'>All content on this website, including text, graphics, logos, icons, images, audio clips, 
                digital downloads, and software, is the property of Global Mclien Software Solutions or its content suppliers and is protected 
                by international copyright laws. The compilation of all content on this site is the exclusive property of 
                Global Mclien Software Solutions, protected by international copyright laws.
            </p>
        </div>
        <div className="privacy-body">
            <h2>2. Use of Our Website</h2>
            <p className='privacyp-tag'>All content on this website, including text, graphics, logos, icons, images, audio clips, 
            You are granted a limited license to access and make personal use of this site. This license does not include any resale 
            or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; 
            any derivative use of this site or its contents; or any use of data mining, robots, or similar data gathering and extraction tools.
            </p>
        </div>
        <div className="privacy-body">
            <h2>3. User Conduct</h2>
            <p className='privacyp-tag'>
            You agree to use the website only for lawful purposes. You are prohibited from posting on or transmitting through 
            the website any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually explicit,
             profane, hateful, racially, ethnically, or otherwise objectionable material of any kind.
            </p>
        </div>
        <div className="privacy-body">
            <h2>4. Privacy</h2>
            <p className='privacyp-tag'>
            Your use of our website is also governed by our Privacy Policy. Please review our Privacy Policy for information on how we 
            collect, use, and protect your personal data.
            </p>
        </div>
        <div className="privacy-body">
            <h2>5. Disclaimers and Limitation of Liability</h2>
            <p className='privacyp-tag'>
            This website is provided by Global Mclien Software Solutions on an "as is" and "as available" basis. Global Mclien Software 
            Solutions makes no representations or warranties of
            </p>
        </div>
        <div className="privacy-body">
            <h2>6. Contact Us</h2>
            <p className='privacyp-tag'>If you have any questions or concerns about this Legal Statement, please contact us at:</p>
            <div className="privacy-body">
                    <Link to="/">Global Mclien Software Solutions</Link>
                <address>
                    25630 Felicia Avenue , Menifee,
                    CA92586
                </address>
                <a href="mailto:developer@softwaredevbytes.com">info@globalmcliensoftwaresolutions.com</a>
                <p>
                <a href="tel:+17472498760">7472498760</a>
                </p>
            </div>
        </div>
    </div>
    )
}

export default React.memo(Legal);