import React from 'react';
import { Link } from 'react-router-dom';

const NotFound =() =>{

    return(
        <div className='error404'>
            <h1>Oops!!! Page not Found</h1>
            <div className='error404_div'>
                <Link to='/'>Go back to home page</Link>
            </div>
        </div>
    )
}

export default React.memo(NotFound);