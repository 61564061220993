import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { MdFilterCenterFocus } from "react-icons/md";
import {FaChalkboardTeacher ,FaCertificate, FaAmazonPay} from "react-icons/fa";
import { TfiWrite , TfiGame } from "react-icons/tfi";
import { BsTelephone  } from "react-icons/bs";
import { FcDeployment,FcOnlineSupport, FcAutomatic} from "react-icons/fc";
import {AiOutlineAudit ,AiFillAccountBook,AiOutlineSecurityScan, AiOutlineDeploymentUnit, AiOutlinePayCircle } from "react-icons/ai";
import { GiPlatform } from "react-icons/gi";
import { EmailJsComponent } from '../../utility/EmailJsComponent';
import Reviews from '../Reviews';
import FooterForm from '../Footer/FooterForm';


const CustodialWallet =() =>{
    const[showMoreIndustries, setShowMoreIndustries] = useState(false);
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);
    const navigate = useNavigate();

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' 
                content=
                'Cryptocurrency,ethereum  exchanges,wallet, ethereum  algorithms,Defi, ethereum  platform DAOs, OpenZepplin COntracts, ethereum '
                />
                <meta name='description' 
                    content='Global Mclien Software Solutions is a leading ethereum  development company with over 14 years of experience 
                    building ethereum  applications by a competent team of the best ethereum  developers, R&D department and ethereum  consultants'
                    />
                <title>Non-Fungible Wallet | Custodial Wallet - Global Mclien Software Solutions </title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/custodialwallet" />
            </Helmet>
            <div className='component_container ' >
                <div className='smartcontract'>
                    <div id="scroll-container">
                        <div id="scroll-text">
                            <div className='smartcontract_div'>
                                <span id="scroll-span">ethereum </span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">DogeCoin</span>
                                <span>&#9660;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Shiba</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">XRP</span>
                                <span>&#9660;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Solana</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Polygon</span>
                                <span>&#9660;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Cardano</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Solidity</span>
                                <span>&#9660;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Binance</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Tether</span>
                                <span>&#9660;</span>
                            </div>
                        </div>
                    </div>
                    <div className="hero_message_content">
                        <div className="hero_message_topdiv">
                            <h1 className="hero_message_h1">Welcome to GMSS Wallet: Your Trusted Custodial Wallet Development Partner</h1>
                            <ul className="hero_message_ul">
                                <li>Security</li>
                                <li>DAO Development</li>
                                <li>Innovations</li>
                                <li>Custom Solutions</li>
                                <li>Support</li>
                            </ul>
                            <p className="hero_message_p">
                                At GMSS Wallet, we specialize in building secure and user-friendly custodial 
                                wallet solutions that empower users to securely store, manage, and transact their
                                digital assets. With a focus on cutting-edge security measures and intuitive user 
                                experiences, we're dedicated to providing the best-in-class custodial wallet solutions
                                for individuals and businesses alike.
                            </p>
                            <div className='smart_buttoncontainer'> 
                                <Link className="heromessage_links" to="/blockchainpackages">Our Packages</Link>
                                <Link className="heromessage_links" to="/contact">Book Free Consultation</Link>
                            </div>
                        </div>
                        <div className="hero_message_image">
                            <img src="/images/smartcontractnobg.png"  alt="smart contract for global mclien software solutions"  />
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Project Consultation</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>

                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div>
                        <h2 className="sub_htwo">Why Choose Our <span className="container_span">Custodial Wallet</span></h2>
                    </div>
                    <div className="reason_container">
                        <div className="reason_element">
                            <FaCertificate />
                            <p>Certified Experts</p>
                            <div>
                                <p className="reason_elementP">
                                    Count on the team of 500+ certified ethereum experts who have the experience of 
                                    working on a multitude of wallet projects, fulfilling the needs of a various
                                    set of industries and businesses.                               
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <MdFilterCenterFocus />
                            <p>Client-Focused Approach</p>
                            <div>
                                <p className="reason_elementP">
                                    Our focus lies not just in building products but also analyzing all risks while 
                                    providing solutions. Our USP lies in adding value to your project by presenting 
                                    innovative ideas.                        
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <AiOutlineAudit />
                            <p>Compliance-Assured Products</p>
                            <div>
                                <p className="reason_elementP">
                                    We build wallets that meet all regulatory requirements. Custodial and Non-Custodial 
                                     we ensure that your <Link className="link-builder" to="/mobileapp" > app </Link>is 
                                     absolutely compliance-ready. 
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <FcOnlineSupport />
                            <p>24/7 Support</p>
                            <div>
                                <p className="reason_elementP">
                                    A <Link className="link-builder" to="/software" > software product </Link>requires regular 
                                    maintenance and upgrade post deployment. Leverage our
                                    24*7 support ethereum services to prevent glitches at all times and scale your <Link className="link-builder" to="/mobileapp" > app </Link>as per
                                    user requirements.                           
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whatwedo_container">
                    <div className="whatwedo_containerdiv">
                        <h3>Our Wallet Solutions deployed</h3>
                        <p>&amp; tested by several industries including:</p>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div>
                                <GiPlatform/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Crowdfunding Platforms</h4>
                                <p>
                                    Crowdfunding, ICO, MLM projects. You can create a smart 
                                    contract and issue your own tokens on the ethereum in 
                                    order to attract the interest and capital of investors to 
                                    implement your business idea as quickly and efficiently as
                                    possible.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <AiFillAccountBook/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Development of a personal investor account</h4>
                                <p>
                                    Creating a user interface (front-end) for interacting 
                                    with the ethereum <Link className="link-builder" to="/smartcontracts" > smart contract</Link>
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <AiOutlineDeploymentUnit/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>DAPP ethereum apps</h4>
                                <p>
                                    Creating ethereum solutions for decentralized
                                    applications. 
                                    You can develop <Link className="link-builder" to="/mobileapp" > mobile </Link>and 
                                    <Link className="link-builder" to="/customwebsites" > web </Link> 
                                    apps that interact with ethereum platforms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div>
                                <FcDeployment/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Private ethereum platform deployment</h4>
                                <p>
                                    Do you need your own independent ethereum to interact
                                    with your counterparties? We can help you deploy a private 
                                    ethereum and create <Link className="link-builder" to="/mobileapp" > apps </Link> to interact with it.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <TfiGame/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Game Apps</h4>
                                <p>
                                    Creating <Link className="link-builder" to="/gaming" > gaming </Link>and gambling platforms on TRON and Ethereum 
                                    ethereum .
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <AiOutlinePayCircle />
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Crypto Payment Platforms</h4>
                                <p>
                                    Creating profitability charts, portfolio structure - clearly and
                                    user-friendly. Investors buying crypto assets that go directly 
                                    in their account and they can monitor the returns.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div>
                                <FaAmazonPay/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Payment Platforms</h4>
                                <p>
                                    Enabling acceptance of cryptocurrency for your project or personal
                                    account. Using internal billing, crypto payment processing and 
                                    token accrual.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <FcAutomatic/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Automation</h4>
                                <p>
                                    Build automated systems that require minimal human input through 
                                    smart contracts.<Link className="link-builder" to="/dao" > Automated Dapps </Link>are the perfect choice for solving 
                                    real-world problems in many ways.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <AiOutlineSecurityScan/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Security</h4>
                                <p>
                                    Security and uniformity of ethereum  development is ensured by 
                                    the <Link className="link-builder" to="/dao" > decentralized  </Link>nature and consensus algorithms of the ethereum 
                                    network. No party to a <Link className="link-builder" to="/smartcontracts" > smart contract </Link>is able to make changes to the
                                    smart contract once it is placed on the ethereum  network.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="other_industries">
                    <h4>Industries with established Custodial Wallet implementations</h4>
                    <div className="otherindustrie_items">
                        {showMoreIndustries ? (
                            <>
                                <div className="other_industrieselement">
                                    <div>
                                        <Link to="/">Real Estate</Link>
                                    </div>
                                    <div>
                                        <Link to="/">AR| VR</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Dating</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Affiliate Marketing</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Gambling</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Betting</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Trading</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Retail</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Media</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Telecom</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Manufcaturing</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Insurance </Link>
                                    </div>
                                    <div>
                                        <Link to="/">Construction </Link>
                                    </div>
                                    <div>
                                        <Link to="/">Education</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Finance</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Transportation and Logistics </Link>
                                    </div>
                                    <div>
                                        <Link to="/">Automative </Link>
                                    </div>
                                    <div>
                                        <Link to="/">IoT</Link>
                                    </div>  
                                </div>
                                <div className="persona_button"> 
                                    <button type="button" className="persona_buttonOne" onClick={()=>navigate('/moreindustrylist')}>See All Industry Lists</button>
                                    <button type="button" className="persona_buttonOne" onClick={()=>setShowMoreIndustries(false)}>Close List</button>
                                </div>
                            </>
                        ) : (
                            <button className="other_industriesbutton" onClick={()=>setShowMoreIndustries(true)} type='button'>Click to see more Industries</button>
                        )}
                    </div>
                </div>
                <div className="reviewsheader_container">
                    <div className="main_reviewsheader">
                        <div>
                            <h4>Our Reviews</h4>
                            <p>& Clients Feedbacks</p>
                        </div>
                        <Link to="/all-reviews" >See all Reviews</Link>
                    </div>
                    <Reviews />
                </div>
                <div className="whychoose_us">
                    <div>
                        <h2>Why Choose US?</h2>
                    </div>
                    <div className='paracontent-conainer'>
                        <p>
                            <span className='bold-text'>1. How can I trust GMSS Wallet with my digital assets? </span>
                           At <Link className="link-builder" to="/"> GMSS </Link>Wallet,
                             security is our top priority. We implement robust encryption protocols, 
                             multi-factor authentication, and cold storage solutions to ensure the utmost 
                             security for your digital assets. Our team undergoes regular security audits
                              to identify and mitigate potential vulnerabilities, providing you with peace 
                              of mind knowing that your assets are safe with us.
                        </p>
                        <p>
                            <span className='bold-text'>2.Will GMSS Wallet be easy to use for beginners? </span>
                            Absolutely! 
                            We understand the importance of a seamless user experience, especially 
                            for newcomers to the cryptocurrency space. Our custodial wallet solutions
                             are designed with simplicity and intuitiveness in mind, featuring user-friendly
                              interfaces and step-by-step guides to help users navigate the platform with ease.
                        </p>
                        <p>
                            <span className='bold-text'>3. How does GMSS Wallet handle user privacy and data protection? </span>
                             At GMSS Wallet, we respect your privacy and prioritize data protection. 
                             We adhere to strict privacy policies and compliance standards, ensuring 
                             that your personal information and transaction data remain confidential 
                             and secure. Your privacy is our utmost concern, and we're committed to 
                             safeguarding your sensitive information at all times.
                        </p>
                        <p>
                            <span className='bold-text'>4.What kind of support does GMSS Wallet offer? </span>
                           We pride ourselves on
                             providing exceptional customer support to our users. 
                             <Link className="link-builder" to="/about"> Our dedicated support team </Link> 
                             is available around the clock to address any questions, concerns, or 
                             technical issues you may encounter. Whether you need assistance with account setup, 
                             troubleshooting, or general inquiries, our team is here to help you every step of the way.
                        </p>
                        <p>
                            <span className='bold-text'>5.Can GMSS Wallet handle high transaction volumes and growing user demands? </span>
                            Absolutely! Our custodial wallet solutions are built with scalability in mind, 
                            capable of handling high transaction volumes and growing user demands without 
                            compromising performance or security. Whether you're an individual user or a 
                            large-scale enterprise, <Link className="link-builder" to="/about"> GMSS </Link>Wallet can scale to meet your needs and accommodate
                             future growth seamlessly.
                        </p>
                    </div>
                </div>
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    What is the First step to start my ethereum project?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    Every ethereum project is a bit complicated and different, so the very first step that 
                                    mark the start of any project is creating a comprehensive logic that will show 
                                    exact functionality of the project, mitigation plans, security, cost, technology stacks 
                                    to be used, and user requirements.
                                    The Plan will be sent to you with agreement before taking the next step.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                    Will My ethereum project run faster?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    ethereum project are self-executing and automatically enforce the terms and conditions 
                                    encoded within them. They eliminate the need for intermediaries, paperwork, and manual 
                                    processes, streamlining transactions and reducing administrative burdens. This automation
                                     improves efficiency, reduces human errors, and accelerates the speed of contract execution.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    How much do ethereum app development services cost?    
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    The cost of ethereum software development services depends on various factors
                                     such as the complexity of application, number of stakeholders interacting with 
                                     the app, industry compliances, etc. However, on average, <Link className="link-builder" to="/mobileapppackages" > ethereum mobile app </Link>
                                     development costs can vary from $40,000 to $300,000.
                                      <Link className="link-builder" to="/contact"> Get in touch with our team </Link>
                                     of ethereum consulting service experts now for a precise cost estimation.  
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                    How long does it take to build a ethereum app?
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    Depending on the scope of the project, the duration of building a 
                                    <Link className="link-builder" to="/mobileapp" > ethereum application </Link>
                                     can be anywhere between 4-6 weeks and 12-18 months.                         
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                    How to hire the right ethereum developers from a ethereum application development company?
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                    Hiring ethereum developers from a ethereum app development agency is a 
                                    tricky task, just like hiring a <Link className="link-builder" to="/mobileapp" > mobile app development </Link>company. The decision,
                                     however, should vary based on factors like team size, experience, portfolio, 
                                     industry exposure, ratings & reviews, etc.        
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                    What ethereum platforms do you work on?
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <>
                                    <p>
                                        With a number of ethereum development platforms available, we put our 
                                        trust in a few that pass the benchmark of security and scalability. Some 
                                        such ethereum platforms through which we offer state-of-the-art enterprise 
                                        ethereum development services include:
                                    </p>
                                    <ul>
                                        <li>Ethereum</li>
                                        <li>Solana</li>
                                        <li>Cardano</li>
                                        <li>Polkadot</li>
                                        <li>Hyperledger</li>
                                        <li>Tron</li>
                                        <li>Stellar</li>
                                        <li>Corda</li>
                                        <li>Quorum</li>
                                        <li>Multichain</li>
                                    </ul>
                                </>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                    How can I assess the progress of my ethereum project?
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                    <Link className="link-builder" to="/"> Global Mclien Software Solutions </Link>follows an agile development methodology, which
                                     keeps communication between the client and the development team always open and 
                                     transparent. Our team not only updates the progress of ethereum solutions 
                                     development at regular intervals to clients, but is also always motivated to work 
                                     on any last minute iterations to ensure 100% client satisfaction.       
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <FooterForm field="Get A Quote"/>

            </div>
        </>
    )
}

export default React.memo(CustodialWallet);