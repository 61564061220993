import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Donation =() =>{

    return(
        <div className="standard">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='Reviews, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance donations'
                    />
                <title>Donations - Global Mclien Software Solutions | Mobile Apps Reviews</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/donations" />
            </Helmet>
            <div >
                <div className="standard_header">
                    <h1 className="standard_hone">Give Education</h1>
                    <h6>Global Mclien Software Solutions</h6>
                    <p>100% of Online donations go to helping freelancers continue their love for learning to write</p>
                </div>
                <div className="donationContainer">
                    <div className="card_header">
                        <h4>Make a one-time donation</h4>
                        <h6>A helping hand changes lives and it starts with you</h6>
                    </div>
                    <div className="card_blogelement customInput">
                        <p>I'm giving:</p>
                        <input type="text"  placeholder='$35 custom amount'/>
                    </div>
                    <div className="card_blogelement customButon">
                        <a className="card_Links" title='click to donate $20' href='https://buy.stripe.com/9AQg20b7n2p56goeUX'>$20</a>
                        <a className="card_Links"  title='click to donate $30'  href='https://buy.stripe.com/3cseXWgrH3t9awEeUY'>$30</a>
                        <a className="card_Links" title='click to donate $50'  href='https://buy.stripe.com/bIY5nmejzbZF6go3ce'>$50</a>
                        <a className="card_Links" title='click to donate $50'  href='https://buy.stripe.com/bIY5nmejzbZF6go3ce'>OTHER</a>
                    </div>
                    <div className="otherways_container">
                        <Link to='/pricing' className="card_otherways">Other Ways You Can Give.</Link>
                    </div>
                    <div className="card_blogelement cardpaypal">
                        <span className="card_Links" title='use this zelle phonenumber 7472498760'>Zelle: 7472498760</span>
                        <span className="card_Links" title='use this paypal account afeez20'>Paypal: afeez20</span>
                    </div>
                   

                </div>
            </div>
           
        </div>
    )
}

export default React.memo(Donation);