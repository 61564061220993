import React ,{useState} from 'react';
import FooterForm from '../components/Footer/FooterForm';
import Reviews from '../components/Reviews';
import { Helmet } from 'react-helmet';
import {  Link, useNavigate } from 'react-router-dom';
import TrustFactors from '../components/TrustFactors';
import { TfiWrite } from "react-icons/tfi";
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";
import {FaChalkboardTeacher, FaRegLightbulb , FaUserSecret ,FaGlobe,FaRocket } from "react-icons/fa";
import { EmailJsComponent } from '../utility/EmailJsComponent';

const Backlinks =() =>{
    const navigate = useNavigate();
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='seo, ppc, branding, digital marketing, design, traffic, social media management, run ads ,online advertising'/>
                <meta name='description' 
                    content=
                    "Boost your website's authority and visibility with our premium backlink services. At Global Mclien Software Solutions, we specialize in building high-quality backlinks that drive organic traffic and improve search engine rankings. Our expert team employs proven strategies and ethical practices to acquire authoritative links from reputable sources. Whether you're looking to enhance your SEO efforts or establish credibility in your industry, we have the expertise to deliver results. Partner with us to strengthen your online presence and outperform the competition. Contact us today to explore our backlink services and take your website to the next level!"
                    />
                <title>Organic Backlinks Agency | Full Service Marketing Agency</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/backlinks" />
            </Helmet>
            <div className='component_container'>
                <div className="hero_container digitalPage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Welcome to GMSS : Boosting Your Online Authority with Quality Backlinks</h1>
                                <ul className="hero_message_ul">
                                    <li> Backlink Analysis</li>
                                    <li>Outreach and Link Building</li>
                                    <li>Guest Posting</li>
                                    <li>Broken Link Building</li>
                                    <li>Content Promotion</li>
                                    <li>Link Cleanup</li>
                                </ul>
                                <p className="hero_message_p">
                                    At GMSS, we specialize in helping businesses like yours enhance their online visibility and authority 
                                    through strategic backlink building techniques. With our expertise and dedication, we'll help you climb 
                                    the <Link className="link-builder" to="/localrankings" >search engine rankings </Link>and attract more organic traffic to your website.
                                </p>
                                <div>
                                    <a className="hero_message_button" href="#content_form">Book A Free Strategy Call</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/digitalmarketing.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Marketing Strategy</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Detailed strategy</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home_aboutcontainer">
                <div className="whychoose_us">
                    <div>
                        <h2>Why Choose Global Mclien Software Solutions?</h2>
                    </div>
                    <div className='paracontent-conainer'>
                        <p>
                            <span className='bold-text'>1.Expertise: </span>
                            Our team consists of skilled backlink specialists who understand the intricacies of 
                            link building and know how to acquire high-quality backlinks that drive results.
                        </p>
                        <p>
                            <span className='bold-text'>2.Customized Strategies: </span>
                            We don't believe in one-size-fits-all approaches. We'll work closely with you to develop 
                            a customized backlink strategy tailored to your business goals and target audience.
                        </p>
                        <p>
                            <span className='bold-text'>3.Quality Over Quantity: </span>
                            We prioritize quality over quantity when it comes to backlinks. Our focus is on acquiring
                             authoritative and relevant links that positively impact your website's ranking and credibility.
                        </p>
                        <p>
                            <span className='bold-text'>4.Transparent Process: </span>
                            Transparency is key to our approach. We'll keep you informed every step of the way,
                             providing detailed reports and updates on the progress of your backlink campaign.
                        </p>
                        <p>
                            <span className='bold-text'>5.Long-Term Results: </span>
                            We're committed to delivering long-term results for your business. Our sustainable 
                            backlink strategies are designed to strengthen your online presence and drive continuous growth.
                        </p>
                    </div>
                </div>
                    <div className="about_ourservices">
                        <span>We Help You</span>
                        <h4>Gain A Competitive Edge</h4>
                        <div className="about_illustration">
                            <div className="illustratin_container">
                                <FaRegLightbulb />
                                <div className="illustration_content">
                                    <h6>Gain Insights on your Competition</h6>
                                    <p>
                                        We reveal how you stack up against who <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">Google </a>
                                        thinks is your competition  and the areas you need the most improvement, then show you what your
                                        competition is doing behind the scenes to improve their own online visibility.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaUserSecret />
                                <div className="illustration_content">
                                    <h6>Grow Leads and Sales</h6>
                                    <p>
                                        Understanding your local market and the competition in it is critical for growth.
                                        That's why we use our exclusive Footprint Technology to conduct a competitor and
                                        market analysis, with <a href="/websites" className="backlinks">website </a> and <a href="/seo" className="backlinks">SEO assessment </a>
                                        to reveal what's impacting your success.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaGlobe />
                                <div className="illustration_content">
                                    <h6>Track your Result</h6>
                                    <p>
                                        Managing a small business near me in Los Angeles, California can be time-consuming without 
                                        the worry of managing your digital marketing. With <a href="/home" className="backlinks">Global Mclien Software Solutions's </a> exclusive 
                                        Dashboard reports, you can easily keep track of our team's work, 
                                        leads, and channel performance in real time and provide feedback to
                                        our team on the lead quality you're getting.
                                    </p>
                                </div>
                            </div>
                            <div className="illustratin_container">
                                <FaRocket />
                                <div className="illustration_content">
                                    <h6>Strenghten your Brand</h6>
                                    <p>
                                        A solid online presence for small businesses is crucial.
                                        We start by creating a professional, fast <a href="/websites" className="backlinks">website </a>
                                        to ensure an excellent first impression. Then we, recommend getting a 
                                        <a href="/mobileapp" className="backlinks"> mobile app </a> to scale and strenghten your brand.
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <a href="/home" className="backlinks">Global Mclien Software Solutions, </a>we develop marketing strategy that makes your business stay <span className="container_span">Relevant & visible online.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/marketingdata.png" alt="branding" />
                            <h5>Generate you online data</h5>
                            <p>
                                We build a comprehensive data for your marketing campaign 
                                to perform at it's highest capabilities
                                on the major <a href="/seo" className="backlinks">search engines.</a>
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/keyword.png" alt="marketing"  />
                            <h5>Build your Keyword List</h5>
                            <p>
                                We create a detailed keyword list of your top ranking competitors 
                                plug it into your campaign with periodic updates.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/contract.png" alt="presentation"  />
                            <h5>No Contracts</h5>
                            <p>
                                You decide if you want to run a month-to-month 
                                digital marketing campaign or retain a contract 
                                sales campaign.
                            </p>
                        </div>
                    </div>
                    <div className="other_subfeatures">
                            <div className="subfeatures_title">
                                <h2 >Our Services</h2>
                            </div>
                            <div className="subfeatures_contentcontainer">
                                <div className="subfeatures_content">
                                    <h4>SEO</h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/seo.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        We re-edit your keyword listings, update your <Link to='/seo'>seo</Link> process,
                                         get high ranking keywords from your competitors ranking top on search engines,
                                        create/revamp your <a href="https://g.page/r/CXzB0lEvWoVzEBM/review" className="backlinks">google my business page </a>and other local channels, create 
                                        profiles on other search engines such as bing ,start new google ad campaign 
                                        for your products/listings and create a detailed digital marketing strategy with 
                                        measurable progress at every stage of the process 
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card"> Google PPC Advertising </h4> 
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/ppc.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        We create an engaging and user friendly <a href="/websites" className="backlinks">website </a>that
                                         will be easy to use for your customers, which will
                                        reduce your <a href="/websites" className="backlinks">website </a>
                                         bounce rates increasing your <a href="/websites" className="backlinks">website </a>
                                         score on all <a href="/seo" className="backlinks">seacrh engines.</a>
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card">Content Marketing </h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/content.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button' className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        Building a progressive <a href="/websites" className="backlinks">web </a> app isn't just enough in the <a href="/mobileapp" className="backlinks">app </a>business,that's the reason we make sure all
                                        <a href="/websites" className="backlinks"> web </a> application is scalable from initial app versioning, multilevel stages of user authorisation,  
                                        authentication and verification all in a streamlined flow of operation.
                                    </p>
                                </div>
                                <div className="subfeatures_content">
                                    <h4 id="card"> Social Media Marketing</h4>
                                    <div className="card_imagecontainer" onClick={()=> navigate("/contact")}>
                                        <img className='card_image' src="/images/socialmedia.png" alt="Global Mclien Software Solutions" />
                                        <button  type='button'  className='websitecard_button'>Get A Free Quote</button>
                                    </div>
                                    <p>
                                        Landing pages with a high conversion ratio is the bedrock of any digital campaign.We help both your sales team and 
                                        marketing team close, build pipelines and close more leads with our conversion-optimised <a href="/websites" className="backlinks">web pages.</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
                <TrustFactors />
                <div className="reviewsheader_container">
                    <div className="main_reviewsheader">
                        <div>
                            <h4>Our Reviews</h4>
                            <p>& Clients Feedbacks</p>
                        </div>
                        <Link to="/all-reviews" >See all Reviews</Link>
                    </div>
                    <Reviews />
                </div>
                <div className="whychoose_us">
                    <h2>Get Started Today</h2>
                    <p className='p_content'>
                    Ready to take your website to the next level with quality backlinks? Contact us today for a free consultation and discover how GMSS can help you achieve your online goals.
                    </p>
                    <p>
                    <span className='bold-text'>Keywords: </span>
                    backlinks, link building, backlink analysis, guest posting, outreach, broken link building, content promotion, link cleanup, website authority, SEO, organic traffic.

                    </p>
                    <div>
                        <Link className="hero_message_button"  to="/contact">Talk To Us</Link>
                    </div>
                </div>
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                I've heard that backlinks can hurt my website's ranking.
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                   While low-quality or spammy backlinks can indeed harm your website's ranking, our focus is on acquiring high-quality, authoritative backlinks that positively impact your website's credibility and visibility. With our strategic approach, you can trust that your website is in safe hands.

                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                I don't see the immediate value of investing in backlinks.
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                   While the effects of backlink building may not be instantaneous, they are long-lasting. Quality backlinks serve as a vote of confidence in your website's authority and relevance, leading to improved search engine rankings and increased organic traffic over time.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                I'm worried about the cost of backlink building services.
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                   We understand that budget is a concern for many businesses, which is why we offer flexible pricing options to suit your needs. Our focus is on delivering value and tangible results that justify the investment in your website's long-term success.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                I've tried building backlinks myself and didn't see any results.
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    Effective backlink building requires a combination of expertise, resources, and strategic planning. Our team of backlink specialists has the experience and knowledge to identify opportunities, execute outreach campaigns, and acquire quality backlinks that make a difference for your website.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                I'm concerned about the risk of penalties from search engines.
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                   Our backlink building strategies are ethical, white-hat practices that comply with search engine guidelines. We prioritize quality and relevance in our link acquisition efforts to minimize the risk of penalties and ensure long-term success for your website.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                I'm already ranking well on Google, so I don't need SEO.
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <p>
                                    While it's great that you're already ranking well, SEO is not a one-time effort. Continuous optimization is necessary to maintain and improve your rankings, stay ahead of competitors, and adapt to changes in search engine algorithms. Our services can help ensure your business maintains its strong online presence over time.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                   What kind of assisstance can i expect after my campaign go live?
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                   You’ll receive the support of your Digital Consultant as well as a Customer Success Manager who will help with strategy, reporting, internal communication, and asset trafficking. In addition, you’ll have specialists working on every aspect of your campaign to ensure consistent optimizations to deliver desired outcomes.                              
                                </p>
                            }
                        </div>
                    </div>
            </div>
                <FooterForm field="Book A Free Strategy Call"/>
            </div>
        </>
    )
}

export default React.memo(Backlinks);