import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import FooterForm from '../Footer/FooterForm';


const PlainLayout =() =>{

    return(
        <>
            <Header />
            <div className="plainlayout_container">
                <Outlet />
            </div>
            <FooterForm field="Get In Touch"/>
            <Footer />
        </>
    )
}

export default PlainLayout;
