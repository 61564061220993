import React from 'react';
import { Helmet } from 'react-helmet';
import { AiFillWechat } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import {Link, useNavigate} from 'react-router-dom';


const PackagesPage =() =>{
    const navigate = useNavigate();

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>Packages - Global Mclien Software Solutions</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/packages" />
            </Helmet>
            <div className="hero_container homePage">
                <div className="hero_message">
                    <div className="hero_message_content">
                        <div >
                            <p className="hero_message_contentP">Take Control, Choose one / more package OR customise your package</p>
                            <h1 className="hero_message_h1">Package List</h1>
                            <p className="hero_message_p">
                                Find the package that suits your needs and the scale of your business at an affordable and transparent cost.
                                Each package is customised and fully scalable to provide a combination of services that work effectively 
                                together for your business scalability.
                            </p>
                            <div className="hero_packagebutton">
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Web Design'})}>Web Design</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'eCommerce Packages'})}>eCommerce Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Shopify Packages'})}>Shopify Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'SEO Packages'})}>SEO Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Digital Marketing'})}>Digital Marketing</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Web Maintainance'})}>Web Maintainance</button>
                                </div>
                            </div>
                        </div>
                        <div className="heropackage_img"><img src="/images/package.png" alt="Global mclien package" /></div>
                    </div>
                </div>
            </div>
            <div className="packagesContainer">
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>2 Revisions</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Basic <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$1250</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >3 Page Website</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >3 Stock Images</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Contact Query Form</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>1yr SSL</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Domain Name</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Hosting</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Mobile Responsive</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Basic Package',
                                        price:'1250'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>5 Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Standard <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$1750</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >8 Page Website</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >8 Stock Images</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                    <p className="packagesContentcheck">&#10004;</p>
                                        <p>Contact Query Form</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Google Sitemap</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>1yr SSL</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Domain Name</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Hosting</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Mobile Responsive</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Standard Package',
                                        price:'1750'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>10 Revisions</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Elite <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$2,250</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >10 Unique Page Website</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Admin Panel Support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Mobile Responsive</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Google Sitemap</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>1yr SSL</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Domain Name</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Hosting</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>C M S</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Codebase Ownership</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Online Payment Integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Appointment Tool</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>FREE Google Friendly Sitemap</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'Elite Package',
                                            price:'2250'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="packagesContainer lower">
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br /> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">eCommerce <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$4,250</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Unlimited Web Pages</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Admin Panel Support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >UX | UI</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Performance and analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integrations</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Trend Analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>1yr SSL</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Domain Name</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Hosting</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Analytics and Dashboards</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Invoice templates</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Mobile Notifications</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>FREE Google Friendly Sitemap</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>User Signup/Login Functionalities</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Technical Support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'eCommerce Package',
                                            price:'4250'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br /> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Web Application <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$8,750</h3>
                            <p className="packagesElement_description">
                                Suitable for B2B & Established business with Scalable business concepts.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Unlimited Web Pages</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >UI | UX</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>API integrations</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Google Analytics integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Unlimited Revisions</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Mobile Responsive Design</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Image Optimization</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Site Optimisation</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>1yr SSL</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Domain Name</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Hosting</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Search Bar</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Chat bot and chat widgets</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Technical Support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'Web Application Package',
                                            price:'8750'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br/> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Social Media <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$16,599</h3>
                            <p className="packagesElement_description">
                                Suitable for potential Social Media contents startegy for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Unlimited Web Pages</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Online Integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >15 Seconds Explainer Video</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Voice Over & Sound Effects</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Script Writing</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Story Board</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>1yr SSL</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Domain Name</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Hosting</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>SEO Meta Tags</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Online Integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Custom Dynamic Forms</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Admin Profile Management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>User Profile Management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Third Party Integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Easy Search</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Push Notification</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Content Management System(CMS)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Push Notification</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>User Profile Management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Signup Area(Newsletter, Offers)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Content Sharing</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Privacy and Security</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free Platform Designs</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Technical Support</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Social Media Package',
                                        price:'16599'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(PackagesPage);