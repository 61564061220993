import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {FaChalkboardTeacher } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import Reviews from './Reviews';
import FooterForm from './Footer/FooterForm';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import { Link } from 'react-router-dom';

const Gaming =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='data management, analytics , software development ,data quality, data access, data security'/>
                <meta name='description' 
                    content="Embark on an adventure with our expert game development services.
                     At Global Mclien Software Solutions, we specialize in creating immersive 
                     gaming experiences that captivate players and fuel engagement. 
                     From concept to launch, our team brings your ideas to life with stunning visuals, 
                     compelling gameplay, and seamless mechanics. Whether you're looking to create
                      a mobile game, PC game, or virtual reality experience, we've got the expertise 
                      to make it happen. Level up your gaming journey with us. Contact us today to 
                      start building your next gaming masterpiece!"
                    />
                <title>Global Mclien Software Solutions - Game Development Company</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/gaming" />
            </Helmet>
            <div className='component_container' >
                <div className="hero_container datapage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Imagination, innovation in gaming development!</h1>
                                <ul className="hero_message_ul">
                                    <li>Full Cycle Game Dev</li>
                                    <li>Game Porting | Testing</li>
                                    <li>Android Game Development</li>
                                    <li>iOS Game development</li>
                                    <li>Android Game Development</li>
                                    <li>PC Game Development</li>
                                    <li>3D Game Development</li>
                                    <li>End-To End Game Development</li>
                                </ul>
                                <p className="hero_message_p">
                                    At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link> 
                                    we're not just creating games; we're crafting experiences 
                                    that resonate with players around the globe. As a cutting-edge gaming development 
                                    startup, we're dedicated to pushing the boundaries of creativity and technology to
                                     deliver unforgettable gaming adventures.
                                </p>
                                <div>
                                  <a className="hero_message_button" href="#content_form">Get Started</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/gaming.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free <a href="/websites" className="backlinks"> Website Consultation</a></p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>Gaming creativity meets <span className="container_span">innovation.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/presentation.png" alt="presentation"  />
                            <h5>Exceptional Flexibility and Scalability</h5>
                            <p>
                                We're a custom game development company that adjusts to the scale of the project and is 
                                ready to attract an even greater number of highly skilled experts if necessary for the 
                                successful implementation of the project on schedule.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/delivery.png" alt="marketing"  />
                            <h5>Best Security Practices</h5>
                            <p>
                               Keep calm and don't worry about <Link className="link-builder" to="/data" >data, </Link>system and infrastructure protection:we adhere to strict 
                               protection policy rules inherent in game development outsourcing cooperation and constatntly update 
                               security systems in accordance with the latest innovations
                        </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/customer.png" alt="presentation"  />
                            <h5>Customized Full-cycle Game Development</h5>
                            <p>
                                Our game development agency creates games carefully tailored to your specific needs
                                 and budget expectations.
                                Experienced game development 
                                <Link className="link-builder" to="/customwebsites" > custom-code developers </Link>with
                                 a customised approach to your project.
                            </p>
                        </div>
                    </div>
                 </div>
                <div className="data-objectioncontainer">
                    <div className="data-objections">
                        <h2>
                            Full-Cycle game development
                        </h2>
                        <p>
                            With experience in game development services and hundreds of delivered projects,
                            we guide your game from concept to release. 
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Scalable team - In-house Talent
                        </h2>
                        <p>
                            We can scale our team to satisfy any of your needs, significantly reducing costs and delivering 
                            your project in a limited timeframe.                   
                        </p>
                        <p>
                            Since our game development studio bets on long-term partnership, we keep all professionals
                             under one roof to manage teams effectively for each project.                       
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                             Experts in any style and genre
                        </h2>
                            <p>
                                 Having developed games of various kinds, our artists gained diverse 
                                 experiences to ensure quality content for any genre, from 2D slot
                                  games to full-scale 3D projects.
                            </p>
                    </div>
                </div>
                <Reviews />
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                What is the approach to game development at Global Mclien Software Solutions?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div className='gamefaq'>
                                <p>
                                     Game-Ace follows world-class practices in order to deliver premium-quality games that do
                                      the work they should — attract new players and keep all the existing ones interested. 
                                      First and foremost, we start communicating with each new client, discuss all the project
                                       details, set the time frame for adequate work completion, as well as plan our workflows beforehand.
                                </p>
                                <p>
                                     Our game development company heavily bets on quality, performance, transparency, and continuous 
                                     communication with every client. Since Game-Ace has operated in the global market already for 
                                     17 years, our team knows all the ins and outs of the game development process, being aware of 
                                     how to approach each issue that may pop up during project execution.
                                </p>
                                <p>
                                    So, what does our unique game development approach encompass?
                                </p>
                                <ul className='gamefaq'>
                                    <li>
                                        1.Cross-platform development. Game-Ace specializes in developing video games for a 
                                        broad spectrum of gaming platforms. The proficiency of our team in working with 
                                        game engines, such as Unity or Unreal, enables us to build a product and optimize 
                                        it for mobile, PC, console, or other platforms of your preference. Whether you need 
                                        an iOS mobile game or a large-scale title for consoles, the expertise of specialists
                                         at Game-Ace is more than enough to make it happen.
                                    </li>
                                    <li>
                                       2.Custom-tailored results. Professionals at Game-Ace fine-tune their workflows and development
                                        habits to satisfy our clients’ expectations, inasmuch as each project is unique, so it requires 
                                        a personal touch, which our team is incredibly good at after so many years of delivering games
                                         of different complexity. We start off by discussing your future project’s requirements and 
                                         other details that may shed light on our role in its execution. Such a personalized approach 
                                         helps us quickly identify your needs and offer custom solutions.
                                    </li>
                                    <li>
                                       3.Bet on functionality & quality. We hire and train developers, 2D/3D artists, as well as other 
                                       specialists involved in the game development process with maximum attention paid to their experience,
                                        portfolios, hard/soft skills, etc. That is why you can be confident that your game will be delivered
                                         on time, have all the agreed functionality, and impress your target audience with graphics quality.
                                          After a thorough QA phase, your product will undoubtedly have no critical bugs or any other issues
                                           that may prevent players from enjoying the gameplay. The Game-Ace team is proud of its approach 
                                           to game development because it ensures holistic project execution, creating a feeling of an integral 
                                           product.
                                    </li>
                                    <li>
                                        4.Player-friendly interfaces. Our UI/UX designers and artists are qualified to produce premium-quality
                                         content for any of your games, whether it’s a casual mobile project or an epic action RPG optimized 
                                         for consoles of the latest generation. Equipped with extensive theoretical and practical knowledge 
                                         of how players interact with menus, our designers will craft a unique interface built specifically
                                          for your game.
                                    </li>
                                    <li>
                                        5.One-stop shop. Our game development studio offers the full range of services you might ever need in 
                                        the industry. We can produce and animate art assets, write scripts, build architecture, integrate 
                                        everything into a game engine you choose, etc. Since Game-Ace is a full-cycle game development agency,
                                         our team members are ready to supply you with anything you may want your game to feature, irrespective
                                          of the genre. We are experienced in delivering metaverse, Web3, NFT, XR, mobile, and traditional games.
                                           Thanks to 17 years of professional operation in the international market, our team defies any
                                            challenges because we prioritize client satisfaction more than other aspects of cooperation.  
                                    </li>
                                </ul>

                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                               What Game engines do you use and why?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                            <div className='gamefaq'>
                                <p>
                                    Based on the 17-year experience of Game-Ace, our team relies on the two most functional game engines in the 
                                    industry — Unity and Unreal, preferring one over another depending on our needs. Additionally, sometimes we 
                                    refer to other game development platforms, such as Cocos2d, PlayCanvas, HTML5, and BabylonJS.
                                </p>
                                <p>
                                    In each specific case, we may want to take advantage of Unity or Unreal Engine based on a high number of 
                                    criteria as well as discussing the whole tech stack with our clients. Sometimes you may wish to use only 
                                    a specific game engine for a plurality of reasons.                               
                                 </p>
                                <p>
                                    Consequently, in what cases should you resolve to develop your game using Unity?                             
                                 </p>
                                 <ul className='gamefaq'>
                                    <li>
                                        1.You desire to make a mobile or mid-core game without millions of features. Rumor has it that game 
                                        developers use Unity only when they need to quickly build a simple and low-quality game. However, 
                                        this statement is a misconception that’s far from reality. As a matter of fact, Unity has become an 
                                        incredibly powerful game engine with a lot of features to tweak, excellent graphic support, and many 
                                        other benefits to make use of. Despite this, we suggest developing a mobile or mid-core game using 
                                        Unity because it’s more straightforward, cost-effective, and reasonable. After all, you won’t need 
                                        to reconfigure a lot of stuff to incorporate thousands of custom features for a casual mobile game.
                                    </li>
                                    <li>
                                        2.Your ambition is to develop a decent game within a reasonable timeframe. Since Unity is a user-friendly 
                                        platform, let alone that it has a low entry threshold for your game development team, you will likely get
                                        all the work done more quickly compared to other, more heavyweight game engines without losing any 
                                        quality.                                    
                                    </li>
                                    <li>
                                        3.You want to save resources and still get an awesome game. Game development with Unity, of course, 
                                        isn’t a cakewalk at all, but it’s comparatively developer-friendly, which enables your team to complete 
                                        their tasks more effectively and not for millions of dollars. Besides, you can hire middle Unity game 
                                        developers and artists for this purpose, thanks to an intuitive user interface and a beginner-friendly
                                        programming language that powers this platform. Undoubtedly, C# can’t be called a simple technology, 
                                        but it’s relatively more straightforward than C++, which is the main programming language in Unreal
                                        Engine.
                                  </li>
                                 </ul>
                                 <p>When it comes to AA+ game development, it’s time for Unreal Engine. Why? There are quite a few reasons for 
                                    that.
                                </p>
                                 <ul className='gamefaq'>
                                    <li>
                                        1.It supports premium-quality graphics. If you plan to make a top-notch game for your audience, you’ll 
                                        definitely need the functionality under the hood of Unreal. It allows you to build big open worlds with
                                         hundreds of unique NPCs, points of interest, impressive VFX, both horizontal and vertical levels, etc. 
                                         There’s no denying that the end graphics quality you get is the best beyond compare in the present-day
                                          game development industry, especially if you’d like to use its fifth generation.
                                    </li>
                                    <li>
                                        2.It enables you to configure the internal system as long as you need it. In case your upcoming game needs
                                         to be enormously feature-rich, and you want a custom-tailored game development approach, Unreal Engine is
                                          an open-source solution, implying your developers can even write their own plugins to broaden its 
                                          functionality, as per your request.                                   
                                    </li>
                                    <li>
                                        3.Technology-rich functionality. With its latest update, Unreal Engine has been offering state-of-the-art 
                                        technologies, like Lumen, Nanite, MetaHuman, Pixel Streaming, and many more. If your ambition is to develop
                                         a high-end large-scale game, these technical solutions will come in handy and simplify your team’s 
                                         workflow significantly, which will affect the cost as well as the timeframe needed to finalize the 
                                         project.                                   
                                    </li>
                                 </ul>
                                 <p>
                                    As you can see, both game engines are perfectly fine for their purposes. That’s why you should get in touch 
                                    with our game development company and discuss your project in great detail for us to evaluate it, calculating
                                     the cost, time, as well as other resources. All the preliminary work and preparations are essential when it 
                                     comes to game development since they allow for process optimization, which is our central priority — to help 
                                     you reach your goal optimally.
                                 </p>

                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    How long does it take to develop a game?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                            <div className='gamefaq'>
                                <p>
                                    This question is as complex and multi-dimensional as everything touching upon the production stage as well 
                                    as its costs. In other words, it's incredibly difficult to estimate the number of hours needed to develop a 
                                    game since you should consider a broad spectrum of variables, such as genre, style, type, scale, scope, 
                                    budget, team composition, its seniority level, etc. You cannot tell right away what deadline to make for 
                                    your development team. This requires research.                                   
                                </p>
                                <p>
                                    Let's dive into each variable affecting the time frame for your game project.
                                </p>
                                <ul className='gamefaq'>
                                    <li>
                                        1.Scale. The mathematics here is pretty straightforward — the simpler the game, the less time your game
                                         development team will require to implement all the features and release the product. In contrast, the 
                                         more complex and feature-rich the project, the more hours you'll have to spend in order to get everything
                                          done perfectly. Such aspects as your game's depth, number of levels, pixels of an open world, or polygons in your models (for 3D) will significantly affect the deadline you'll want to establish for the team.                                    </li>
                                    <li>
                                        2.Resources. This variable isn't only about money. Resources consist of people, hardware, software, and 
                                        everything you can utilize in order to make your game. Without a doubt, if you have a capable team, you 
                                        can designate every game development aspect to a specific team member, which will considerably simplify 
                                        the production stage. In this case, the math is simple as well — the more resources, the quicker and more 
                                        quality the result.                                       
                                    </li>
                                    <li>
                                        3.Tech stack. As one of the most time- and resource-dependent parts of game development, the set of 
                                        technologies you employ is going to either shorten or extend the time needed to build your game. 
                                        For instance, if you need to create a casual 2D mobile game, you may want to consider using Unity, 
                                        HTML5, Spine, Photoshop, or other tools that will optimize your workflow and time. That's where your 
                                        outsourcing partner steps in. Working with specialists like Game-Ace allows you to forget about the 
                                        technical part because our team will cherry-pick the best tech stack for your project based on its 
                                        characteristics.
                                    </li>
                                    <li>
                                        4.Genre & style. This point is as obvious as it seems. Imagine a 2D casual mobile game for Android and a 
                                        gaming metaverse — how much time will you need to develop each project? In the first case, you may need a 
                                        couple of months, whereas, for the latter, it's difficult to establish a clear deadline because you'll 
                                        have to take into account multiple features. Metaverse game development may take a year or far more, 
                                        depending on how much you want it to happen and the number of resources at your disposal.
                                    </li>
                                    <li>
                                        Team composition & seniority level. Although this point has already been touched upon when we talk about
                                         the resources, the team composition and its expertise are crucial factors that hugely affect the game 
                                         development time frame. If you, say, have a team of 2 programmers, 1 game designer, 2 artists, and 1
                                          project manager, you are more likely to finish your game project within months than if you, for example,
                                           lack the number of particular specialists or even don't have some of them.                       
                                    </li>
                                </ul>
                                <p>
                                    Most often, development time isn't constant. Conversely, if your team lacks experience, you should be ready 
                                    to move the deadline further until your project is polished. Nevertheless, given that you cooperate with a 
                                    game development outsourcing studio like Game-Ace, you won't need to worry about multiple distracting things, 
                                    inasmuch as we take care of all of them.                              
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    How much does it cost to develop a game
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                            <div className='gamefaq'>
                                <p>
                                The cost of a game development project varies based on multiple factors, such as its scale, scope, team composition and size, deadline, gameplay type, genre, etc. In order to estimate the budget for your future game, the best solution is to contact our specialists and discuss the project with them. As a result, you’ll acquire a crystal-clear vision of what and how to do.
                                   
                                </p>
                                <p>
                                Without further ado, let’s cross the i’s and dot the t’s on the example of mobile game development.
                                   
                                </p>
                                <ul className='gamefaq'>
                                    <li>
                                        1.Test prototypes. Demos and the like usually don’t require much time as well as budget to complete because these are far from complete products. Therefore, the price for their development starts from $10k.
                                    </li>
                                    <li>
                                    2.Casual games. Depending on the project scope and scale, as well as the type of gameplay, these apps usually cost $20k — $150k to develop.                                    </li>
                                    <li>
                                        3.Midcore games. If you want this kind of mobile game, be ready to spend about $150k — $500k. Everything will depend on other factors because the scale of such entertainment apps is already larger than in casual ones. Such products require more time and effort to make.

                                    </li>
                                    <li>
                                        4.Hardcore games. This term has nothing to do with the complexity level of your game. In this meaning, it describes the scope of work to do in order to develop it. Also referred to as AA games, they require $500k — $1m to develop, which is a huge deal in the mobile game development industry. Everything more complex and budget consuming belongs to the category of AAA games.                                    </li>
                                </ul>
                                <p>
                                    When it comes to games meant for other platforms, the price range may be even higher, depending on the type or genre. The same goes for gaming platforms. Everything should be taken into account because the pipeline is ramified, there can be various team compositions depending on your game’s scale, and you should mind many variables affecting the result. Hence, it’s highly recommended that you get in touch with us beforehand, and together we’ll calculate the costs.
                                </p>
                                <p>
                                    In any event, the total price can be discussed only throughout an interview, during which we analyze your game idea, plan all the processes along with resources, and build the game design document. In cases other than that, it’s almost impossible to provide a clear financial vision. Furthermore, you cannot plan anything with your game project until you calculate the costs because a carefully thought-out description of your further steps is already half of the work.                                </p>

                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    Will it be Secure from attacks?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    Yes, your data and files will be secured with the token based encryption process. 
                                   
                                </p>
                            }
                        </div>
                     </div>
                </div>
                <FooterForm field="Get started"/>
            </div>
        </>
    )
}

export default React.memo(Gaming);