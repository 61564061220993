import React from 'react';
import { Helmet } from 'react-helmet';
import {  useNavigate } from 'react-router-dom';

const About =() =>{
    const navigate = useNavigate();

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>About Us - Global Mclien Software Solutions</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/about" />
            </Helmet>
            <div className="hero_container homePage">
                <div className="hero_message">
                    <div className="hero_message_content">
                        <div >
                            <p className="hero_message_contentP">About Global Mclien Software Solutions Founder</p>
                            <h1 className="hero_message_h1">About Global Mclien Software Solutions</h1>
                            <p className="hero_message_p">
                                For over a decade we've partnered with technology leaders to rapidly 
                                scale their teams and meet their business challenges.Offering personalized 
                                consultation, web development, software development, and digital marketing services.
                            </p>
                            <div>
                                <button className="hero_message_button" onClick={()=>navigate('/contact' , {state:'Get A Quote'})}>Get In Touch</button>
                            </div>
                        </div>
                        <div><img src="/images/afeezbadmos.png" alt="Global mclien gear about us" /></div>
                    </div>
                </div>
            </div>
            <div className="about_element">
                <div className="about_elementpic">
                    <img src="/images/afeezbadmos.png" alt="Global mclien gear about us" />
                </div>
                <div  className="about_elementdiv"> 
                    <h4 className="about_elementhfour">Our Story</h4>
                    <p>
                        Global Mclien Software Solutions is an experienced USA based team of 
                        developers, architects, designers and strategists is what sets us apart 
                        from all the other development firms.
                    </p>
                    <p>
                        We believe effective solutions merge exceptional design, intuitive human 
                        interfaces, emerging technologies and best practices that deliver ROI.
                        When you're ready to partner with a software development firm to bring
                        your idea to life, it is an investment in your time and money. 
                        You can rest assured when you partner with Global Mclien Software Solutions, 
                        you'll experience
                        more than an amazing application or software platform. We will partner 
                        together to grow your business and increase your profits because of what
                        we bring to the table. We want to come together to grow your business, 
                        not just handoff flashy technology.
                    </p>
                    <p>
                        Plain and simple - we are honest, we over-deliver and we set out from the
                        beginning with the goal of becoming your long-term partners for technology 
                        and business strategy as your business grows and evolves.
                    </p>
                    <p>
                        Our USA based team of technologists specialize in mobile applications, software
                        development, cloud computing, UX design and legacy modernization, but that list
                        isn't comprehensive. In fact, we appreciate a good challenge. Let us know what 
                        type of technology creation you have in mind, and we will come to the table 
                        with ideas and solutions that blow you away.
                    </p>
                </div>
            </div>
            <div className="about_footer">
                <p>
                    I founded Global Mclien Software Solutions to do things differently. We focus on building business value
                    to achieve faster ROI.
                </p>
                <div className="about_picture">
                    <img src="/images/founder.jpg" className="about_founderimg" alt="Global mclien gear about us" />
                    <div className="about_picturediv">
                        <h5>Afeez Badmos</h5>
                        <h6>Founder Global Mclien</h6>
                    </div>
                </div>
            </div>
            <div className="staff_container">
                <h2>Meet Our Team</h2>
                <div className="staff_elementContainer">
                    <div className="aboutstaff_element">
                        <span className="aboutstaff_span">A</span>
                        <span className="aboutstaff_span">B</span>
                        <div className="overlay">
                            <h4>Afeez Badmos</h4>
                            <button type='button'>Founder</button>
                        </div>
                    </div>
                    <div className="aboutstaff_element">
                        <span className="aboutstaff_span">D</span>
                        <span className="aboutstaff_span">I</span>
                        <div className="overlay">
                            <h4>Dele Ismail</h4>
                            <button type='button'>Senior Dev</button>
                        </div>
                    </div>
                    <div className="aboutstaff_element">
                        <span className="aboutstaff_span">A</span>
                        <span className="aboutstaff_span">B</span>
                        <div className="overlay">
                            <h4>Adam Badmos</h4>
                            <button type='button'>UI | UX</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(About);