import React , {useState} from 'react';
import { AiOutlineClose} from "react-icons/ai";
import '../App.css';
import {Link , useNavigate} from 'react-router-dom';
import { BsTelephone} from "react-icons/bs";
import {FcMenu} from "react-icons/fc";


const Header =({personnel}: any) =>{
    const [showMobileMenu , setShowMobileMenu] = useState(false);
    const [showMobileMenuList , setShowMobileMenuList] = useState(0);
    const navigate = useNavigate();

    const goToPage =(field: any)=>{
        setShowMobileMenu(false);
        navigate(field);
    }
    return(
        <div className="topbar-container">
            <div className="topbar-content">
                <Link to="/">
                    <img className="topbar-logo" src="/images/nobglogo.png" alt="Global Mclien Software Solutions logo"  />
                </Link>
            </div>
            <div className="nav_list">
                <ul className="nav_list_ul">
                    <li>
                        <Link to="/software">
                            Software | Websites
                        </Link>
                        <span className='navlist_span'>&#x25BC;</span>
                        <div className="nav_hover">
                            <ul className="nav_hoverul">
                                <li>
                                    <Link to="/software">
                                        Software Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/customwebsites">
                                        Web Application
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cloud">
                                        AI | Cloud Engineering
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/data">
                                        Python | Data Enginnering
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/websites">
                                        Websites
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/gaming">
                                        Game Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/customwebsites">
                                        Custom Codes
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/mobileapp">
                            Mobile Apps'
                        </Link>
                        <span>&#x25BC;</span>
                        <div className="nav_hover">
                            <ul className="nav_hoverul">
                                <li>
                                    <Link to="/iosapp">
                                        iOS Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/androidapp">
                                        Android Application
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/xamarinapp">
                                        Xamarin
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/javapp">
                                        JAVA Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/hybridapp">
                                        Hybrid development
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/digitalmarketing">
                            Digital Marketing
                        </Link>
                        <span>&#x25BC;</span>
                        <div className="nav_hover">
                            <ul className="nav_hoverul">
                                <li>
                                    <Link to="/seo">
                                        SEO
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/googleseo">
                                        Google Ads
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/facebookads">
                                        Facebook Ads
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog">
                                        Blogs
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/backlinks">
                                        Backlinks
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/localrankings">
                                        Local Rankings
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/blockchain">
                            Blockchain
                        </Link>
                        <span>&#x25BC;</span>
                        <div className="nav_hover">
                            <ul className="nav_hoverul">
                                <li>
                                    <Link to="/ethereum">
                                        Ethereum
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/custodialwallet">
                                        Custodial Wallets
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/smartcontracts">
                                        Smart Contracts
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/dao">
                                        DAO
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/defi">
                                        DEFi
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/packages">
                            Packages
                        </Link>
                        <span>&#x25BC;</span>
                        <div className="nav_hover">
                            <ul className="nav_hoverul">
                                <li>
                                    <Link to="/softwarepackages">
                                        Software Development Packages
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mobileapppackages">
                                        Mobile App Development Packages
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/websitespackages">
                                       Website Development Packages
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/digitalmarketingpackages">
                                        Digital Marketing Packages
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blockchainpackages">
                                        Blockchain | Solidity Packages
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/blog">
                            Blogs
                        </Link>
                        <span>&#x25BC;</span>
                        <div className="nav_hover">
                            <ul className="nav_hoverul">
                                <li>
                                    <Link to="/mobileappblog">
                                        Mobile App Blogs
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/softwareblog">
                                        Software Blogs
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/websiteblog">
                                       Web App Blogs
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/digitalmarketingblog">
                                        Digital Marketing Blogs
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blockchainblog">
                                        Blockchain | Solidity Blogs
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='topbar-unloggedin'>
                <div className='topbar-unloggedinphone'>
                     <BsTelephone className='topbar-tel' />
                    <a title='click to call' className='topbar-tel' href="tel:+17472498760">747.249.8760</a>
                </div>
                <div className="menubar_container" >
                    <div className="menubar">
                        {showMobileMenu ? 
                            <AiOutlineClose className="menubar_close" onClick={()=> setShowMobileMenu(!showMobileMenu)}/>
                            :
                            <FcMenu onClick={()=> setShowMobileMenu(!showMobileMenu)}/>
                        }
                    </div>
                </div>
            </div>
            {showMobileMenu && 
                <div className="mobile_menu">
                    <ul className="mobile_menuul">
                        <li className="mobile_menuli">
                            {showMobileMenuList === 1 ?
                                <button type='button' onClick={()=>setShowMobileMenuList(0)}>Mobile Applications &#x25B2;</button>:
                                <button type='button' onClick={()=>setShowMobileMenuList(1)}>Mobile Applications &#x25BC;</button>
                            }
                           {showMobileMenuList === 1 && 
                                <ul className='mobilemenusublist'>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/mobileapp")}>Mobile Applications</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/iosapp")}>iOS Development</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/androidapp")}>Android Application</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/xamarinapp")}>Xamarin</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/javapp")}>JAVA Development</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/hybridapp")}>Hybrid development</button>
                                    </li>
                                </ul>
                           } 
                        </li>
                        <li className="mobile_menuli">
                            {showMobileMenuList === 2 ?
                                <button type='button' onClick={()=>setShowMobileMenuList(0)}> Software | Websites &#x25B2;</button>:
                                <button type='button' onClick={()=>setShowMobileMenuList(2)}> Software | Websites &#x25BC;</button>
                            }
                           {showMobileMenuList === 2 && 
                                <ul className='mobilemenusublist'>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/software")}>Software Development</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/customwebsites")}>Web Application</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/cloud")}>AI | Cloud Engineering</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/data")}>Python | Data Enginnering</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/websites")}>Websites</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/gaming")}>Game Development</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/customwebsites")}>Custom Codes</button>
                                    </li>
                                </ul>
                           } 
                        </li>
                        <li className="mobile_menuli">
                            {showMobileMenuList === 3 ?
                                <button type='button' onClick={()=>setShowMobileMenuList(0)}>Digital Marketing &#x25B2;</button>:
                                <button type='button' onClick={()=>setShowMobileMenuList(3)}>Digital Marketing &#x25BC;</button>
                            }
                           {showMobileMenuList === 3 && 
                                <ul className='mobilemenusublist'>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/digitalmarketing")}>Digital Marketing</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/seo")}>SEO</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/googleseo")}>Google Ads</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/facebookads")}>Facebook Ads</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/blog")}>Blogs</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/backlinks")}>Backlinks</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/localrankings")}>Local Rankings</button>
                                    </li>
                                </ul>
                           } 
                        </li>
                        <li className="mobile_menuli">
                            {showMobileMenuList === 4 ?
                                <button type='button' onClick={()=>setShowMobileMenuList(0)}>Blockchain &#x25B2;</button>:
                                <button type='button' onClick={()=>setShowMobileMenuList(4)}>Blockchain &#x25BC;</button>
                            }
                           {showMobileMenuList === 4 && 
                                <ul className='mobilemenusublist'>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/blockchain")}>Blockchain</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/ethereum")}>Ethereum</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/custodialwallet")}>Custodial Wallets</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/smartcontracts")}>Smart Contracts</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/dao")}>DAO</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/defi")}>DEFi</button>
                                    </li>
                                </ul>
                           } 
                        </li>
                        <li className="mobile_menuli">
                            {showMobileMenuList === 5 ?
                                <button type='button' onClick={()=>setShowMobileMenuList(0)}> Packages &#x25B2;</button>:
                                <button type='button' onClick={()=>setShowMobileMenuList(5)}> Packages &#x25BC;</button>
                            }
                           {showMobileMenuList === 5 && 
                                <ul className='mobilemenusublist'>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/packages")}>Packages</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/softwarepackages")}>Software Development Packages</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/mobileapppackages")}>Mobile App Development Packages</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/websitespackages")}>Website Development Packages</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/digitalmarketingpackages")}>Digital Marketing Packages</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/blockchainpackages")}>Blockchain | Solidity Packages</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/gaming")}>Game Development</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/customwebsites")}>Custom Codes</button>
                                    </li>
                                </ul>
                           } 
                        </li>
                        <li className="mobile_menuli">
                            {showMobileMenuList === 6 ?
                                <button type='button' onClick={()=>setShowMobileMenuList(0)}> Blogs &#x25B2;</button>:
                                <button type='button' onClick={()=>setShowMobileMenuList(6)}> Blogs &#x25BC;</button>
                            }
                           {showMobileMenuList === 6 && 
                                <ul className='mobilemenusublist'>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/blog")}>Blogs</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/mobileappblog")}>Mobile App Blogs</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/softwareblog")}>Software Blogs</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/websiteblog")}> Web App Blogs</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/digitalmarketingblog")}>Digital Marketing Blogs</button>
                                    </li>
                                    <li>
                                        <button type='button' onClick={()=>goToPage("/blockchainblog")}>Blockchain | Solidity Blogs</button>
                                    </li>
                                </ul>
                           } 
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}

export default React.memo(Header);