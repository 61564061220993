import React from 'react';
import { Helmet } from 'react-helmet';
import { AiFillWechat } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import {Link, useNavigate} from 'react-router-dom';


const BlockChainPackages =() =>{
    const navigate = useNavigate();

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='Explore our comprehensive blockchain packages designed to elevate your digital presence 
                    and streamline operations. From secure smart contracts to decentralized applications, our 
                    tailored solutions empower businesses to harness the full potential of blockchain technology. 
                    Unlock efficiency, transparency, and scalability with our expertly crafted packages. 
                    Discover how our blockchain solutions can revolutionize your industry. Get started today!'
                    />
                <title>Blockchain | Etherum | Solidity | Smart Contract Packages - Global Mclien Software Solutions</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/blockchainpackages" />
            </Helmet>
            <div className="hero_container homePage">
                <div className="hero_message">
                    <div className="hero_message_content">
                        <div >
                            <p className="hero_message_contentP">Take Control, Choose one / more package OR customise your package</p>
                            <h1 className="hero_message_h1">Blockchain Package List</h1>
                            <p className="hero_message_p">
                                Find the package that suits your needs and the scale of your business at an affordable and transparent cost.
                                Each package is customised and fully scalable to provide a combination of services that work effectively 
                                together for your business scalability.
                            </p>
                            <div className="hero_packagebutton">
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Web Design'})}>Web Design</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'eCommerce Packages'})}>eCommerce Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Shopify Packages'})}>Shopify Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'SEO Packages'})}>SEO Packages</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Digital Marketing'})}>Digital Marketing</button>
                                </div>
                                <div>
                                    <button className="hero_message_button" onClick={()=>navigate('/pricing' , {state:'Web Maintainance'})}>Web Maintainance</button>
                                </div>
                            </div>
                        </div>
                        <div className="heropackage_img"><img src="/images/package.png" alt="Global mclien package" /></div>
                    </div>
                </div>
            </div>
            <div className="packagesContainer">
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>2 Revisions</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Starter <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$5,000</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Basic blockchain consultation</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a simple decentralized application (DApp)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Smart contract development for one use case</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Deployment on a test network</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Documentation and basic support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Basic Package',
                                        price:'1250'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>5 Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Standard <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$7,000</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Comprehensive blockchain consultation and strategy</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a customizable DApp with enhanced features</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                    <p className="packagesContentcheck">&#10004;</p>
                                        <p>Smart contract development for multiple use cases</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Deployment on a live network</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Basic security audit</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Documentation, training, and ongoing support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Standard Package',
                                        price:'1750'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>10 Revisions</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Enterprise <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$10,000</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Advanced blockchain consultation and strategy, including scalability and interoperability solutions</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Custom DApp development with enterprise-grade features </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Smart contract development for complex business logic</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Deployment on multiple networks for maximum reach</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Thorough security audit and penetration testing</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Continuous monitoring, maintenance, and support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Unique Design Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'Elite Package',
                                            price:'2250'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="packagesContainer lower">
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br /> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Tokenization <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$12,250</h3>
                            <p className="packagesElement_description">
                                Suitable for potential super-startups and brand revamps for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Consultation on tokenization strategies and regulatory compliance</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a token issuance platform</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Smart contract development for token creation and management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integration with leading blockchain networks and wallets</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Legal review and compliance support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Marketing and launch support for the token sale</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Satisfaction Guarantee</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Free 1yr Technical Support</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'eCommerce Package',
                                            price:'4250'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br /> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Supply Chain <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$14,650</h3>
                            <p className="packagesElement_description">
                                Suitable for B2B & Established business with Scalable business concepts.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Consultation on blockchain implementation in supply chain management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a supply chain tracking system using blockchain technology</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Smart contract development for transparent and secure transactions</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Integration with IoT devices for real-time data monitoring</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Supplier onboarding and training</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Ongoing maintenance and support for the supply chain network</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Chat bot and chat widgets</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Complete Deployment</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Money Back Guarantee *</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                        to="/package_details" state={{
                                            packageName: 'Web Application Package',
                                            price:'8750'
                                        }}
                                    >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="packageOuterContent">
                    <div className="buttonTopContainer">
                        <div className="packageButton">
                            <p>Unlimited <br/> Reviews</p>
                        </div>
                        <div className="triangleTwo"></div>
                    </div>
                    <div className="packagesElement">
                        <div className="packagesElement_header">
                            <p className="packagesElement_p">Finance <span className='container_span'>Package</span></p>
                            <h3 className="packagesElement_hthree">$Custom Pricing</h3>
                            <p className="packagesElement_description">
                                Suitable for potential Social Media contents startegy for 
                                companies.
                            </p>
                        </div>
                        <div className="packagesul_container">
                            <ul className="packagesElement_ul">
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Consultation on blockchain applications in the finance sector, including DeFi (Decentralized Finance)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Development of a decentralized exchange (DEX) or lending platform</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Smart contract development for financial products like loans or automated trading</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p >Integration with fiat gateways and external APIs</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Compliance with financial regulations and security standards</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Marketing and promotion for the finance platform</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Online Integration</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Admin Profile Management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>User Profile Management</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>Analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="packagesContent">
                                        <p className="packagesContentcheck">&#10004;</p>
                                        <p>100% Ownership Rights</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="packagesContent livechat">
                                <div className="packagesContent">
                                    <AiFillWechat />
                                    <p> Live Chat</p>
                                </div>
                                <div>
                                    <BsTelephone  />
                                    <a href="tel:+7472498760">7472498760</a>
                                </div>
                            </div>
                            <a className="packagesContent_button" href="https://buy.stripe.com/8wM3feb7n6Fl0W4145">
                                Order Now
                            </a>
                            <div className="detail_container">
                                <Link className="packages_details" 
                                    to="/package_details" state={{
                                        packageName: 'Social Media Package',
                                        price:'16599'
                                    }}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(BlockChainPackages);