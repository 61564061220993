import React , {useState} from 'react';
import { GrReactjs ,GrHtml5 } from "react-icons/gr";
import { IoLogoJavascript , IoLogoPython ,IoLogoNodejs } from "react-icons/io";
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {FaChalkboardTeacher , FaNode , FaAws ,FaGit,FaPhp ,  FaVuejs} from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { TbBrandReactNative , TbBrandNextjs} from "react-icons/tb";
import { BsTelephone , BsWordpress} from "react-icons/bs";
import { VscAzure } from "react-icons/vsc";
import {  AiOutlineGithub ,AiOutlineAndroid , AiOutlineApple} from "react-icons/ai";
import { SiSqlite, SiMysql , SiPostgresql ,SiFlutter ,SiDjango ,SiMongodb ,SiAngularjs} from "react-icons/si";
import { Helmet } from 'react-helmet';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import Reviews from './Reviews';
import FooterForm from './Footer/FooterForm';
import { Link } from 'react-router-dom';

const CustomWebsites =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='custom code, scalability , software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content="Transform your ideas into reality with our top-notch website application development services.
                     At Global Mclien Software Solutions, we specialize in crafting bespoke web solutions that drive results. From intuitive user interfaces to robust backend systems, we've got you covered. Partner with us to create exceptional digital experiences for your audience. Contact us now to get started!"
                    />
                <title>Global Mclien Software Solutions - Custom Web Application development | Website Design</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/customwebsites" />
            </Helmet>
            <div className='component_container'>
                <div className="hero_container websitePage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                                <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Empowering Your Digital Presence with Custom Web Solutions</h1>
                                <ul className="hero_message_ul">
                                    <li>Web application development</li>
                                    <li>Bespoke web applications</li>
                                    <li>Enterprise applications</li>
                                    <li>Reactjs | VUE frameworks & Libraries</li>
                                    <li>Custom | Legacy Code base</li>
                                </ul>
                                <p className="hero_message_p">
                                    At <Link className="link-builder" to="/" >GMSS, </Link>we specialize in crafting bespoke web applications that propel
                                    businesses to new heights. Our team of experienced 
                                    <Link className="link-builder" to="/about"> developers </Link>combines 
                                    cutting-edge technologies with creative solutions to deliver exceptional 
                                    <Link className="link-builder" to="/digitalmarketing" > digital experiences </Link>tailored to your unique needs
                                </p>
                                <div>
                                  <a className="hero_message_button" href="#content_form">Get Started</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/html.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Website Consultation</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whatwedo_container">
                    <div className="whatwedo_containerdiv">
                        <h3>A California Website Application development agency</h3>
                        <p>Impress Your Customers With engaging and high-converting <Link className="link-builder" to="/websites" >Web Design</Link></p>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/seoservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>SEARCH ENGINE OPTIMIZATION - SEO</h4>
                                <p>
                                    Your website is useless if people can't find it. Our California web development
                                    experts work with our team of <Link className="link-builder" to="/seo" >SEO </Link>professionals to ensure your website works
                                    well with search engines. They start with comprehensive site audits and 
                                    competitive keyword analysis to create a customized strategy for your 
                                    <Link className="link-builder" to="/digitalmarketing" >digital marketing campaign. </Link> 
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/webservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>WEB DESIGN AND DEVELOPMENT</h4>
                                <p>
                                    Your website represents your business and brand.<Link className="link-builder" to="/about"> Our California web designers </Link> 
                                    develop a strong visual and interactive representation of your brand that
                                    aligns with your <Link className="link-builder" to="/digitalmarketing" >marketing strategy. </Link>Our approach is to blend innovative
                                    design concepts with your brand messaging to impress your customers and
                                    attract new leads.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/contentservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>CONTENT WRITING</h4>
                                <p>
                                    Every campaign - whether <Link className="link-builder" to="/seo" >SEO, PPC, or social media marketing </Link>- requires
                                    high-quality content.
                                    <Link className="link-builder" to="/">Global Mclien Software Solutons's </Link> California web app development 
                                    agency has a dedicated 
                                    team of content writers and professional editors. We produce superior, 
                                    optimized content that engages your target audience and speaks to your 
                                    strongest value proposition.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/shopifyservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>SHOPIFY WEB DESIGN</h4>
                                <p>
                                    Gain more leverage with your online business by employing a custom Shopify
                                    theme that easily converts. <Link className="link-builder" to="/about"> Our California web design </Link>experts create a
                                    data-driven design strategy to meet your eCommerce requirements and 
                                    goals. Through our technical expertise <Link className="link-builder" to="/digitalmarketing" >digital marketing </Link>experience, 
                                    we create responsive websites that offer your customers a seamless 
                                    shopping experience.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/responsiveservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>RESPONSIVE WEB DESIGN</h4>
                                <p>
                                    Improve your conversions with an interactive and responsive website. <Link className="link-builder" to="/menifeedevelopers"> Our web dev team in California </Link>
                                     ensures your business site performs well across all 
                                    devices and web browsers. We can guarantee a seamless and mobile-responsive
                                    website so you can maximize your lead generation strategies.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/ecommerceservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>ECOMMERCE WEB DESIGN</h4>
                                <p>
                                <Link className="link-builder" to="/">Global Mclien Software Solution's </Link>California web development services include eCommerce website 
                                    optimization. Work with our team of <Link className="link-builder" to="/digitalmarketing" >digital marketing experts </Link>who 
                                    optimize your product listings and brand pages. Our team can guarantee 
                                    your digital storefront is easily found by your ideal customer and your 
                                    products are perfectly displayed.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/hostingservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>WEBSITE HOSTING</h4>
                                <p>
                                <Link className="link-builder" to="/">Global Mclien Software Solution </Link>provides fast and secure 
                                website hosting solutions. We use 
                                     the most reliable of web hosts - to ensure our clients 
                                    can easily and independently manage their business site. However, our 
                                    team also offers 24/7 tech support, site maintenance, performance 
                                    tracking, and <Link className="link-builder" to="/data" >data backup </Link>when you need it the most.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/wordpressservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>WORDPRESS EXPERTS</h4>
                                <p>
                                    Consult our <Link className="link-builder" to="/websites" >WordPress experts </Link>to consistently 
                                    enhance your website. Our 
                                    <Link className="link-builder" to="/about">Our California web development team </Link>is at-the-ready to provide assistance 
                                    whenever you need them. To start, the team conducts an in-depth site 
                                    analysis to diagnose any backend errors and fix them. Expect us to 
                                    actively implement changes or small tweaks for better site health or 
                                    optimization.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/customdeve.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>CUSTOM WEB DESIGN</h4>
                                <p>
                                    We understand your unique business needs a custom website. At <Link className="link-builder" to="/">Global Mclien Software Solution, </Link> 
                                    we don't use templated layouts and designs. Our  <Link className="link-builder" to="/about">California web designers </Link> 
                                    take the time to assess your goals and tailor a design strategy that 
                                    serves your business needs. Our web dev team provides you with 
                                    high-quality wireframes or page schematics for approval so we can build
                                     you the best site for your brand.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>we create your online presence with a <span className="container_span">User Friendly Website.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/presentation.png" alt="presentation"  />
                            <h5>Website Performance Report</h5>
                            <p>
                                Our <Link className="link-builder" to="/about">California web dev team </Link>doesn't
                                 settle for one-size-fits-all
                                solutions. Our methods are founded on real-time data and 
                                comprehensive analysis. We can create a tailored, data-driven 
                                strategy to successfully meet your sales target and business
                                goals. What's more, our team consistently monitors your website
                                and other <Link className="link-builder" to="/digitalmarketing" >digital marketing </Link>campaigns to maintain their 
                                performance.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/delivery.png" alt="marketing"  />
                            <h5>Prompt Delivery</h5>
                            <p>
                                As your partner, we understand how delays can take a toll on 
                                your business operations. Our web developers assess the scope 
                                of your projects, so we can map out an accurate timeline and
                                ensure your web design and goals are met timeously. 
                                At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>
                                our team is always ready to
                                communicate any progress concerning your website projects.
                        </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/customer.png" alt="presentation"  />
                            <h5>Customer-Centric</h5>
                            <p>
                            <Link className="link-builder" to="/">Global Mclien Software Solutions </Link>has built its trusted reputation by 
                                conducting business the right way - and that is to treat our clients as partners 
                                 in success. <Link className="link-builder" to="/usadevelopers" >Our California web design </Link>company ensures everyone is
                                  on board with every project we handle. We respect your goals 
                                  and company vision, and we remain committed to delivering you 
                                  the desired results for your business. Partner with 
                                  <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link> 
                                  and you'll gain an unmatched competitive advantage.
                            </p>
                        </div>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/resultgoal.jpg" alt="branding" />
                            <h5>Target &amp; Transparency</h5>
                            <p>
                            <Link className="link-builder" to="/">Global Mclien Software Solutions </Link>is built on long-term partnerships,
                                which means that we want to earn your trust and keep it. We do 
                                this through our commitment to absolute transparency about us, 
                                our <Link className="link-builder" to="/software" >software processes, our software projects,  </Link>and your project.
                                While the frequency of software updates will be at your 
                                discretion, we will always keep you up to date on the status of 
                                your project, budget, risks, timelines, and overall target.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/teamwork.jpg" alt="marketing"  />
                            <h5>24/7 Dev and support Team</h5>
                            <p>
                                Unlike other California web app dev companies, the round-the-clock 
                                <Link className="link-builder" to="/">development team at 
                                Global Mclien Software Solutions </Link>doesn't simply settle for initial results. We 
                                constantly look for more opportunities to grow your 
                                business. Our proactive <Link className="link-builder" to="/digitalmarketing" >digital marketing team </Link>strives 
                                to reach beyond your expectations by consistently improving
                                 your campaigns with data-driven strategies. At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link> 
                                 we strongly believe that your success is our success, as well!
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/moneyback.png" alt="branding" />
                            <h5>Money Back Guaranteed</h5>
                            <p>
                                We ensure a premium quality <Link className="link-builder" to="/websites" >web development process </Link>from 
                                our professional <Link className="link-builder" to="/websites" >web developers </Link>that we guarantee a 30-day money 
                                back if you are not satisfied and we delay your <Link className="link-builder" to="/websites" >website </Link>due to our 
                                own shortcomings.
                            </p>
                        </div>
                    </div>
                    <div className="other_maincontainer">
                        <div className="other_subfeatures">
                            <div className="right_items">
                                <h2>
                                    Our <span className='softwareheader_span'> Process</span>
                                </h2>
                                <div className="rightitem_content">
                                    <img src="/images/ourprocess.jpg" alt="process" />
                                    <p>
                                        The First point of call for over 75% of customers that want to experience an
                                        effortless shopping with safety and 
                                        satisfaction, will make or plan to make such transactions  on the internet . It is imperative
                                        that not only your <Link className="link-builder" to="/websites" >web </Link> be secure, it has got to be appealing to Customers online.
                                        Now , every Customer is unique , then how can you guarantee satisfaction to these diverse
                                        demographics of users crawling through your <Link className="link-builder" to="/websites" >web </Link>.
                                    </p>
                                </div>
                            </div>
                            <div className="right_items">
                                <h2>Development Process </h2>
                                <div className="rightitem_content">
                                <p>
                                    From a <Link className="link-builder" to="/websites" >Landing page to a fully functional dynamic Web application, </Link>we always make 
                                    sure that it's developed with full accessibility , responsiveness , security and scalability.
                                    You want to Improve your website ? and skeptical about the downtime , we take the worry 
                                    from your hands and simplify the entire scalability , responsiveness that best fit your website
                                    to increase the clients satisfaction level with little Or NO Website Downtime.
                                    Get that peace of mind when you know your Website performance is at the level your allocated 
                                    recources supports . The supports measured in terms of customer acquisitions , enhanced user experiences
                                    accross multiple platforms and having a happy repeat customer followed with good recommendations.
                                </p>
                                    <img src="/images/performance2.png" alt="performance" />
                                </div>
                            </div>
                            <div className="right_items">
                                <h2>
                                    <span className='softwareheader_span'>Optimisation </span> Process...
                                </h2>
                                <div className="rightitem_content">
                                <img src="/images/optimisationnew.png" alt="performance" /> 
                                <p>
                                    We will get the initial performance of your website in its current state , enhance the media items and 
                                    code syntax of the page with most visits and make a technical evaluation.
                                    For Web Apps , the situation might require a migration of <Link className="link-builder" to="/data" >database, </Link>changing Api call point depending 
                                    on the initial technical report from your Web Application.
                                </p>
                            </div>
                            </div>
                            <div className="right_items">
                                <h2>Next ...</h2>
                                <div className="rightitem_content">
                                <p>
                                    Media does affect Site performance a lot , so all img tags will be completely optimised and make sure they 
                                    still retain their picture perfect pixelate levels. Followed by Headings  and titles with broken links.
                                    For Web Applications , a simple change from an Array list to a Linked list might significantly Improve the 
                                    performance overall.
                                </p>
                                    <img src="/images/next.png" alt="performance" />
                                </div>
                            </div>
                            <div className="right_items">
                                <h2>
                                    Effects &amp; <span className='softwareheader_span'> Results </span>
                                </h2>
                                <div className="rightitem_content">
                                    <img src="/images/resultimg.png" alt="performance" />
                                    <p>
                                        The effects are noticed on increased loading times , improved responsiveness and a fully scalable Website or Appllication as your
                                        project entails which will make your customers spend more quality time on your website. The more time the Client spend
                                        on your site due to this improved efficiency , the more inflow of business and increased profit.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="other_subfeatures">
                            <div className="subfeatures_title">
                                <h2 >Technology Stack</h2>
                            </div>
                            <div className="techstack_images">
                                <div>
                                    <div className="tech_header">
                                        <p>Web Development</p>
                                    </div>
                                    <div className="techstackContainer">
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <GrHtml5 />
                                            </div>
                                            <p>HTML</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <IoLogoJavascript />
                                            </div>
                                            <p>Javascript</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <IoLogoNodejs />
                                            </div>
                                            <p>CSS3</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <BsWordpress />
                                            </div>
                                            <p>WordPress</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tech_header">
                                        <p>Front End</p>
                                    </div>
                                    <div className="techstackContainer">
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <SiAngularjs />
                                            </div>
                                            <p>AngularJs</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <GrReactjs />
                                            </div>
                                            <p>ReactJs</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <FaVuejs />
                                            </div>
                                            <p>VueJs</p>
                                        </div>
                                   
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <TbBrandNextjs />
                                            </div>
                                            <p>NextJs</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tech_header">
                                        <p>Back End</p>
                                    </div>
                                    <div className="techstackContainer">
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                            <FaNode/>
                                            </div>
                                            <p>NodeJs</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                            <FaPhp/>
                                            </div>
                                            <p>PHP</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <IoLogoPython />
                                            </div>
                                            <p>Python</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <SiDjango />
                                            </div>
                                            <p>Django</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tech_header">
                                        <p>Database</p>
                                    </div>
                                    <div className="techstackContainer">
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <SiPostgresql/>
                                            </div>
                                            <p>PostgressSQL</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <SiMysql />
                                            </div>
                                            <p>MySQL</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <SiSqlite />
                                            </div>
                                            <p>SQLlite</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <SiMongodb />
                                            </div>
                                            <p>MongoDb</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tech_header">
                                        <p>Mobile App Stacks</p>
                                    </div>
                                    <div className="techstackContainer">
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <TbBrandReactNative />
                                            </div>
                                            <p>React Native</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <SiFlutter />
                                            </div>
                                            <p>Flutter</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <AiOutlineAndroid />
                                            </div>
                                            <p>Android</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <AiOutlineApple />
                                            </div>
                                            <p>iOS</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tech_header">
                                        <p>Cloud Technology</p>
                                    </div>
                                    <div className="techstackContainer">
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <AiOutlineGithub />
                                            </div>
                                            <p>Github</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <FaAws />
                                            </div>
                                            <p>AWS</p>
                                        </div>
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <FaGit />
                                            </div>
                                            <p>Git</p>
                                        </div>
                                   
                                        <div className="techstack_divcontainer">
                                            <div className="techstack_div">
                                                <VscAzure />
                                            </div>
                                            <p>Azure</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reviewsheader_container">
                    <div className="main_reviewsheader">
                        <div>
                            <h4>Our Reviews</h4>
                            <p>& Clients Feedbacks</p>
                        </div>
                        <Link to="/all-reviews" >See all Reviews</Link>
                    </div>
                    <Reviews />
                </div>
                <div className="whychoose_us">
                    <div>
                        <h2>Why Choose US?</h2>
                    </div>
                    <div className='paracontent-conainer'>
                        <p>
                            <span className='bold-text'>1. Expertise: </span>
                            With years of experience in the industry, our team possesses the expertise to tackle any web 
                            development challenge. From simple websites to complex enterprise applications, we have the
                             skills to bring your vision to life.
                        </p>
                        <p>
                            <span className='bold-text'>2.Custom Solutions: </span>
                            We understand that every business is different. That's why we take a personalized approach to
                             every project, ensuring that your web application is tailored to your specific requirements 
                             and goals.
                        </p>
                        <p>
                            <span className='bold-text'>3.Cutting-Edge Technologies: </span>
                            We stay ahead of the curve by leveraging the latest technologies and frameworks in web 
                            development. Whether it's React, Angular, Vue.js, or Node.js, we have the tools to build 
                            powerful and scalable web applications.
                        </p>
                        <p>
                            <span className='bold-text'>4.Transparent Communication: </span>
                            Communication is key to a successful project. We believe in transparency and regular updates,
                             keeping you informed every step of the way and ensuring that your project stays on track and 
                             within budget.
                        </p>
                        <p>
                            <span className='bold-text'>5.Exceptional Support: </span>
                            Our relationship with our clients doesn't end when the project is complete. We provide ongoing 
                            support and maintenance to ensure that your web application continues to perform optimally 
                            and evolves with your business needs.
                        </p>
                    </div>
                </div>
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    What is the First step to develop my Custom website application?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    Every website project is a bit complicated and different, so the very first step that 
                                    mark the start of any project is creating a comprehensive plan that will show 
                                    designs, number of pages, features and contents, technology stacks 
                                    to be used, user requirements.
                                    The Plan and agreement will be sent to you before taking the next step.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                I'm not sure if my idea is feasible for a web application.
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    Our team of experts will assess your idea and provide guidance on the feasibility of 
                                    turning it into a web application. With our experience and expertise, we can help you 
                                    explore the possibilities and find the best solution for your needs.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                I've had a bad experience with web development agencies in the past.
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    We understand your concerns and are committed to providing a positive experience for 
                                    all our clients. Our team prioritizes clear communication, transparency, and delivering
                                     exceptional results. Let us show you why we're different.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                I'm worried about the cost of developing a web application.
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    We offer flexible pricing options to accommodate a wide range of budgets. Our goal is to
                                     provide cost-effective solutions without compromising on quality. Let's discuss your 
                                     requirements and find a solution that fits your budget.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                I'm not tech-savvy and don't understand the web development process.
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                   You don't need to be a technical expert to work with us. Our team will guide you through 
                                   the entire web development process, explaining each step in simple terms and keeping you 
                                   informed along the way. We're here to make the process as smooth and hassle-free as
                                    possible for you.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                I'm concerned about the security and reliability of my web application.
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <p>
                                    Security and reliability are our top priorities. We follow industry best practices and 
                                    implement robust security measures to protect your web application from potential 
                                    threats. Additionally, we provide ongoing support and maintenance to ensure that 
                                    your application remains secure and performs optimally at all times.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                    Can I add new Features to my website?
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                    Custom Website development typically includes ongoing support and 
                                    maintenance services from our development team. This ensures prompt
                                    assistance in case of adding new features, new updates, or enhancements. 
                                    The development team has in-depth knowledge of the software's 
                                    architecture, making it easier to address update and changes.
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <FooterForm field="Get started"/>
            </div>
        </>
    )
}

export default React.memo(CustomWebsites);