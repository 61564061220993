import React ,{useState} from 'react';
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {FaChalkboardTeacher } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import Reviews from './Reviews';
import { Helmet } from 'react-helmet';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import FooterForm from './Footer/FooterForm';
import { Link } from 'react-router-dom';

const CloudEngineering =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='cloud management, optimisation , aws cloud, azure cloud, deployments ,cloud platform, cloud solutions, cloud managed services'/>
                <meta name='description' 
                    content="Unlock the power of the cloud with Global Mclien Software Solutions. 
                    Our expert team specializes in crafting innovative cloud applications tailored to your needs. From scalable infrastructure to seamless integration, we'll help your business thrive in the digital age. Discover how our cloud solutions can elevate your operations and drive growth. Contact us today to embark on your cloud journey!"
                    />
                <title>AI Powered Cloud Data Management - Driving Digital Transformation</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/cloud" />
            </Helmet>
            <div className='component_container' >
                <div className="hero_container datapage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">AI Powered  & Cloud Management Services</h1>
                                <ul className="hero_message_ul">
                                    <li>Hybrid Technologies</li>
                                    <li>Cloud Data Management</li>
                                    <li>Data | Cloud Protection</li>
                                    <li>Data Recovery</li>
                                    <li>Data Backup</li>
                                </ul>
                                <p className="hero_message_p">
                                    At <Link to="/" className="link-builder"> Global Mclien Software Solutions, </Link>
                                    we'll help you stabilize,  optimize and manage your
                                    cloud IT infrastructure for future growth, scalability, and transformation.
                                </p>
                                <div>
                                  <a className="hero_message_button" href="#content_form">Get Started</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/datanobg.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free <a href="/websites" className="backlinks"> Website Consultation</a></p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <a href="/home" className="backlinks"> Global Mclien Software Solutions, </a> we target important <span className="container_span">tasks for automation.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/presentation.png" alt="presentation"  />
                            <h5>Governance</h5>
                            <p>
                            Continuous compliance to secure baselines allows our customers to free themselves of necessary, 
                            rote work so they can focus on differentiating their business in a competitive market.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/delivery.png" alt="marketing"  />
                            <h5>Cost Optimisation</h5>
                            <p>
                            On-going cost optimization unburdens customer technical and financial teams, releasing them to innovation and creative work.
                        </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/customer.png" alt="presentation"  />
                            <h5>AWS Experts</h5>
                            <p>
                            Our team of cloud experts are available 24x7 to resolve issues and provide prescriptive guidance on operating model evolution
                            </p>
                        </div>
                    </div>
                 </div>
                <div className="cloud-objections">
                    <div className="cloudobjections-sideone">
                        <h3>
                            <a href="/home" className="backlinks">GLOBAL McLIEN SOFTWARE SOLUTIONS </a> MANAGED CLOUD SERVICES IS EXCEEDING OUR CUSTOMERS' EXPECTATIONS BY:
                        </h3>
                    </div>
                    <div className="cloudobjections-sidetwo">
                        <div><span>&#9654;</span><span>Reducing security and compliance risk</span></div>
                        <div><span>&#9654;</span><span>Educating and enabling customer IT teams</span></div>
                        <div><span>&#9654;</span><span>Reducing total cost of ownership</span></div>
                        <div><span>&#9654;</span><span>Implementing & Enforcing AWS Well-Architected best practices </span></div>
                        <div><span>&#9654;</span><span>Improving operational excellence</span></div>
                        <div><span>&#9654;</span><span>Designing, implementing, and managing DevOps pipelines</span></div>

                    </div>
                </div>
                <div className="data-objectioncontainer">
                    <div className="data-objections">
                        <h2>
                            Cloud Management you can rely on
                        </h2>
                        <p>
                            As businesses increasingly move their operations to the cloud, it's more important
                            than ever to have a trusted partner that can help you effectively manage your cloud 
                            environment.
                        </p>
                        <p>
                            At <a href="/home" className="backlinks">GLOBAL McLIEN SOFTWARE SOLUTIONS, </a> we understand the importance of cloud management and have 
                            developed a range of solutions that you can rely on to maximize the value of your cloud 
                            investments.
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Why Choose Us for Cloud Management
                        </h2>
                        <p>
                            Our cloud management solutions provide a comprehensive suite of tools and services to help 
                            you effectively manage your cloud environments. Whether you are just starting your journey 
                            and need cloud consulting, or you're looking to optimize your existing cloud infrastructure,
                            our solutions are designed to meet your specific needs and help you achieve your business 
                            goals. 
                            </p>    
                        <p>
                            Cloud-managed services can help businesses reduce their IT costs by eliminating the need for 
                            expensive hardware and <a href="/software" className="backlinks">software. </a>With cloud management, 
                            businesses only pay for the resources
                            they consume, resulting in significant cost savings compared to traditional IT infrastructure.
                        </p>
                        <p>
                            We can provide businesses with the ability to scale their IT resources up or down as needed, 
                            without the need for capital expenditures. This scalability allows businesses to quickly 
                            respond to changing business requirements and growth opportunities.
                        </p>
                        <p>
                            With cloud management, 
                            businesses can access their IT resources from anywhere, at any time, on any <a href="/mobileapp" className="backlinks">mobile device, </a>providing 
                            them with the freedom and flexibility to work from anywhere.
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                           Our Cloud-managed Services
                        </h2>
                        <p>
                            We have several appealing cloud management services that would suit any business looking to
                            streamline their practices. 
                        </p>  
                        <p>
                            Our cloud governance services help you establish clear policies and guidelines for your cloud
                            environment. We work with you to create a cloud strategy that aligns with your business goals
                            and ensures that your cloud infrastructure is secure, compliant, and cost-effective.
                        </p>
                        <p>
                            With cloud optimization, we can help you maximize the value of your cloud investments. 
                            We work with you to identify areas for improvement and implement best practices to ensure 
                            that your cloud environment is running at peak efficiency.
                        </p>
                        <p>
                            Finally, our cloud migration services can assist with the efforts of moving your existing 
                            applications and workloads to the cloud. Our experts will work with you to assess your
                            migration needs, develop a migration plan, and execute the migration to ensure a seamless 
                            transition to the cloud.
                        </p>
                        <p>
                            These aren't our only cloud-managed services, we offer a range of beneficial cloud-based 
                            services for any business, big or small.
                        </p>
                          
                    </div>

                </div>
                
                <Reviews />
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                Is Global Mclien Software Solutions an offshore shop?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    No! we take pride in perfroming all work in the US.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                               What services does Global Mclien Software Solutions offer as part of its cloud management solutions?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    Global Mclien Software Solutions offers a range of cloud-managed services, including cloud governance, cloud optimization, cloud cost management, and cloud migration. These services are designed to help businesses effectively manage their cloud environments and achieve their business goals.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    Can Global Mclien SOftware Solutions help me Migrtae my existing applications to the cloud?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    Yes, Global Mclien Software Solutions has extensive experience in helping businesses migrate their existing applications and workloads to the cloud. Our cloud migration services are designed to help businesses execute a seamless transition to the cloud, ensuring that their applications and workloads are up and running quickly and efficiently.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                   What is Cloud Consulting?
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    Cloud consulting helps businesses make informed decisions about cloud technology by consulting with professionals who have extensive knowledge of cloud management, cloud strategy development, cloud migration, and more. We can ensure that your cloud environment is optimized for performance, security, and cost-effectiveness.
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <FooterForm field="Get started"/>
            </div>
        </>
    )
}

export default React.memo(CloudEngineering);