import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {  BiLogoBitcoin } from "react-icons/bi";
import {FaChalkboardTeacher} from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone , BsCodeSlash } from "react-icons/bs";
import { FcFlowChart } from "react-icons/fc";
import {AiOutlineAudit  } from "react-icons/ai";
import { EmailJsComponent } from '../../utility/EmailJsComponent';
import { Link, useNavigate } from 'react-router-dom';
import Reviews from '../Reviews';
import FooterForm from '../Footer/FooterForm';



const SmartContracts =() =>{
    const[showMoreIndustries, setShowMoreIndustries] = useState(false);
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);
    const navigate = useNavigate();
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' 
                content=
                'Smart contract development, Tezos, truffle deployment, test and deploy with Hardhat, Blockchain, OpenZepplin COntracts, Etherum'
                />
                <meta name='description' 
                    content='Our smart contract development process is divided into several steps,
                     including requirements gathering, design, coding, testing, and deployment. 
                     We work closely with both public and private blockchain using blockchain technology'
                    />
                <title>Smart Contract Development Company - Global Mclien Software Solutions </title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/smartcontracts" />
            </Helmet>
            <div className='component_container ' >
                <div className='smartcontract'>
                    <div id="scroll-container">
                        <div id="scroll-text">
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Etherum</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">DogeCoin</span>
                                <span>&#9660;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Shiba</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">XRP</span>
                                <span>&#9660;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Solana</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Polygon</span>
                                <span>&#9660;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Cardano</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Solidity</span>
                                <span>&#9660;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Binance</span>
                                <span>&#x25B2;</span>
                            </div>
                            <div className='smartcontract_div'>
                                <span id="scroll-span">Tether</span>
                                <span>&#9660;</span>
                            </div>
                        </div>
                    </div>
                    <div className="hero_message_content">
                        <div className="hero_message_topdiv">
                            <h1 className="hero_message_h1">Smart Contract Development</h1>
                            <ul className="hero_message_ul">
                                <li>Wallet development and integration</li>
                                <li>DAO initialisation and Security</li>
                                <li>Web3 UX / UI design</li>
                                <li>DEX Development</li>
                                <li>Token Offering</li>
                            </ul>
                            <p className="hero_message_p">
                                Powering native web3 ecosystems and institutional
                                applications for the next generation of the web3 through our remote-first 
                                team. We serve a global clientele seeking web3 and AI support, transforming ambitious ideas into robust 
                                groundbreaking <Link className="link-builder" to="/software" > software </Link>smart contracts and proving strategic 
                                guidance to transform visions into realities
                            </p>
                            <div className='smart_buttoncontainer'> 
                                <Link className="heromessage_links" to="/blockchainpackages">Our Packages</Link>
                                <Link className="heromessage_links" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="hero_message_image">
                            <img src="/images/smartcontractnobg.png"  alt="smart contract for global mclien software solutions"  />
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free Project Consultation</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>

                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div>
                        <h2 className="sub_htwo">Our Smart Contract &amp; <span className="container_span">Services</span></h2>
                    </div>
                    <div className="reason_container">
                        <div className="reason_element">
                            <BiLogoBitcoin />
                            <p>Building a Tokenomics Model</p>
                            <div>
                                <p className="reason_elementP">
                                Create a smart contract to develop an effective, comprehensive economic model for 
                                your project. In the process of tokenomics creation, a smart contract engineer 
                                develops the principles of its functioning and a functional description of smart
                                 contracts for working with the project tokens.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <FcFlowChart />
                            <p>Architecture</p>
                            <div>
                                <p className="reason_elementP">
                                A smart contract developer can create architecture for your existing or newly 
                                developed <Link className="link-builder" to="/software" > software </Link>solutions using
                                <Link className="link-builder" to="/blockchain" > blockchain </Link>platforms and 
                                smart contracts
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <AiOutlineAudit />
                            <p>Audit</p>
                            <div>
                                <p className="reason_elementP">
                                <Link className="link-builder" to="/about"> Developers </Link>can set up an audit of smart contracts using automated and manual tools to
                                 find bugs and potential security flaws in smart contracts.
                                </p>
                            </div>
                        </div>
                        <div className="reason_element">
                            <BsCodeSlash />
                            <p>Development</p>
                            <div>
                                <p className="reason_elementP">
                                Non-template smart contracts can be developed for your projects in almost any area of 
                                business. Developers can integrate smart contracts into existing solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whatwedo_container">
                    <div className="whatwedo_containerdiv">
                        <h3>Implementing Smart Contracts</h3>
                        <p>Industries that have tried and tested smart contracts implementations</p>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/seoservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>Fundraising</h4>
                                <p>
                                Crowdfunding, ICO, MLM projects. You can create a smart contract and issue your own tokens
                                 on the <Link className="link-builder" to="/blockchain" > blockchain </Link>in order to
                                  attract the interest and capital of investors to implement 
                                 your business idea as quickly and efficiently as possible.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/webservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>Development of a personal investor account</h4>
                                <p>
                                Creating a user interface (front-end) for interacting with the blockchain/smart contract
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/contentservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>DAPP blockchain apps</h4>
                                <p>
                                Creating smart contracts for decentralized applications. You can develop 
                                <Link className="link-builder" to="/mobileapp" > mobile </Link>and 
                                <Link className="link-builder" to="/customwebsites" > web apps </Link>that interact with blockchain platforms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/shopifyservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>Private blockchain platform deployment</h4>
                                <p>
                                Do you need your own independent <Link className="link-builder" to="/blockchain" > blockchain </Link>to interact with your counterparties? We can 
                                help you deploy a private blockchain and create <Link className="link-builder" to="/mobileapp" > apps </Link>to interact with it.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/responsiveservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>Blockchain game development</h4>
                                <p>
                                Creating <Link className="link-builder" to="/gaming" > gaming </Link>and gambling platforms on TRON and 
                                <Link className="link-builder" to="/ethereum" > Ethereum </Link>blockchains.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/ecommerceservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>Crypto funds development</h4>
                                <p>
                                Creating profitability charts, portfolio structure - clearly and user-friendly. 
                                Investors buying crypto assets that go directly in their account and they can monitor the returns.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/hostingservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>Cryptoprocessing</h4>
                                <p>
                                Enabling acceptance of cryptocurrency for your project or personal account. 
                                Using internal billing, crypto payment processing and token accrual.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/wordpressservices.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>Technical consulting and support</h4>
                                <p>
                                Completing an external audit of your smart contract. By ordering development from us,
                                 you automatically get technical support and access to the expertise of our <Link className="link-builder" to="/about"> team</Link>.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div><img className="whatwedo_img" src="/images/customdeve.png" alt="Global Mclien Software solutions seo" /></div>
                            <div className="whatwedo_itemdiv">
                                <h4>Security</h4>
                                <p>
                                Security and uniformity of smart contract execution is ensured by the 
                                <Link className="link-builder" to="/dao" > decentralized </Link>nature and consensus 
                                algorithms of the blockchain network. No party to a smart contract is able to make changes to the smart 
                                contract once it is placed on the <Link className="link-builder" to="/blockchain" > blockchain </Link>network.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="smart-contracteduative">
                    <div className="smart-contractelement">
                        <h4>How the blockchain ecosystem and smart contracts work</h4>
                        <p>
                            The use of smart contracts is related to the <Link className="link-builder" to="/blockchain" > blockchain </Link>ecosystem operation, as they apply 
                            nearly the same algorithm to perform actions in the system. Using <Link className="link-builder" to="/ethereum" > Ethereum </Link>smart contracts 
                            consists of several steps:
                        </p>
                        <ul>
                            <li>1. When a transaction needs to be executed, the user sends it to a network of peer nodes.</li>
                            <li>2. The algorithm verifies the authenticity of digital signatures of both parties involved in the transaction.</li>
                            <li>3. After that, the algorithm-based contract makes a decision for the parties involved in the transaction.</li>
                            <li>4. If the terms of the contract are fully met, the participants receive money. If the rules are violated, the algorithm imposes a penalty on users and also restricts their access to further actions on the platform.</li>
                        </ul>
                    </div>
                    <div className="smart-contractelement">
                        <h4>Essential conditions for smart contract operation</h4>
                        <p>
                        The use of the new technology became possible only in 2013, when Vitalik Buterin's project financed 
                        <Link className="link-builder" to="/ethereum" > Ethereum  </Link>smart contracts. To function properly, the system should have the following features:
                        </p>
                        <ul>
                            <li>1. An asymmetric encryption method should be used to protect data.</li>
                            <li>2. The system should have open <Link className="link-builder" to="/data" > databases </Link>with information about users entering into a transaction.</li>
                            <li>3. There should be complete absence of the human factor.</li>
                            <li>4. Decentralized platforms should be used for a transaction.</li>
                        </ul>
                        <p>
                        Only when the conditions are met, it is possible to create smart contracts to transact within 
                        <Link className="link-builder" to="/blockchain" > blockchain ecosystems.  </Link> 
                        This is why the technology became available for mass use on the network almost 25 years after its invention.
                        </p>
                    </div>
                    <div className="smart-contractelement">
                        <h4>Pros and cons of smart contract development</h4>
                        <p>
                            Modern security technologies for transactions have a number of benefits, including the following:
                        </p>
                        <ul>
                            <li>1. No intermediary that charges an additional fee is required to complete the transaction.</li>
                            <li>2. Business get reliable protection of customer funds and storage of confidential user information.</li>
                            <li>3. There is no need to fill in the contract manually; the service automatically enters all data about the parties of the transaction.</li>
                            <li>4. The algorithm uses multiple duplications, which ensures an additional guarantee of security.</li>
                            <li>5. Users do not need to monitor compliance with the contract terms. If one of the parties violates the requirements, the contract will be automatically deleted.</li>
                        </ul>
                        <p>
                            However, the modern development of smart contracts also has certain disadvantages that do not allow the 
                            technology to be used everywhere. Disadvantages of smart contracts include the following:
                        </p>
                        <ul>
                            <li>1. Advanced solutions can contain code bugs in the system, which often lead to problems in transactions and their execution.</li>
                            <li>2.The lack of government oversight is both an advantage and a disadvantage. There are still no laws regulating the use of smart contracts in the blockchain ecosystem.</li>
                            <li>3. At this stage, the use of smart contracts is only allowed within blockchain ecosystems.</li>
                            <li>4. Some systems have too low data transfer rates, which complicates the conclusion of transactions.</li>
                        </ul>
                    </div>
                     
                </div>
                <div className="other_industries">
                    <h4>Industries with established smart contract implementations</h4>
                    <div className="otherindustrie_items">
                        {showMoreIndustries ? (
                            <>
                                <div className="other_industrieselement">
                                    <div>
                                        <Link to="/">Real Estate</Link>
                                    </div>
                                    <div>
                                        <Link to="/">AR| VR</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Dating</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Affiliate Marketing</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Gambling</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Betting</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Trading</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Retail</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Media</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Telecom</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Manufcaturing</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Insurance </Link>
                                    </div>
                                    <div>
                                        <Link to="/">Construction </Link>
                                    </div>
                                    <div>
                                        <Link to="/">Education</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Finance</Link>
                                    </div>
                                    <div>
                                        <Link to="/">Transportation and Logistics </Link>
                                    </div>
                                    <div>
                                        <Link to="/">Automative </Link>
                                    </div>
                                    <div>
                                        <Link to="/">IoT</Link>
                                    </div>  
                                </div>
                                <div className="persona_button"> 
                                    <button type="button" className="persona_buttonOne" onClick={()=>navigate('/moreindustrylist')}>See All Industry Lists</button>
                                    <button type="button" className="persona_buttonOne" onClick={()=>setShowMoreIndustries(false)}>Close List</button>
                                </div>
                            </>
                        ) : (
                            <button className="other_industriesbutton" onClick={()=>setShowMoreIndustries(true)} type='button'>Click to see more Industries</button>
                        )}
                    </div>
                </div>
                <Reviews />
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    What is the First step to start my Smart Contract?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    Every smart contract is a bit complicated and different, so the very first step that 
                                    mark the start of any project is creating a comprehensive logic that will show 
                                    exact functionality of the contract, mitigation plans, security, cost, technology stacks 
                                    to be used, and user requirements.
                                    The Plan will be sent to you with agreement before taking the next step.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                    Will My Smart Contract process task faster?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    Smart contracts are self-executing and automatically enforce the terms and conditions
                                     encoded within them. They eliminate the need for intermediaries, paperwork, and manual
                                      processes, streamlining transactions and reducing administrative burdens. 
                                      This automation improves efficiency, reduces human errors, and accelerates 
                                      the speed of contract execution.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    Will it be Secure from attacks?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    Smart contracts utilize cryptographic techniques to secure transactions and data. 
                                    The <Link className="link-builder" to="/dao" > decentralized </Link> nature of <Link className="link-builder" to="/blockchain" > blockchain </Link>makes it resistant to single points of failure 
                                    and hacking attempts. Smart contracts also employ encryption, digital signatures, and 
                                    consensus mechanisms to enhance security and protect sensitive information.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                   Will my Users trust the technology?
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    Smart contracts are built on blockchain technology, which provides a decentralized and 
                                    transparent ledger for recording and validating transactions. The immutability of the 
                                    blockchain ensures that once a smart contract is deployed, its code and execution cannot 
                                    be altered. This transparency fosters trust among the parties involved, as everyone has 
                                    access to the same information, reducing the potential for fraud or manipulation.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                    How much budget should I assign to the contract?
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                    By eliminating intermediaries and manual processes, smart contracts can significantly 
                                    reduce costs associated with traditional contract management. The automation and efficiency
                                     offered by smart contracts can minimize overhead expenses, paperwork, reconciliation 
                                     efforts, and the need for third-party verification.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                    How do you programme the Logics?
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <p>
                                  Smart contracts are programmable, allowing for complex business logic and conditions
                                   to be embedded within them. They can incorporate if-then statements, time triggers, 
                                   multi-party agreements, and other conditional clauses. This programmability enables 
                                   customization and flexibility, making smart contracts adaptable to a wide range of use 
                                   cases and automating various business processes.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                    We need to Implement a Change on the COntract
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                    Smart contracts are immutable, meaning that they can never be altered or updated.However, 
                                    there are situations that arises and a change is required, the solution is to create a new 
                                    transaction with a new smart contract to avoid conflicting with the initial smart contract 
                                    in play.
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <FooterForm field="Get A Quote"/>

            </div>
        </>
    )
}

export default React.memo(SmartContracts);