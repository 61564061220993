import React from 'react';
import { Helmet } from 'react-helmet';
import Reviews from '.';

const AllReviews =() =>{

    return(
        <div className="standard">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='Reviews, scalability ,Mobile App, software development ,desktop applications, software consultation,software'/>
                <meta name='description' 
                    content='We Develop custom software solutions with features on a variety of Technology Platforms.
                    Assisst your clients scale their business by offering your market-ready software solution.
                    software quality assurance , software support and maintainance'
                    />
                <title>Our Reviews - Global Mclien Software Solutions | Mobile Apps Reviews</title>
                <link rel="canonical" href="http://www.globalmcliensoftwaresolutions/sitemaps" />
            </Helmet>
            <div >
                <div className="standard_header">
                    <h1 className="standard_hone">Customer Reviews</h1>
                    <h6>Global Mclien Software Solutions</h6>
                </div>
            </div>
            <div>
                <Reviews />
            </div>
        </div>
    )
}

export default React.memo(AllReviews);