import React from 'react';
import { Link } from 'react-router-dom';

const ReturnPolicy =() =>{

    return(
        <div className='privacydiv'>
        <h1>Refund Policy</h1>
        <div>
            <h3 className='privacy-hthree'>Effective Date: January 2024</h3>
        </div>
        <div className="privacycontent">
            <p className='privacyp-tag'>
            At Global Mclien Software Solutions, we are committed to ensuring the satisfaction of our clients. 
            This Refund Policy outlines the terms and conditions under which refunds are provided for our custom mobile application and web application development services. By engaging our services, you agree to the terms of this Refund Policy.
            </p>
        </div>
        <div className="privacy-body">
            <h2> 1. Project Milestones and Payment Structure</h2>
            <p className='privacyp-tag'>Projects are typically divided into four or more milestones. Clients are required to pay a 40% deposit upfront, with the remaining balance divided over the completion of each milestone.
            </p>
        </div>
        <div className="privacy-body">
            <h2> 2. Initial Consultation</h2>
            <p className='privacyp-tag'>We offer a free initial consultation to understand your requirements and provide an initial project proposal.
            </p>
        </div>
        <div className="privacy-body">
            <h2>3. Scope of Work Changes</h2>
            <p className='privacyp-tag'>If there are changes to the overall scope of the project, a new contract will be created to accommodate these changes. Additional costs may apply based on the new scope.
            </p>
        </div>
        <div>
            <h3 className='privacy-hthree'>4. Cancellation and Refunds</h3>
        </div>
        <div className="privacy-body">
            <h2>4.1 Cancellation Before First Milestone</h2>
            <p className='privacyp-tag'>Clients can cancel the project before the completion of the first milestone. In such cases, 85% of the initial deposit will be refunded. The remaining 15% of the initial deposit is non-refundable.
            </p>
        </div>
        <div className="privacy-body">
            <h2>4.2 Refund Eligibility</h2>
            <p className='privacyp-tag'>Refunds are provided only under the following circumstances:
            </p>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>If we are unable to complete the project as agreed.</li>
                    <li className='privacy-li'>If the client cancels the project before the completion of the first milestone.</li>
                </ul>
            </div>
        </div>
        <div className="privacy-body">
            <h2>4.3 Non-Refundable Fees</h2>
            <p className='privacyp-tag'>The 15% of the initial deposit is non-refundable to cover initial project planning and administrative costs.
            </p>
        </div>
        <div className="privacy-body">
            <h2>4.4 Refund Processing Time</h2>
            <p className='privacyp-tag'>Approved refunds will be processed within 10 business days from the date of approval.
            </p>
        </div>
        <div className="privacy-body">
            <h2>5. Client Responsibilities</h2>
            <p className='privacyp-tag'>To ensure smooth project progress and eligibility for refunds, clients must:</p>
            <div className='ul-boxcontent'>
                <ul>
                    <li className='privacy-li'>Provide prompt and clear feedback on their project.</li>
                    <li className='privacy-li'>Supply any necessary materials and information in a timely manner.</li>
                </ul>
            </div>
            <p className='privacyp-tag'>To exercise these rights, please contact us using the contact information provided below.</p>
        </div>
        <div className="privacy-body">
            <h2>6. Dispute Resolution</h2>
            <p className='privacyp-tag'>In the event of a dispute or disagreement regarding the project or refunds, we will work with the client to resolve the issue by granting additional reviews on the project to ensure satisfaction.
            </p>
        </div>
        <div className="privacy-body">
            <h2>7. Contact Us</h2>
            <p className='privacyp-tag'>If you have any questions or concerns about this refunds policies, please contact us at:</p>
            <div className="privacy-body">
                    <Link to="/">Global Mclien Software Solutions</Link>
                <address>
                    25630 Felicia Avenue , Menifee,
                    CA92586
                </address>
                <a href="mailto:developer@softwaredevbytes.com">info@globalmcliensoftwaresolutions.com</a>
                <p>
                <a href="tel:+17472498760">7472498760</a>

                </p>
            </div>
        </div>
    </div>
    )
}

export default React.memo(ReturnPolicy);