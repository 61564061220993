import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Home from './components/Home';
import Software from './components/Software';
import Website from './components/Website';
import Seo from './components/Seo';
import DigitalMarketing from './components/DigitalMarketing';
import MobileApplication from './components/MobileApplication';
import SecondLayout from './components/Layout/SecondLayout';
import Contact from './components/Contact';
import SuccessPage from './components/SuccessPage';
import NotFound from './components/NotFound';
import PlainLayout from './components/Layout/PlainLayout';
import Sitemaps from './components/Sitemaps';
import MoreIndustryList from './components/MoreIndustryList';
import Blog from './components/Blog';
import About from './components/About';
import Legal from './components/Legal';
import PrivacyPolicy from './components/PrivacyPolicy';
import Press from './components/Press';
import DataEngineering from './components/DataEngineering';
import CloudEnginnering from './components/CloudEnginnering';
import LandingPages from './components/LandingPages';
import AllReviews from './components/Reviews/AllReviews';
import BlockChain from './components/BlockChain';
import SmartContracts from './components/BlockChain/SmartContracts';
import Pricing from './components/Pricing';
import PackagesPage from './components/Pricing/PackagesPage';
import PaymentPage from './components/Pricing/PaymentPage';
import PackageDetails from './components/Pricing/PackageDetails';
import Donation from './components/Pricing/Donation';
import Gaming from './components/Gaming';
import IosApp from './components/IosApp';
import AndroidApp from './components/AndroidApp';
import Xamarin from './components/Xamarin';
import JavaApp from './components/JavaApp';
import DigitalMarketingPackages from './components/Pricing/DigitalMarketingPackages';
import BlockchainPackages from './components/Pricing/BlockchainPackages';
import MobilePackages from './components/Pricing/MobilePackages';
import WebsitePackages from './components/Pricing/WebsitePackages';
import Softwarepackages from './components/Pricing/Softwarepackages';
import Mobileappblog from './components/Blog/mobileappblog';
import Websiteblog from './components/Blog/websiteblog';
import Softwareblog from './components/Blog/softwareblog';
import Digitalmktblog from './components/Blog/digitalmktblog';
import Blockchainblog from './components/Blog/blockchainblog';
import Ethereum from './components/BlockChain/Ethereum';
import CustodialWallet from './components/BlockChain/CustodialWallet';
import DaoDev from './components/BlockChain/DaoDev';
import DefiDev from './components/BlockChain/DefiDev';
import GoogleSeo from './digitalmarketing/GoogleSeo';
import Facebookads from './digitalmarketing/Facebookads';
import Backlinks from './digitalmarketing/Backlinks';
import LocalRankings from './digitalmarketing/LocalRankings';
import HybridMobileApp from './components/HybridMobileApp';
import MenifeeDevelopers from './components/LandingPages/MenifeeDevelopers';
import UsaDevelopers from './components/LandingPages/UsaDevelopers';
import CustomWebsites from './components/CustomWebsites';
import AppforMidsizebizz from './components/Blog/mobileappblog/AppforMidsizebizz';
import TermsAndConditions from './components/TermsAndConditions';
import ReturnPolicy from './components/ReturnPolicy';
import MobileAppCalifornia from './components/LandingPages/mobileapplandingpages/MobileAppCalifornia';
import MobileAppMenifee from './components/LandingPages/mobileapplandingpages/MobileAppMenifee';
import MobileAppLa from './components/LandingPages/mobileapplandingpages/MobileAppLa';
import MobileAppMurrietta from './components/LandingPages/mobileapplandingpages/MobileAppMurrietta';
import MobileAppLakeElsinore from './components/LandingPages/mobileapplandingpages/MobileAppLakeElsinore';
import MobileAppLosAlamos from './components/LandingPages/mobileapplandingpages/MobileAppLosAlamos';
import MobileAppPerris from './components/LandingPages/mobileapplandingpages/MobileAppPerris';
import MobileAppTemecula from './components/LandingPages/mobileapplandingpages/MobileAppTemecula';
import MobileAppWildomar from './components/LandingPages/mobileapplandingpages/MobileAppWildomar';
import MobileAppCorona from './components/LandingPages/mobileapplandingpages/MobileAppCorona';
import MobileAppNorco from './components/LandingPages/mobileapplandingpages/MobileAppNorco';
import MobileAppOrange from './components/LandingPages/mobileapplandingpages/MobileAppOrange';
import MobileAppLongBeach from './components/LandingPages/mobileapplandingpages/MobileAppLongBeach';
import MobileAppSanFran from './components/LandingPages/mobileapplandingpages/MobileAppSanFran';
import CaliforniaAppDev from './components/LandingPages/mobileapplandingpages/CaliforniaAppDev';
import MenifeeAppDev from './components/LandingPages/mobileapplandingpages/MenifeeAppDev';
import LosAngelesAppDev from './components/LandingPages/mobileapplandingpages/LosAngelesAppDev';
import MurriettaAppDev from './components/LandingPages/mobileapplandingpages/MurriettaAppDev';
import LakeElsinoreAPpDev from './components/LandingPages/mobileapplandingpages/LakeElsinoreAPpDev';
import LosAlamosAppDev from './components/LandingPages/mobileapplandingpages/LosAlamosAppDev';
import TemeculaAppDev from './components/LandingPages/mobileapplandingpages/TemeculaAppDev';
import WildomarAppDev from './components/LandingPages/mobileapplandingpages/WildomarAppDev';
import CoronaAppDev from './components/LandingPages/mobileapplandingpages/CoronaAppDev';
import NorcoAppDev from './components/LandingPages/mobileapplandingpages/NorcoAppDev';
import OrangeAppDev from './components/LandingPages/mobileapplandingpages/OrangeAppDev';
import LongBeachAppDev from './components/LandingPages/mobileapplandingpages/LongBeachAppDev';
import SanFranAppDev from './components/LandingPages/mobileapplandingpages/SanFranAppDev';
import SandiegoAppDev from './components/LandingPages/mobileapplandingpages/SandiegoAppDev';
import PalmSpringsAppDev from './components/LandingPages/mobileapplandingpages/PalmSpringsAppDev';
import SacramentoAppDev from './components/LandingPages/mobileapplandingpages/SacramentoAppDev';
import SantaBarbaraAppDev from './components/LandingPages/mobileapplandingpages/SantaBarbaraAppDev';
import MobileAppSantaBarbara from './components/LandingPages/mobileapplandingpages/MobileAppSantaBarbara';
import MobileAppSacramento from './components/LandingPages/mobileapplandingpages/MobileAppSacramento';
import MobileAppPalmSprings from './components/LandingPages/mobileapplandingpages/MobileAppPalmSprings';
import MobileAppSandiego from './components/LandingPages/mobileapplandingpages/MobileAppSandiego';
import AlabamaAppDev from './components/LandingPages/mobileapplandingpages/AlabamaAppDev';
import AlaskaAppDev from './components/LandingPages/mobileapplandingpages/AlaskaAppDev';
import ArizonaAppDev from './components/LandingPages/mobileapplandingpages/ArizonaAppDev';
import ArkansasAppDev from './components/LandingPages/mobileapplandingpages/ArkansasAppDev';
import ColoradoAppDev from './components/LandingPages/mobileapplandingpages/ColoradoAppDev';
import ConnecticutAppDev from './components/LandingPages/mobileapplandingpages/ConnecticutAppDev';
import DCappDev from './components/LandingPages/mobileapplandingpages/DCappDev';
import DelawareAppDev from './components/LandingPages/mobileapplandingpages/DelawareAppDev';
import FloridaAppDev from './components/LandingPages/mobileapplandingpages/FloridaAppDev';
import GeorgiaAppDev from './components/LandingPages/mobileapplandingpages/GeorgiaAppDev';
import GuamAppDev from './components/LandingPages/mobileapplandingpages/GuamAppDev';
import HawaiiAppDev from './components/LandingPages/mobileapplandingpages/HawaiiAppDev';
import IdahoAppDev from './components/LandingPages/mobileapplandingpages/IdahoAppDev';
import IllinoisAppDev from './components/LandingPages/mobileapplandingpages/IllinoisAppDev';
import IndianaAppDev from './components/LandingPages/mobileapplandingpages/IndianaAppDev';
import IowaAppDev from './components/LandingPages/mobileapplandingpages/IowaAppDev';
import KansasAppDev from './components/LandingPages/mobileapplandingpages/KansasAppDev';
import KentuckyAppDev from './components/LandingPages/mobileapplandingpages/KentuckyAppDev';
import LouisianaAppDev from './components/LandingPages/mobileapplandingpages/LouisianaAppDev';
import MaineAppDev from './components/LandingPages/mobileapplandingpages/MaineAppDev';
import MarylandAppDev from './components/LandingPages/mobileapplandingpages/MarylandAppDev';
import MassAppDev from './components/LandingPages/mobileapplandingpages/MassAppDev';
import MichiganAppDev from './components/LandingPages/mobileapplandingpages/MichiganAppDev';
import MinnesataAppDev from './components/LandingPages/mobileapplandingpages/MinnesataAppDev';
import MississippiAppDev from './components/LandingPages/mobileapplandingpages/MississippiAppDev';
import MissouriAppDev from './components/LandingPages/mobileapplandingpages/MissouriAppDev';
import MontanaAppDev from './components/LandingPages/mobileapplandingpages/MontanaAppDev';
import NCarolinaAppDev from './components/LandingPages/mobileapplandingpages/NCarolinaAppDev';
import NebraskaAppDev from './components/LandingPages/mobileapplandingpages/NebraskaAppDev';
import NevadaAppDev from './components/LandingPages/mobileapplandingpages/NevadaAppDev';
import NewJerseyAppDev from './components/LandingPages/mobileapplandingpages/NewJerseyAppDev';
import NewYorkAppDev from './components/LandingPages/mobileapplandingpages/NewYorkAppDev';
import NewMexicoAppDev from './components/LandingPages/mobileapplandingpages/NewMexicoAppDev';
import NHampshireAppDev from './components/LandingPages/mobileapplandingpages/NHampshireAppDev';
import NorthDakotaApps from './components/LandingPages/mobileapplandingpages/NorthDakotaApps';
import OhioAppDev from './components/LandingPages/mobileapplandingpages/OhioAppDev';
import OklahomaAppDev from './components/LandingPages/mobileapplandingpages/OklahomaAppDev';
import OregonAppDev from './components/LandingPages/mobileapplandingpages/OregonAppDev';
import PennsylvaniaAppDev from './components/LandingPages/mobileapplandingpages/PennsylvaniaAppDev';
import PuertoRicoAppDev from './components/LandingPages/mobileapplandingpages/PuertoRicoAppDev';
import RhodeIslandAppDev from './components/LandingPages/mobileapplandingpages/RhodeIslandAppDev';
import SCarolinaAppDev from './components/LandingPages/mobileapplandingpages/SCarolinaAppDev';
import SDakotaAppDev from './components/LandingPages/mobileapplandingpages/SDakotaAppDev';
import TennesseeAppDev from './components/LandingPages/mobileapplandingpages/TennesseeAppDev';
import TexasAppDev from './components/LandingPages/mobileapplandingpages/TexasAppDev';
import UtahAppDev from './components/LandingPages/mobileapplandingpages/UtahAppDev';
import VermontAppDev from './components/LandingPages/mobileapplandingpages/VermontAppDev';
import WVirginiaApp from './components/LandingPages/mobileapplandingpages/WVirginiaApp';
import WisconsinAppDev from './components/LandingPages/mobileapplandingpages/WisconsinAppDev';
import WebsiteDesignInCalifornia from './components/LandingPages/websites/website-design-in-california';
import WebsiteDesignInMenifee from './components/LandingPages/websites/website-design-in-menifee';
import WebsiteDesignInPueblo from './components/LandingPages/websites/website-design-in-pueblo';
import WebsiteDesignInFresno from './components/LandingPages/websites/website-design-in-fresno';
import WebsiteDesignInTacoma from './components/LandingPages/websites/website-design-in-tacoma';
import WebsiteDesignInBoise from './components/LandingPages/websites/website-design-in-boise';
import WebsiteDesignInSeattle from './components/LandingPages/websites/website-design-in-seattle';
import WebsiteDesignInAtlanta from './components/LandingPages/websites/website-design-in-atlanta';
import WebsiteDesignInAlabama from './components/LandingPages/websites/website-design-in-alabama';
import WebsiteDesignInAlaska from './components/LandingPages/websites/website-design-in-alaska';
import WebsiteDesignInArizona from './components/LandingPages/websites/website-design-in-arizona';
import WebsiteDesignInArkansas from './components/LandingPages/websites/website-design-in-arkansas';
import WebsiteDesignInColorado from './components/LandingPages/websites/website-design-in-colorado';
import WebsiteDesignInConnecticut from './components/LandingPages/websites/website-design-in-connecticut';
import WebsiteDesignInDc from './components/LandingPages/websites/website-design-in-dc';
import WebsiteDesignInDelaware from './components/LandingPages/websites/website-design-in-delaware';
import WebsiteDesignInFlorida from './components/LandingPages/websites/website-design-in-florida';
import WebsiteDesignInGeorgia from './components/LandingPages/websites/website-design-in-georgia';
import WebsiteDesignInGuam from './components/LandingPages/websites/website-design-in-guam';
import WebsiteDesignInHawaii from './components/LandingPages/websites/website-design-in-hawaii';
import WebsiteDesignInIdaho from './components/LandingPages/websites/website-design-in-idaho';
import WebsiteDesignInIndiana from './components/LandingPages/websites/website-design-in-indiana';
import WebsiteDesignInIowa from './components/LandingPages/websites/website-design-in-iowa';
import WebsiteDesignInKansas from './components/LandingPages/websites/website-design-in-kansas';
import WebsiteDesignInKentucky from './components/LandingPages/websites/website-design-in-kentucky';
import WebsiteDesignInLouisiana from './components/LandingPages/websites/website-design-in-louisiana';
import WebsiteDesignInMaine from './components/LandingPages/websites/website-design-in-maine';
import WebsiteDesignInVirginia from './components/LandingPages/websites/website-design-in-virginia';
import WebsiteDesignInMaryland from './components/LandingPages/websites/website-design-in-maryland';
import WebsiteDesignInMass from './components/LandingPages/websites/website-design-in-mass';
import WebsiteDesignInMichigan from './components/LandingPages/websites/website-design-in-michigan';
import WebsiteDesignInMinnesota from './components/LandingPages/websites/website-design-in-minnesota';
import WebsiteDesignInMississippi from './components/LandingPages/websites/website-design-in-mississippi';
import WebsiteDesignInMissouri from './components/LandingPages/websites/website-design-in-missouri';
import WebsiteDesignInMontana from './components/LandingPages/websites/website-design-in-montana';
import WebsiteDesignInNebraska from './components/LandingPages/websites/website-design-in-nebraska';
import WebsiteDesignInNewHampshire from './components/LandingPages/websites/website-design-in-new-hampshire';
import WebsiteDesignInNewJersey from './components/LandingPages/websites/website-design-in-new-jersey';
import WebsiteDesignInNewMexico from './components/LandingPages/websites/website-design-in-new-mexico';
import WebsiteDesignInNewYork from './components/LandingPages/websites/website-design-in-new-york';
import WebsiteDesignInNcarolina from './components/LandingPages/websites/website-design-in-ncarolina';
import WebsiteDesignInNorthDakota from './components/LandingPages/websites/website-design-in-north-dakota';
import WebsiteDesignInOhio from './components/LandingPages/websites/website-design-in-ohio';
import WebsiteDesignInOklahoma from './components/LandingPages/websites/website-design-in-oklahoma';
import WebsiteDesignInNevada from './components/LandingPages/websites/website-design-in-nevada';
import WebsiteDesignInOregon from './components/LandingPages/websites/website-design-in-oregon';
import WebsiteDesignInPennsylvania from './components/LandingPages/websites/website-design-in-pennsylvania';
import WebsiteDesignInPuertoRico from './components/LandingPages/websites/website-design-in-puerto-rico';
import WebsiteDesignInRhodeIsland from './components/LandingPages/websites/website-design-in-rhode-island';
import WebsiteDesignInSouthCarolina from './components/LandingPages/websites/website-design-in-south-carolina';
import WebsiteDesignInSouthDakota from './components/LandingPages/websites/website-design-in-south-dakota';
import WebsiteDesignInTennesse from './components/LandingPages/websites/website-design-in-tennesse';
import WebsiteDesignInTexas from './components/LandingPages/websites/website-design-in-texas';
import WebsiteDesignInUtah from './components/LandingPages/websites/website-design-in-utah';
import WebsiteDesignInVermont from './components/LandingPages/websites/website-design-in-vermont';
import WebsiteDesignInWestVirgina from './components/LandingPages/websites/website-design-in-west-virgina';
import WebsiteDesignInWyoming from './components/LandingPages/websites/website-design-in-wyoming';
import WebsiteDesignInAgouraHills from './components/LandingPages/websites/website-design-in-agoura-hills';
import WebsiteDesignInAlhambra from './components/LandingPages/websites/website-design-in-alhambra';
import WebsiteDesignInArcadia from './components/LandingPages/websites/website-design-in-arcadia';
import WebsiteDesignInArtesia from './components/LandingPages/websites/website-design-in-artesia';
import WebsiteDesignInAvalon from './components/LandingPages/websites/website-design-in-avalon';
import WebsiteDesignInAzusa from './components/LandingPages/websites/website-design-in-azusa';
import WebsiteDesignInBaldwinPark from './components/LandingPages/websites/website-design-in-baldwin-park';
import WebsiteDesignInBell from './components/LandingPages/websites/website-design-in-bell';
import WebsiteDesignInBellGardens from './components/LandingPages/websites/website-design-in-bell-gardens';
import WebsiteDesignInBellflower from './components/LandingPages/websites/website-design-in-bellflower';
import WebsiteDesignInBeverlyHills from './components/LandingPages/websites/website-design-in-beverly-hills';
import WebsiteDesignInHollywood from './components/LandingPages/websites/website-design-in-hollywood';
import WebsiteDesignInBradbury from './components/LandingPages/websites/website-design-in-bradbury';
import WebsiteDesignInBurbank from './components/LandingPages/websites/website-design-in-burbank';
import WebsiteDesignInCalabasas from './components/LandingPages/websites/website-design-in-calabasas';
import WebsiteDesignInCarson from './components/LandingPages/websites/website-design-in-carson';
import WebsiteDesignInCrenshaw from './components/LandingPages/websites/website-design-in-crenshaw';
import WebsiteDesignInCerritos from './components/LandingPages/websites/website-design-in-cerritos';
import WebsiteDesignInClaremont from './components/LandingPages/websites/website-design-in-claremont';
import WebsiteDesignInCommerce from './components/LandingPages/websites/website-design-in-commerce';
import WebsiteDesignInCompton from './components/LandingPages/websites/website-design-in-compton';
import WebsiteDesignInCovina from './components/LandingPages/websites/website-design-in-covina';
import WebsiteDesignInCudahy from './components/LandingPages/websites/website-design-in-cudahy';
import WebsiteDesignInCulverCity from './components/LandingPages/websites/website-design-in-culver-city';
import WebsiteDesignInDiamondBar from './components/LandingPages/websites/website-design-in-diamond-bar';
import WebsiteDesignInDowney from './components/LandingPages/websites/website-design-in-downey';
import WebsiteDesignInDuarte from './components/LandingPages/websites/website-design-in-duarte';
import WebsiteDesignInElMonte from './components/LandingPages/websites/website-design-in-el-monte';
import WebsiteDesignInElSegundo from './components/LandingPages/websites/website-design-in-el-segundo';
import WebsiteDesignInGardena from './components/LandingPages/websites/website-design-in-gardena';
import WebsiteDesignInGlendora from './components/LandingPages/websites/website-design-in-glendora';
import WebsiteDesignInGlendale from './components/LandingPages/websites/website-design-in-glendale';
import WebsiteDesignInHawaiianGardens from './components/LandingPages/websites/website-design-in-hawaiian-gardens';
import WebsiteDesignInHawthorne from './components/LandingPages/websites/website-design-in-hawthorne';
import WebsiteDesignInHermosaBeach from './components/LandingPages/websites/website-design-in-hermosa-beach';
import WebsiteDesignInHiddenHills from './components/LandingPages/websites/website-design-in-hidden-hills';
import WebsiteDesignInHuntingtonPark from './components/LandingPages/websites/website-design-in-huntington-park';
import WebsiteDesignInIndustry from './components/LandingPages/websites/website-design-in-industry';
import WebsiteDesignInInglewood from './components/LandingPages/websites/website-design-in-inglewood';
import WebsiteDesignInIrwindale from './components/LandingPages/websites/website-design-in-irwindale';
import WebsiteDesignInLaCañadaFlintridge from './components/LandingPages/websites/website-design-in-la-cañada-flintridge';
import WebsiteDesignInLaHabraHeights from './components/LandingPages/websites/website-design-in-la-habra-heights';
import WebsiteDesignInLaMirada from './components/LandingPages/websites/website-design-in-la-mirada';
import WebsiteDesignInLaPuente from './components/LandingPages/websites/website-design-in-la-puente';
import WebsiteDesignInLaVerne from './components/LandingPages/websites/website-design-in-la-verne';
import WebsiteDesignInLakewood from './components/LandingPages/websites/website-design-in-lakewood';
import WebsiteDesignInLancaster from './components/LandingPages/websites/website-design-in-lancaster';
import WebsiteDesignInLawndale from './components/LandingPages/websites/website-design-in-lawndale';
import WebsiteDesignInLomita from './components/LandingPages/websites/website-design-in-lomita';
import WebsiteDesignInLongBeach from './components/LandingPages/websites/website-design-in-long-beach';
import WebsiteDesignInLosAngeles from './components/LandingPages/websites/website-design-in-los-angeles';
import WebsiteDesignInLynwood from './components/LandingPages/websites/website-design-in-lynwood';
import WebsiteDesignInMalibu from './components/LandingPages/websites/website-design-in-malibu';
import WebsiteDesignInManhattanBeach from './components/LandingPages/websites/website-design-in-manhattan-beach';
import WebsiteDesignInMaywood from './components/LandingPages/websites/website-design-in-maywood';
import WebsiteDesignInMonrovia from './components/LandingPages/websites/website-design-in-monrovia';
import WebsiteDesignInMontebello from './components/LandingPages/websites/website-design-in-montebello';
import WebsiteDesignInMontereyPark from './components/LandingPages/websites/website-design-in-monterey-park';
import WebsiteDesignInNorwalk from './components/LandingPages/websites/website-design-in-norwalk';
import WebsiteDesignInPalmdale from './components/LandingPages/websites/website-design-in-palmdale';
import WebsiteDesignInPalosVerdesEstates from './components/LandingPages/websites/website-design-in-palos-verdes-estates';
import WebsiteDesignInParamount from './components/LandingPages/websites/website-design-in-paramount';
import WebsiteDesignInPasadena from './components/LandingPages/websites/website-design-in-pasadena';
import WebsiteDesignInPicoRivera from './components/LandingPages/websites/website-design-in-pico-rivera';
import WebsiteDesignInPomona from './components/LandingPages/websites/website-design-in-pomona';
import WebsiteDesignInRanchoPalosVerdes from './components/LandingPages/websites/website-design-in-rancho-palos-verdes';
import WebsiteDesignInRedondoBeach from './components/LandingPages/websites/website-design-in-redondo-beach';
import WebsiteDesignInRollingHills from './components/LandingPages/websites/website-design-in-rolling-hills';
import WebsiteDesignInRollingHillsEstates from './components/LandingPages/websites/website-design-in-rolling-hills-estates';
import WebsiteDesignInRosemead from './components/LandingPages/websites/website-design-in-rosemead';
import WebsiteDesignInSanDimas from './components/LandingPages/websites/website-design-in-san-dimas';
import WebsiteDesignInSanFernando from './components/LandingPages/websites/website-design-in-san-fernando';
import WebsiteDesignInSanGabriel from './components/LandingPages/websites/website-design-in-san-gabriel';
import WebsiteDesignInSanMarino from './components/LandingPages/websites/website-design-in-san-marino';
import WebsiteDesignInSantaClarita from './components/LandingPages/websites/website-design-in-santa-clarita';
import WebsiteDesignInSantaFeSprings from './components/LandingPages/websites/website-design-in-santa-fe-springs';
import WebsiteDesignInSantaMonica from './components/LandingPages/websites/website-design-in-santa-monica';
import WebsiteDesignInSierraMadre from './components/LandingPages/websites/website-design-in-sierra-madre';
import WebsiteDesignInSignalHill from './components/LandingPages/websites/website-design-in-signal-hill';
import WebsiteDesignInSouthElMonte from './components/LandingPages/websites/website-design-in-south-el-monte';
import WebsiteDesignInSouthGate from './components/LandingPages/websites/website-design-in-south-gate';
import WebsiteDesignInSouthPasadena from './components/LandingPages/websites/website-design-in-south-pasadena';
import WebsiteDesignInTempleCity from './components/LandingPages/websites/website-design-in-temple-city';
import WebsiteDesignInTorrance from './components/LandingPages/websites/website-design-in-torrance';
import WebsiteDesignInVernon from './components/LandingPages/websites/website-design-in-vernon';
import WebsiteDesignInWalnut from './components/LandingPages/websites/website-design-in-walnut';
import WebsiteDesignInWestCovina from './components/LandingPages/websites/website-design-in-west-covina';
import WebsiteDesignInWestHollywood from './components/LandingPages/websites/website-design-in-west-hollywood';
import WebsiteDesignInWestlakeVillage from './components/LandingPages/websites/website-design-in-westlake-village';
import WebsiteDesignInWhittier from './components/LandingPages/websites/website-design-in-whittier';
import WebsiteDesignInEastLosAngeles from './components/LandingPages/websites/website-design-in-east-los-angeles';
import WebsiteDesignInNinetynineOaks from './components/LandingPages/websites/website-design-in-ninetynine-oaks';
import WebsiteDesignInAvocadoHeights from './components/LandingPages/websites/website-design-in-avocado-heights';
import WebsiteDesignInSouthSanGabriel from './components/LandingPages/websites/website-design-in-south-san-gabriel';
import WebsiteDesignInMarinaDelRey from './components/LandingPages/websites/website-design-in-marina-del-rey';
import WebsiteDesignInQuartzHill from './components/LandingPages/websites/website-design-in-quartz-hill';
import WebsiteDesignInMayflowerVillage from './components/LandingPages/websites/website-design-in-mayflower-village';
import WebsiteDesignInTopanga from './components/LandingPages/websites/website-design-in-topanga';
import WebsiteDesignInLakeLosAngeles from './components/LandingPages/websites/website-design-in-lake-los-angeles';
import WebsiteDesignInWalnutPark from './components/LandingPages/websites/website-design-in-walnut-park';
import WebsiteDesignInRowlandHeights from './components/LandingPages/websites/website-design-in-rowland-heights';
import WebsiteDesignInCityOfIndustry from './components/LandingPages/websites/website-design-in-city-of-industry';
import WebsiteDesignInCharterOak from './components/LandingPages/websites/website-design-in-charter-oak';
import WebsiteDesignInIe from './components/LandingPages/websites/website-design-in-ie';
import WebsiteDesignInCorona from './components/LandingPages/websites/website-design-in-corona';
import WebsiteDesignInLakeElsinore from './components/LandingPages/websites/website-design-in-lake-elsinore';
import WebsiteDesignInLosAlamos from './components/LandingPages/websites/website-design-in-los-alamos';
import WebsiteDesignInNorco from './components/LandingPages/websites/website-design-in-norco';
import WebsiteDesignInPerris from './components/LandingPages/websites/website-design-in-perris';
import WebsiteDesignInSacramento from './components/LandingPages/websites/website-design-in-sacramento';
import WebsiteDesignInPalmSprings from './components/LandingPages/websites/website-design-in-palm-springs';
import WebsiteDesignInSanDiego from './components/LandingPages/websites/website-design-in-san-diego';
import WebsiteDesignInWildomar from './components/LandingPages/websites/website-design-in-wildomar';
import WebsiteDesignInTemecula from './components/LandingPages/websites/website-design-in-temecula';
import WebsiteDesignInEscondido from './components/LandingPages/websites/website-design-in-escondido';
import WebsiteDesignInFallbrook from './components/LandingPages/websites/website-design-in-fallbrook';
import WebsiteDesignInOceansSide from './components/LandingPages/websites/website-design-in-oceans-side';
import WebsiteDesignInFrenchValley from './components/LandingPages/websites/website-design-in-french-valley';
import WebsiteDesignInClintonKeith from './components/LandingPages/websites/website-design-in-clinton-keith';
import WebsiteDesignInRanchoCucamonga from './components/LandingPages/websites/website-design-in-rancho-cucamonga';
import WebsiteDesignInSantaBarbara from './components/LandingPages/websites/website-design-in-santa-barbara';
import WebsiteDesignInRanchoCalifornia from './components/LandingPages/websites/website-design-in-rancho-california';
import WebsiteDesignInChinoHills from './components/LandingPages/websites/website-design-in-chino-hills';
import WebsiteDesignInRanchoMirage from './components/LandingPages/websites/website-design-in-rancho-mirage';
import WebsiteDesignInChino from './components/LandingPages/websites/website-design-in-chino';
import WebsiteDesignInSanBernadino from './components/LandingPages/websites/website-design-in-san-bernadino';
import WebsiteDesignInSanJacinto from './components/LandingPages/websites/website-design-in-san-jacinto';
import WebsiteDesignInFontana from './components/LandingPages/websites/website-design-in-fontana';
import WebsiteDesignInPalmDesert from './components/LandingPages/websites/website-design-in-palm-desert';
import WebsiteDesignInJurupaValley from './components/LandingPages/websites/website-design-in-jurupa-valley';
import WebsiteDesignInLaQuinta from './components/LandingPages/websites/website-design-in-la-quinta';
import WebsiteDesignInMorenoValley from './components/LandingPages/websites/website-design-in-moreno-valley';
import WebsiteDesignInEastvale from './components/LandingPages/websites/website-design-in-eastvale';
import WebsiteDesignInIndio from './components/LandingPages/websites/website-design-in-indio';
import WebsiteDesignInDesertHotSprings from './components/LandingPages/websites/website-design-in-desert-hot-springs';
import WebsiteDesignInCoachella from './components/LandingPages/websites/website-design-in-coachella';
import WebsiteDesignInCalimesa from './components/LandingPages/websites/website-design-in-calimesa';
import WebsiteDesignInBlythe from './components/LandingPages/websites/website-design-in-blythe';
import WebsiteDesignInBeaumont from './components/LandingPages/websites/website-design-in-beaumont';
import WebsiteDesignInBanning from './components/LandingPages/websites/website-design-in-banning';
import WebsiteDesignInAdelanto from './components/LandingPages/websites/website-design-in-adelanto';
import WebsiteDesignInColton from './components/LandingPages/websites/website-design-in-colton';
import WebsiteDesignInLomaLinda from './components/LandingPages/websites/website-design-in-loma-linda';
import WebsiteDesignInVictorville from './components/LandingPages/websites/website-design-in-victorville';
import WebsiteDesignInOntario from './components/LandingPages/websites/website-design-in-ontario';
import WebsiteDesignInJoshuaTree from './components/LandingPages/websites/website-design-in-joshua-tree';
import WebsiteDesignInBigBearLake from './components/LandingPages/websites/website-design-in-big-bear-lake';
import WebsiteDesignInAppleValley from './components/LandingPages/websites/website-design-in-apple-valley';
import WebsiteDesignInRialto from './components/LandingPages/websites/website-design-in-rialto';
import WebsiteDesignInRedlands from './components/LandingPages/websites/website-design-in-redlands';
import WebsiteDesignInBarstow from './components/LandingPages/websites/website-design-in-barstow';
import WebsiteDesignInBloomington from './components/LandingPages/websites/website-design-in-bloomington';
import WebsiteDesignInLakeArrowhead from './components/LandingPages/websites/website-design-in-lake-arrowhead';

function App(): JSX.Element {
 
  return (
    <Router>
      <Routes>
        <Route  path="/" element={<SecondLayout />} >
          <Route index element={<Home />} />
          <Route path="/websites" element={<Website />} />
          <Route path="/customwebsites" element={<CustomWebsites />} />
          <Route path="/software" element={<Software />} />
          <Route path="/mobileapp" element={<MobileApplication/>} />
          <Route path="/home" element={<MobileApplication/>} />
          <Route path="/iosapp" element={<IosApp/>} />
          <Route path="/androidapp" element={<AndroidApp/>} />
          <Route path="/javapp" element={<JavaApp/>} />
          <Route path="/xamarinapp" element={<Xamarin/>} />
          <Route path="/hybridapp" element={<HybridMobileApp/>} />
          <Route path="/digitalmarketing" element={<DigitalMarketing/>} />
          <Route path="/localrankings" element={<LocalRankings/>} />
          <Route path="/backlinks" element={<Backlinks/>} />
          <Route path="/facebookads" element={<Facebookads/>} />
          <Route path="/googleseo" element={<GoogleSeo/>} />
          <Route path="/seo" element={<Seo/>} />
          <Route path="/blockchain" element={<BlockChain/>} />
          <Route path="/ethereum" element={<Ethereum/>} />
          <Route path="/custodialwallet" element={<CustodialWallet/>} />
          <Route path="/dao" element={<DaoDev/>} />
          <Route path="/defi" element={<DefiDev/>} />
          <Route path="/smartcontracts" element={<SmartContracts/>} />
          <Route path="/data" element={<DataEngineering/>} />
          <Route path="/cloud" element={<CloudEnginnering/>} />
          <Route path="/gaming" element={<Gaming/>} />
        </Route>
        <Route  element={<PlainLayout />} >
          <Route path="/sucesspage" element={<SuccessPage />} />
          <Route path="/all-reviews" element={<AllReviews />} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/mobileappblog" element={<Mobileappblog/>} />
          <Route path="/mobileappblogitem" element={<AppforMidsizebizz/>} />
          <Route path="/websiteblog" element={<Websiteblog/>} />
          <Route path="/softwareblog" element={<Softwareblog/>} />
          <Route path="/digitalmarketingblog" element={<Digitalmktblog/>} />
          <Route path="/blockchainblog" element={<Blockchainblog/>} />
          <Route path="/donations" element={<Donation/>} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/packages" element={<PackagesPage/>} />
          <Route path="/digitalmarketingpackages" element={<DigitalMarketingPackages/>} />
          <Route path="/blockchainpackages" element={<BlockchainPackages/>} />
          <Route path="/mobileapppackages" element={<MobilePackages/>} />
          <Route path="/websitespackages" element={<WebsitePackages/>} />
          <Route path="/softwarepackages" element={<Softwarepackages/>} />
          <Route path="/payment" element={<PaymentPage/>} />
          <Route path="/package_details" element={<PackageDetails/>} />
          <Route path="/landingpage" element={<LandingPages/>} />
          <Route path="/mobile-app-development-california" element={<MobileAppCalifornia/>} />
          <Route path="/mobile-app-development-menifee" element={<MobileAppMenifee/>} />
          <Route path="/mobile-app-development-los-angeles" element={<MobileAppLa/>} />
          <Route path="/mobile-app-development-murrietta" element={<MobileAppMurrietta/>} />
          <Route path="/mobile-app-development-lake-elsinore" element={<MobileAppLakeElsinore/>} />
          <Route path="/mobile-app-development-los-alamos" element={<MobileAppLosAlamos/>} />
          <Route path="/mobile-app-development-perris" element={<MobileAppPerris/>} />
          <Route path="/mobile-app-development-temecula" element={<MobileAppTemecula/>} />
          <Route path="/mobile-app-development-wildomar" element={<MobileAppWildomar/>} />
          <Route path="/mobile-app-development-corona" element={<MobileAppCorona/>} />
          <Route path="/mobile-app-development-norco" element={<MobileAppNorco/>} />
          <Route path="/mobile-app-development-orange-county" element={<MobileAppOrange/>} />
          <Route path="/mobile-app-development-long-beach" element={<MobileAppLongBeach/>} />
          <Route path="/mobile-app-development-san-francisco" element={<MobileAppSanFran/>} />
          <Route path="/mobile-app-development-santa-barbara" element={<MobileAppSantaBarbara/>} />
          <Route path="/mobile-app-development-sacramento" element={<MobileAppSacramento/>} />
          <Route path="/mobile-app-development-palm-springs" element={<MobileAppPalmSprings/>} />
          <Route path="/mobile-app-development-san-diego" element={<MobileAppSandiego/>} />
          <Route path="/menifeedevelopers" element={<MenifeeDevelopers/>} />
          <Route path="/los-angeles-mobile-app-developer" element={<LosAngelesAppDev/>} />
          <Route path="/california-mobile-app-developer" element={<CaliforniaAppDev/>} />
          <Route path="/menifee-mobile-app-developer" element={<MenifeeAppDev/>} />
          <Route path="/murrietta-mobile-app-developer" element={<MurriettaAppDev/>} />
          <Route path="/lake-elsinore-mobile-app-developer" element={<LakeElsinoreAPpDev/>} />
          <Route path="/los-alamos-mobile-app-developer" element={<LosAlamosAppDev/>} />
          <Route path="/temecula-mobile-app-developer" element={<TemeculaAppDev/>} />
          <Route path="/wildomar-mobile-app-developer" element={<WildomarAppDev/>} />
          <Route path="/corona-mobile-app-developer" element={<CoronaAppDev/>} />
          <Route path="/norco-mobile-app-developer" element={<NorcoAppDev/>} />
          <Route path="/orange-county-mobile-app-developer" element={<OrangeAppDev/>} />
          <Route path="/long-beach-mobile-app-developer" element={<LongBeachAppDev/>} />
          <Route path="/san-francisco-mobile-app-developer" element={<SanFranAppDev/>} />
          <Route path="/san-diego-mobile-app-developer" element={<SandiegoAppDev/>} />
          <Route path="/palm-springs-mobile-app-developer" element={<PalmSpringsAppDev/>} />
          <Route path="/sacramento-mobile-app-developer" element={<SacramentoAppDev/>} />
          <Route path="/santa-barbara-mobile-app-developer" element={<SantaBarbaraAppDev/>} />
          <Route path="/alabama-mobile-app-developer" element={<AlabamaAppDev/>} />
          <Route path="/alaska-mobile-app-developer" element={<AlaskaAppDev/>} />
          <Route path="/arizona-mobile-app-developer" element={<ArizonaAppDev/>} />
          <Route path="/arkansas-mobile-app-developer" element={<ArkansasAppDev/>} />
          <Route path="/colorado-mobile-app-developer" element={<ColoradoAppDev/>} />
          <Route path="/connecticut-mobile-app-developer" element={<ConnecticutAppDev/>} />
          <Route path="/dc-mobile-app-developer" element={<DCappDev/>} />
          <Route path="/delaware-mobile-app-developer" element={<DelawareAppDev/>} />
          <Route path="/florida-mobile-app-developer" element={<FloridaAppDev/>} />
          <Route path="/georgia-mobile-app-developer" element={<GeorgiaAppDev/>} />
          <Route path="/guam-mobile-app-developer" element={<GuamAppDev/>} />
          <Route path="/hawaii-mobile-app-developer" element={<HawaiiAppDev/>} />
          <Route path="/idaho-mobile-app-developer" element={<IdahoAppDev/>} />
          <Route path="/illinois-mobile-app-developer" element={<IllinoisAppDev/>} />
          <Route path="/indiana-mobile-app-developer" element={<IndianaAppDev/>} />
          <Route path="/iowa-mobile-app-developer" element={<IowaAppDev/>} />
          <Route path="/kansas-mobile-app-developer" element={<KansasAppDev/>} />
          <Route path="/kentucky-mobile-app-developer" element={<KentuckyAppDev/>} />
          <Route path="/louisiana-mobile-app-developer" element={<LouisianaAppDev/>} />
          <Route path="/maine-mobile-app-developer" element={<MaineAppDev/>} />
          <Route path="/maryland-mobile-app-developer" element={<MarylandAppDev/>} />
          <Route path="/mass-mobile-app-developer" element={<MassAppDev/>} />
          <Route path="/michigan-mobile-app-developer" element={<MichiganAppDev/>} />
          <Route path="/minnesota-mobile-app-developer" element={<MinnesataAppDev/>} />
          <Route path="/mississippi-mobile-app-developer" element={<MississippiAppDev/>} />
          <Route path="/missouri-mobile-app-developer" element={<MissouriAppDev/>} />
          <Route path="/montana-mobile-app-developer" element={<MontanaAppDev/>} />
          <Route path="/ncarolina-mobile-app-developer" element={<NCarolinaAppDev/>} />
          <Route path="/nebraska-mobile-app-developer" element={<NebraskaAppDev/>} />
          <Route path="/nevada-mobile-app-developer" element={<NevadaAppDev/>} />
          <Route path="/new-jersey-mobile-app-developer" element={<NewJerseyAppDev/>} />
          <Route path="/new-york-mobile-app-developer" element={<NewYorkAppDev/>} />
          <Route path="/new-mexico-mobile-app-developer" element={<NewMexicoAppDev/>} />
          <Route path="/new-hampshire-mobile-app-developer" element={<NHampshireAppDev/>} />
          <Route path="/north-dakota-mobile-app-developer" element={<NorthDakotaApps/>} />
          <Route path="/ohio-mobile-app-developer" element={<OhioAppDev/>} />
          <Route path="/oklahoma-mobile-app-developer" element={<OklahomaAppDev/>} />
          <Route path="/oregon-mobile-app-developer" element={<OregonAppDev/>} />
          <Route path="/pennsylvania-mobile-app-developer" element={<PennsylvaniaAppDev/>} />
          <Route path="/puerto-rico-mobile-app-developer" element={<PuertoRicoAppDev/>} />
          <Route path="/rhode-island-mobile-app-developer" element={<RhodeIslandAppDev/>} />
          <Route path="/south-carolina-mobile-app-developer" element={<SCarolinaAppDev/>} />
          <Route path="/south-dakota-mobile-app-developer" element={<SDakotaAppDev/>} />
          <Route path="/tennesse-mobile-app-developer" element={<TennesseeAppDev/>} />
          <Route path="/texas-mobile-app-developer" element={<TexasAppDev/>} />
          <Route path="/utah-mobile-app-developer" element={<UtahAppDev/>} />
          <Route path="/vermont-mobile-app-developer" element={<VermontAppDev/>} />
          <Route path="/west-virgina-mobile-app-developer" element={<WVirginiaApp/>} />
          <Route path="winsconsin-mobile-app-developer" element={<WisconsinAppDev/>} />
          <Route path="/usadevelopers" element={<UsaDevelopers/>} />
          <Route path="/website-design-in-menifee" element={<WebsiteDesignInMenifee/>} />
          <Route path="/website-design-in-pueblo" element={<WebsiteDesignInPueblo/>} />
          <Route path="/website-design-in-fresno" element={<WebsiteDesignInFresno/>} />
          <Route path="/website-design-in-tacoma" element={<WebsiteDesignInTacoma/>} />
          <Route path="/website-design-in-boise" element={<WebsiteDesignInBoise/>} />
          <Route path="/website-design-in-seattle" element={<WebsiteDesignInSeattle/>} />
          <Route path="/website-design-in-atlanta" element={<WebsiteDesignInAtlanta/>} />
          <Route path="/website-design-in-alabama" element={<WebsiteDesignInAlabama/>} />
          <Route path="/website-design-in-alaska" element={<WebsiteDesignInAlaska/>} />
          <Route path="/website-design-in-arizona" element={<WebsiteDesignInArizona/>} />
          <Route path="/website-design-in-arkansas" element={<WebsiteDesignInArkansas/>} />
          <Route path="/website-design-in-colorado" element={<WebsiteDesignInColorado/>} />
          <Route path="/website-design-in-connecticut" element={<WebsiteDesignInConnecticut/>} />
          <Route path="/website-design-in-dc" element={<WebsiteDesignInDc/>} />
          <Route path="/website-design-in-delaware" element={<WebsiteDesignInDelaware/>} />
          <Route path="/website-design-in-florida" element={<WebsiteDesignInFlorida/>} />
          <Route path="/website-design-in-georgia" element={<WebsiteDesignInGeorgia/>} />
          <Route path="/website-design-in-guam" element={<WebsiteDesignInGuam/>} />
          <Route path="/website-design-in-hawaii" element={<WebsiteDesignInHawaii/>} />
          <Route path="/website-design-in-idaho" element={<WebsiteDesignInIdaho/>} />
          <Route path="/website-design-in-indiana" element={<WebsiteDesignInIndiana/>} />
          <Route path="/website-design-in-iowa" element={<WebsiteDesignInIowa/>} />
          <Route path="/website-design-in-kansas" element={<WebsiteDesignInKansas/>} />
          <Route path="/website-design-in-kentucky" element={<WebsiteDesignInKentucky/>} />
          <Route path="/website-design-in-louisiana" element={<WebsiteDesignInLouisiana/>} />
          <Route path="/website-design-in-maine" element={<WebsiteDesignInMaine/>} />
          <Route path="/website-design-in-virginia" element={<WebsiteDesignInVirginia/>} />
          <Route path="/website-design-in-maryland" element={<WebsiteDesignInMaryland/>} />
          <Route path="/website-design-in-mass" element={<WebsiteDesignInMass/>} />
          <Route path="/website-design-in-michigan" element={<WebsiteDesignInMichigan/>} />
          <Route path="/website-design-in-minnesota" element={<WebsiteDesignInMinnesota/>} />
          <Route path="/website-design-in-mississippi" element={<WebsiteDesignInMississippi/>} />
          <Route path="/website-design-in-missouri" element={<WebsiteDesignInMissouri/>} />
          <Route path="/website-design-in-montana" element={<WebsiteDesignInMontana/>} />
          <Route path="/website-design-in-nebraska" element={<WebsiteDesignInNebraska/>} />
          <Route path="/website-design-in-new-hampshire" element={<WebsiteDesignInNewHampshire/>} />
          <Route path="/website-design-in-new-jersey" element={<WebsiteDesignInNewJersey/>} />
          <Route path="/website-design-in-new-mexico" element={<WebsiteDesignInNewMexico/>} />
          <Route path="/website-design-in-new-york" element={<WebsiteDesignInNewYork/>} />
          <Route path="/website-design-in-ncarolina" element={<WebsiteDesignInNcarolina/>} />
          <Route path="/website-design-in-north-dakota" element={<WebsiteDesignInNorthDakota/>} />
          <Route path="/website-design-in-ohio" element={<WebsiteDesignInOhio/>} />
          <Route path="/website-design-in-oklahoma" element={<WebsiteDesignInOklahoma/>} />
          <Route path="/website-design-in-nevada" element={<WebsiteDesignInNevada/>} />
          <Route path="/website-design-in-oregon" element={<WebsiteDesignInOregon/>} />
          <Route path="/website-design-in-pennsylvania" element={<WebsiteDesignInPennsylvania/>} />
          <Route path="/website-design-in-puerto-rico" element={<WebsiteDesignInPuertoRico/>} />
          <Route path="/website-design-in-rhode-island" element={<WebsiteDesignInRhodeIsland/>} />
          <Route path="/website-design-in-south-carolina" element={<WebsiteDesignInSouthCarolina/>} />
          <Route path="/website-design-in-south-dakota" element={<WebsiteDesignInSouthDakota/>} />
          <Route path="/website-design-in-tennesse" element={<WebsiteDesignInTennesse/>} />
          <Route path="/website-design-in-texas" element={<WebsiteDesignInTexas/>} />
          <Route path="/website-design-in-utah" element={<WebsiteDesignInUtah/>} />
          <Route path="/website-design-in-vermont" element={<WebsiteDesignInVermont/>} />
          <Route path="/website-design-in-west-virgina" element={<WebsiteDesignInWestVirgina/>} />
          <Route path="/website-design-in-wyoming" element={<WebsiteDesignInWyoming/>} />
          <Route path="/website-design-in-agoura-hills" element={<WebsiteDesignInAgouraHills/>} />
          <Route path="/website-design-in-alhambra" element={<WebsiteDesignInAlhambra/>} />
          <Route path="/website-design-in-arcadia" element={<WebsiteDesignInArcadia/>} />
          <Route path="/website-design-in-artesia" element={<WebsiteDesignInArtesia/>} />
          <Route path="/website-design-in-avalon" element={<WebsiteDesignInAvalon/>} />
          <Route path="/website-design-in-azusa" element={<WebsiteDesignInAzusa/>} />
          <Route path="/website-design-in-baldwin-park" element={<WebsiteDesignInBaldwinPark/>} />
          <Route path="/website-design-in-bell" element={<WebsiteDesignInBell/>} />
          <Route path="/website-design-in-bell-gardens" element={<WebsiteDesignInBellGardens/>} />
          <Route path="/website-design-in-bellflower" element={<WebsiteDesignInBellflower/>} />
          <Route path="/website-design-in-beverly-hills" element={<WebsiteDesignInBeverlyHills/>} />
          <Route path="/website-design-in-hollywood" element={<WebsiteDesignInHollywood/>} />
          <Route path="/website-design-in-bradbury" element={<WebsiteDesignInBradbury/>} />
          <Route path="/website-design-in-burbank" element={<WebsiteDesignInBurbank/>} />
          <Route path="/website-design-in-calabasas" element={<WebsiteDesignInCalabasas/>} />
          <Route path="/website-design-in-carson" element={<WebsiteDesignInCarson/>} />
          <Route path="/website-design-in-crenshaw" element={<WebsiteDesignInCrenshaw/>} />
          <Route path="/website-design-in-cerritos" element={<WebsiteDesignInCerritos/>} />
          <Route path="/website-design-in-claremont" element={<WebsiteDesignInClaremont/>} />
          <Route path="/website-design-in-commerce" element={<WebsiteDesignInCommerce/>} />
          <Route path="/website-design-in-compton" element={<WebsiteDesignInCompton/>} />
          <Route path="/website-design-in-covina" element={<WebsiteDesignInCovina/>} />
          <Route path="/website-design-in-cudahy" element={<WebsiteDesignInCudahy/>} />
          <Route path="/website-design-in-culver-city" element={<WebsiteDesignInCulverCity/>} />
          <Route path="/website-design-in-diamond-bar" element={<WebsiteDesignInDiamondBar/>} />
          <Route path="/website-design-in-downey" element={<WebsiteDesignInDowney/>} />
          <Route path="/website-design-in-duarte" element={<WebsiteDesignInDuarte/>} />
          <Route path="/website-design-in-el-monte" element={<WebsiteDesignInElMonte/>} />
          <Route path="/website-design-in-el-segundo" element={<WebsiteDesignInElSegundo/>} />
          <Route path="/website-design-in-gardena" element={<WebsiteDesignInGardena/>} />
          <Route path="/website-design-in-glendora" element={<WebsiteDesignInGlendora/>} />
          <Route path="/website-design-in-glendale" element={<WebsiteDesignInGlendale/>} />
          <Route path="/website-design-in-hawaiian-gardens" element={<WebsiteDesignInHawaiianGardens/>} />
          <Route path="/website-design-in-hawthorne" element={<WebsiteDesignInHawthorne/>} />
          <Route path="/website-design-in-hermosa-beach" element={<WebsiteDesignInHermosaBeach/>} />
          <Route path="/website-design-in-hidden-hills" element={<WebsiteDesignInHiddenHills/>} />
          <Route path="/website-design-in-huntington-park" element={<WebsiteDesignInHuntingtonPark/>} />
          <Route path="/website-design-in-industry" element={<WebsiteDesignInIndustry/>} />
          <Route path="/website-design-in-inglewood" element={<WebsiteDesignInInglewood/>} />
          <Route path="/website-design-in-irwindale" element={<WebsiteDesignInIrwindale/>} />
          <Route path="/website-design-in-la-cañada-flintridge" element={<WebsiteDesignInLaCañadaFlintridge/>} />
          <Route path="/website-design-in-la-habra-heights" element={<WebsiteDesignInLaHabraHeights/>} />
          <Route path="/website-design-in-la-mirada" element={<WebsiteDesignInLaMirada/>} />
          <Route path="/website-design-in-la-puente" element={<WebsiteDesignInLaPuente/>} />
          <Route path="/website-design-in-la-verne" element={<WebsiteDesignInLaVerne/>} />
          <Route path="/website-design-in-lakewood" element={<WebsiteDesignInLakewood/>} />
          <Route path="/website-design-in-lancaster" element={<WebsiteDesignInLancaster/>} />
          <Route path="/website-design-in-lawndale" element={<WebsiteDesignInLawndale/>} />
          <Route path="/website-design-in-lomita" element={<WebsiteDesignInLomita/>} />
          <Route path="/website-design-in-long-beach" element={<WebsiteDesignInLongBeach/>} />
          <Route path="/website-design-in-los-angeles" element={<WebsiteDesignInLosAngeles/>} />
          <Route path="/website-design-in-lynwood" element={<WebsiteDesignInLynwood/>} />
          <Route path="/website-design-in-malibu" element={<WebsiteDesignInMalibu/>} />
          <Route path="/website-design-in-manhattan-beach" element={<WebsiteDesignInManhattanBeach/>} />
          <Route path="/website-design-in-maywood" element={<WebsiteDesignInMaywood/>} />
          <Route path="/website-design-in-monrovia" element={<WebsiteDesignInMonrovia/>} />
          <Route path="/website-design-in-montebello" element={<WebsiteDesignInMontebello/>} />
          <Route path="/website-design-in-monterey-park" element={<WebsiteDesignInMontereyPark/>} />
          <Route path="/website-design-in-norwalk" element={<WebsiteDesignInNorwalk/>} />
          <Route path="/website-design-in-palmdale" element={<WebsiteDesignInPalmdale/>} />
          <Route path="/website-design-in-palos-verdes-estates" element={<WebsiteDesignInPalosVerdesEstates/>} />
          <Route path="/website-design-in-paramount" element={<WebsiteDesignInParamount/>} />
          <Route path="/website-design-in-pasadena" element={<WebsiteDesignInPasadena/>} />
          <Route path="/website-design-in-pico-rivera" element={<WebsiteDesignInPicoRivera/>} />
          <Route path="/website-design-in-pomona" element={<WebsiteDesignInPomona/>} />
          <Route path="/website-design-in-rancho-palos-verdes" element={<WebsiteDesignInRanchoPalosVerdes/>} />
          <Route path="/website-design-in-redondo-beach" element={<WebsiteDesignInRedondoBeach/>} />
          <Route path="/website-design-in-rolling-hills" element={<WebsiteDesignInRollingHills/>} />
          <Route path="/website-design-in-rolling-hills-estates" element={<WebsiteDesignInRollingHillsEstates/>} />
          <Route path="/website-design-in-rosemead" element={<WebsiteDesignInRosemead/>} />
          <Route path="/website-design-in-san-dimas" element={<WebsiteDesignInSanDimas/>} />
          <Route path="/website-design-in-san-fernando" element={<WebsiteDesignInSanFernando/>} />
          <Route path="/website-design-in-san-gabriel" element={<WebsiteDesignInSanGabriel/>} />
          <Route path="/website-design-in-san-marino" element={<WebsiteDesignInSanMarino/>} />
          <Route path="/website-design-in-santa-clarita" element={<WebsiteDesignInSantaClarita/>} />
          <Route path="/website-design-in-santa-fe-springs" element={<WebsiteDesignInSantaFeSprings/>} />
          <Route path="/website-design-in-santa-monica" element={<WebsiteDesignInSantaMonica/>} />
          <Route path="/website-design-in-sierra-madre" element={<WebsiteDesignInSierraMadre/>} />
          <Route path="/website-design-in-signal-hill" element={<WebsiteDesignInSignalHill/>} />
          <Route path="/website-design-in-south-el-monte" element={<WebsiteDesignInSouthElMonte/>} />
          <Route path="/website-design-in-south-gate" element={<WebsiteDesignInSouthGate/>} />
          <Route path="/website-design-in-south-pasadena" element={<WebsiteDesignInSouthPasadena/>} />
          <Route path="/website-design-in-temple-city" element={<WebsiteDesignInTempleCity/>} />
          <Route path="/website-design-in-torrance" element={<WebsiteDesignInTorrance/>} />
          <Route path="/website-design-in-vernon" element={<WebsiteDesignInVernon/>} />
          <Route path="/website-design-in-walnut" element={<WebsiteDesignInWalnut/>} />
          <Route path="/website-design-in-west-covina" element={<WebsiteDesignInWestCovina/>} />
          <Route path="/website-design-in-west-hollywood" element={<WebsiteDesignInWestHollywood/>} />
          <Route path="/website-design-in-westlake-village" element={<WebsiteDesignInWestlakeVillage/>} />
          <Route path="/website-design-in-whittier" element={<WebsiteDesignInWhittier/>} />
          <Route path="/website-design-in-east-los-angeles" element={<WebsiteDesignInEastLosAngeles/>} />
          <Route path="/website-design-in-ninetynine-oaks" element={<WebsiteDesignInNinetynineOaks/>} />
          <Route path="/website-design-in-avocado-heights" element={<WebsiteDesignInAvocadoHeights/>} />
          <Route path="/website-design-in-south-san-gabriel" element={<WebsiteDesignInSouthSanGabriel/>} />
          <Route path="/website-design-in-marina-del-rey" element={<WebsiteDesignInMarinaDelRey/>} />
          <Route path="/website-design-in-quartz-hill" element={<WebsiteDesignInQuartzHill/>} />
          <Route path="/website-design-in-mayflower-village" element={<WebsiteDesignInMayflowerVillage/>} />
          <Route path="/website-design-in-lake-los-angeles" element={<WebsiteDesignInLakeLosAngeles/>} />
          <Route path="/website-design-in-topanga" element={<WebsiteDesignInTopanga/>} />
          <Route path="/website-design-in-walnut-park" element={<WebsiteDesignInWalnutPark/>} />
          <Route path="/website-design-in-rowland-heights" element={<WebsiteDesignInRowlandHeights/>} />
          <Route path="/website-design-in-city-of-industry" element={<WebsiteDesignInCityOfIndustry/>} />
          <Route path="/website-design-in-charter-oak" element={<WebsiteDesignInCharterOak/>} />
          <Route path="/website-design-in-ie" element={<WebsiteDesignInIe/>} />
          <Route path="/website-design-in-corona" element={<WebsiteDesignInCorona/>} />
          <Route path="/website-design-in-lake-elsinore" element={<WebsiteDesignInLakeElsinore/>} />
          <Route path="/website-design-in-los-alamos" element={<WebsiteDesignInLosAlamos/>} />
          <Route path="/website-design-in-norco" element={<WebsiteDesignInNorco/>} />
          <Route path="/website-design-in-perris" element={<WebsiteDesignInPerris/>} />
          <Route path="/website-design-in-sacramento" element={<WebsiteDesignInSacramento/>} />
          <Route path="/website-design-in-palm-springs" element={<WebsiteDesignInPalmSprings/>} />
          <Route path="/website-design-in-san-diego" element={<WebsiteDesignInSanDiego/>} />
          <Route path="/website-design-in-wildomar" element={<WebsiteDesignInWildomar/>} />
          <Route path="/website-design-in-temecula" element={<WebsiteDesignInTemecula/>} />
          <Route path="/website-design-in-escondido" element={<WebsiteDesignInEscondido/>} />
          <Route path="/website-design-in-oceans-side" element={<WebsiteDesignInOceansSide/>} />
          <Route path="/website-design-in-fallbrook" element={<WebsiteDesignInFallbrook/>} />
          <Route path="/website-design-in-french-valley" element={<WebsiteDesignInFrenchValley/>} />
          <Route path="/website-design-in-clinton-keith" element={<WebsiteDesignInClintonKeith/>} />
          <Route path="/website-design-in-rancho-cucamonga" element={<WebsiteDesignInRanchoCucamonga/>} />
          <Route path="/website-design-in-santa-barbara" element={<WebsiteDesignInSantaBarbara/>} />
          <Route path="/website-design-in-chino-hills" element={<WebsiteDesignInChinoHills/>} />
          <Route path="/website-design-in-rancho-california" element={<WebsiteDesignInRanchoCalifornia/>} />
          <Route path="/website-design-in-rancho-mirage" element={<WebsiteDesignInRanchoMirage/>} />
          <Route path="/website-design-in-chino" element={<WebsiteDesignInChino/>} />
          <Route path="/website-design-in-san-bernadino" element={<WebsiteDesignInSanBernadino/>} />
          <Route path="/website-design-in-san-jacinto" element={<WebsiteDesignInSanJacinto/>} />
          <Route path="/website-design-in-fontana" element={<WebsiteDesignInFontana/>} />
          <Route path="/website-design-in-palm-desert" element={<WebsiteDesignInPalmDesert/>} />
          <Route path="/website-design-in-jurupa-valley" element={<WebsiteDesignInJurupaValley/>} />
          <Route path="/website-design-in-la-quinta" element={<WebsiteDesignInLaQuinta/>} />
          <Route path="/website-design-in-moreno-valley" element={<WebsiteDesignInMorenoValley/>} />
          <Route path="/website-design-in-eastvale" element={<WebsiteDesignInEastvale/>} />
          <Route path="/website-design-in-indio" element={<WebsiteDesignInIndio/>} />
          <Route path="/website-design-in-desert-hot-springs" element={<WebsiteDesignInDesertHotSprings/>} />
          <Route path="/website-design-in-coachella" element={<WebsiteDesignInCoachella/>} />
          <Route path="/website-design-in-calimesa" element={<WebsiteDesignInCalimesa/>} />
          <Route path="/website-design-in-blythe" element={<WebsiteDesignInBlythe/>} />
          <Route path="/website-design-in-beaumont" element={<WebsiteDesignInBeaumont/>} />
          <Route path="/website-design-in-banning" element={<WebsiteDesignInBanning/>} />
          <Route path="/website-design-in-adelanto" element={<WebsiteDesignInAdelanto/>} />
          <Route path="/website-design-in-colton" element={<WebsiteDesignInColton/>} />
          <Route path="/website-design-in-loma-linda" element={<WebsiteDesignInLomaLinda/>} />
          <Route path="/website-design-in-victorville" element={<WebsiteDesignInVictorville/>} />
          <Route path="/website-design-in-ontario" element={<WebsiteDesignInOntario/>} />
          <Route path="/website-design-in-joshua-tree" element={<WebsiteDesignInJoshuaTree/>} />
          <Route path="/website-design-in-big-bear-lake" element={<WebsiteDesignInBigBearLake/>} />
          <Route path="/website-design-in-apple-valley" element={<WebsiteDesignInAppleValley/>} />
          <Route path="/website-design-in-rialto" element={<WebsiteDesignInRialto/>} />
          <Route path="/website-design-in-redlands" element={<WebsiteDesignInRedlands/>} />
          <Route path="/website-design-in-barstow" element={<WebsiteDesignInBarstow/>} />
          <Route path="/website-design-in-bloomington" element={<WebsiteDesignInBloomington/>} />
          <Route path="/website-design-in-lake-arrowhead" element={<WebsiteDesignInLakeArrowhead/>} />
          <Route path="/website-design-in-menifee" element={<WebsiteDesignInMenifee/>} />
          <Route path="/website-design-in-california" element={<WebsiteDesignInCalifornia/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/legal" element={<Legal/>} />
          <Route path="/privacy" element={<PrivacyPolicy/>} />
          <Route path="/refund" element={<ReturnPolicy/>} />
          <Route path="/returnpolicy" element={<ReturnPolicy/>} />
          <Route path="/termsandconditions" element={<TermsAndConditions/>} />
          <Route path="/press" element={<Press/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/sitemaps" element={<Sitemaps />} />
          <Route path="/moreindustrylist" element={<MoreIndustryList />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>

  );
}

export default App;
