export default [
    {   
        id:'1',
        package_name:'Basic Package',
        price:'$1,250.00',
        features:[
            '3 Page Website',
            '3 stock photo',
            'Contact/Query Form',
            '1yr SSL',
            'Free Domain Name',
            'Free 1yr Hosting',
            'Mobile Responsive',
            'Complete Deployment',
            '100% Satisfaction Guarantee',
            '100% Unique Design Guarantee',
            '100% Money Back Guarantee *',
        ],
    },
    {   
        id:'2',
        package_name:'Standard Package',
        price:'$1,750',
        features:[
            '8 Page Website',
            '8 Stock Photos',
            'Contact Query Form',
            'FREE Google Sitemap',
            '1yr SSL',
            'Free Domain Name',
            'Free 1yr Hosting',
            'Mobile Responsive',
            'Complete Deployment',
            'Mobile Responsive',
            '100% Satisfaction Guarantee',
            '100% Unique Design Guarantee',
            '100% Money Back Guarantee *',
        ],
    },
    {   
        id:'3',
        package_name:'Elite Package',
        price:'$2,250',
        features:[
            '10 Unique Pages Website',
            'Admin Panel Support',
            'Mobile Responsive',
            'Free Google Sitemap',
            'C M S',
            '1yr SSL',
            'Free Domain Name',
            'Free 1yr Hosting',
            'Codebase Ownership',
            'Online Payment Integration',
            'Appointment Tool',
            'FREE Google Friendly Sitemap',
            'Complete Deployment',
            '100% Satisfaction Guarantee',
            '100% Unique Design Guarantee',
            '100% Money Back Guarantee *',
        ],
    },
    {   
        id:'4',
        package_name:'eCommerce Package',
        price:'$4,250.00',
        features:[
            'Unlimited Web Pages',
            'Admin Panel Support',
            'UX | UI',
            'Performance and analytics',
            'Integrations',
            'Complete Deployment',
            'Trend Analytics',
            '1yr SSL',
            'Free Domain Name',
            'Free 1yr Hosting',
            'Analytics and Dashboards',
            'Invoice templates',
            'Mobile Notifications',
            'FREE Google Friendly Sitemap',
            'User Signup/Login Functionalities',
            '100% Ownership Rights',
            '100% Satisfaction Guarantee',
            '100% Unique Design Guarantee',
            'Free 1yr Technical Support',
            '100% Money Back Guarantee *',
        ],
    },
    {   
        id:'5',
        package_name:'Web Application Package',
        price:'$8,750',
        features:[
            'Unlimited Web Pages',
            'UI | UX',
            'API integrations',
            'Google Analytics integration',
            'Mobile Responsive Design',
            'Image Optimization',
            'Site Optimisation',
            '1yr SSL',
            'Free Domain Name',
            'Free 1yr Hosting',
            'Search Bar',
            'Chat bot and chat widgets',
            'Complete Deployment',
            '100% Ownership Rights',
            '100% Satisfaction Guarantee',
            '100% Unique Design Guarantee',
            'Free 1yr Technical Support',
            '100% Money Back Guarantee *',
        ]
    },
    {   
        id:'6',
        package_name:'Social Media Package',
        price:'$16,599',
        features:[
            'Unlimited Web Pages',
            'Online Integration',
            '15 Seconds Explainer Video',
            'Voice Over & Sound Effects',
            'Script Writing',
            'Story Board',
            '1yr SSL',
            'Free Domain Name',
            'Free 1yr Hosting',
            'SEO Meta Tags',
            'Online Integration',
            'Custom Dynamic Forms',
            'Admin Profile Management',
            'User Profile Management',
            'Analytics',
            'Third Party Integration',
            'Easy Search',
            'Push Notification',
            'User Profile Management',
            'Signup Area(Newsletter, Offers)',
            'Content Sharing',
            'Privacy and Security',
            'Complete Deployment',
            'Content Management System(CMS)',
            'Free 1yr Technical Support',
            '100% Ownership Rights',
            'Free Platform Designs',
            '100% Money Back Guarantee *',
        ],
    },

];