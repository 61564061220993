import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {FaChalkboardTeacher } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import Reviews from './Reviews';
import FooterForm from './Footer/FooterForm';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import { Link } from 'react-router-dom';

const AndroidApp =() =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name='keyword ' content='data management, analytics , software development ,data quality, data access, data security'/>
                <meta name='description' 
                    content="Transform your Android app ideas into reality with our expert development services.
                     At Global Mclien Software Solutions, we specialize in crafting innovative 
                     and user-friendly apps that captivate audiences and drive results. From concept to launch, 
                     our experienced team delivers seamless functionality and stunning design, tailored to your
                      specific requirements. Whether you're a startup or a large enterprise, we have the expertise
                       to create custom Android apps that stand out on the Google Play Store. Partner with us to 
                       elevate your Android presence and engage with your target audience effectively. 
                       Contact us today to start building your next successful Android app!"
                    />
                <title>Global Mclien Software Solutions - Android Application Development Company</title>
                <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/androidapp" />
            </Helmet>
            <div className='component_container' >
                <div className="hero_container datapage">
                    <div className="hero_message">
                        <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                                <h1 className="hero_message_h1">Your Premier Android Development Partner</h1>
                                <ul className="hero_message_ul">
                                    <li>Full App Dev</li>
                                    <li>Unit Testing</li>
                                    <li>Android Game development</li>
                                    <li>SSO Authentication</li>
                                    <li>OTP authentication</li>
                                    <li>UI | UX </li>
                                </ul>
                                <p className="hero_message_p">
                                    At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>  
                                    we specialize in crafting high-quality Android applications that empower businesses, 
                                    engage users, and drive success in the digital realm. 
                                    <Link className="link-builder" to="/menifeedevelopers" > With a team of experienced Android developers, </Link>
                                    designers, and strategists, we're dedicated to delivering innovative solutions that exceed expectations 
                                    and elevate your brand presence on the Google Play Store.
                                </p>
                                <div>
                                  <a className="hero_message_button" href="#content_form">Get Started</a>
                                </div>
                            </div>
                            <div className="hero_message_image">
                                <img src="/images/mobileprocessone.png" alt="Global mclien gear on a screen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="getting_started">
                    <div className="gts_header">
                        <div></div>
                        <h3>Getting Started </h3>
                        <div></div>
                    </div>
                    <div className="get_startedcontainer">
                        <div className="getting_starteditem">
                            <IoChatbubblesOutline />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 1</p>
                                <p className="getstartedP">Contact us</p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                          <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <FaChalkboardTeacher />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 2</p>
                                <p className="getstartedP">Free <a href="/websites" className="backlinks"> App Consultation</a></p>
                            </div>
                        </div>
                        <div className="gts_arrow">
                            <p> &#62;</p>
                        </div>
                        <div className="getting_starteditem">
                            <TfiWrite />
                            <div className="getstarted_item">
                                <p className="getstarted_P">Step 3</p>
                                <p className="getstartedP">Develop &amp; Email Proposal</p>
                            </div>
                        </div>
                    </div>
                    <div className="getin_touch">
                        <div className="getin_touchheader">
                            <span className='container_span bigspan'>Call Us </span> 
                            <span>or</span>
                            <h3>Have us Call You</h3>
                        </div>
                        <div className="getin_touchform">
                            <div className="getintouchaddress">
                                <h4>Office Locations</h4>
                                <ul>
                                    <li>Menifee, CA</li>
                                    <li>New York, NY</li>
                                    <li>Chicago, IL</li>
                                    <li>Lagos,Nigeria</li>
                                </ul>
                                <div className="getintouchdivider">
                                    <h4>Contact Us</h4>
                                    <div title='click to call' className="getintouchdivider_div">
                                        <BsTelephone />
                                        <a href="tel:+7472498760">747.249.8760</a>
                                    </div>
                                    <div title='click to send email' className="getintouchdivider_div">
                                        <IoMailOutline />
                                        <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                            info@globalmclienSS.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="getin_touchformelement">
                                <EmailJsComponent callToAction='Get In Touch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_header">
                    <p className="sub_paragraph">
                        At <Link className="link-builder" to="/">Global Mclien Software Solutions, </Link>Mobile creativity meets <span className="container_span">innovation.</span>
                    </p>
                    <div>
                        <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                    </div>
                    <div className="sub_features">
                        <div className="sub_features_item">
                            <img src="/images/presentation.png" alt="presentation"  />
                            <h5>Exceptional Flexibility and Scalability</h5>
                            <p>
                                We're a custom Android development company that adjusts to the scale of the project and is 
                                ready to attract an even greater number of highly skilled experts if necessary for the 
                                successful implementation of the project on schedule.
                            </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/delivery.png" alt="marketing"  />
                            <h5>Best Security Practices</h5>
                            <p>
                               Keep calm and don't woryy about data, system and infrastructure protection:we adhere to strict 
                               protection policy rules inherent in Android development outsourcing cooperation and constatntly update 
                               security systems in accordance with the latest innovations
                        </p>
                        </div>
                        <div className="sub_features_item">
                            <img src="/images/customer.png" alt="presentation"  />
                            <h5>Customized Full-cycle Android Development</h5>
                            <p>
                                Our Android development agency creates Androids carefully tailored to your specific needs and budget 
                                expectations.
                                Experienced Android development <Link className="link-builder" to="/about"> custom-code developers  </Link>with a customised approach to your project.
                            </p>
                        </div>
                    </div>
                 </div>
                <div className="data-objectioncontainer">
                    <div className="data-objections">
                        <h2>
                            Expertise:
                        </h2>
                        <p>
                            Our team comprises seasoned <Link className="link-builder" to="/iosapp" >Android  </Link>developers
                             with years of experience in building robust, scalable, and feature-rich 
                            applications for the Android platform.
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Innovation:
                        </h2>
                        <p>
                            We leverage the latest Android technologies, tools, and design principles to create 
                            innovative and immersive 
                            applications that captivate users and differentiate your brand in the competitive app market.         
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Collaborative Approach:
                        </h2>
                        <p>
                            We believe in collaborative partnerships and transparent communication throughout the 
                            development process.
                             From initial concept to final delivery, we work closely with our clients to ensure their 
                             vision is brought to 
                             life effectively.
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Quality Assurance: 
                        </h2>
                        <p>
                           Quality is non-negotiable at <a href="/home" className="backlinks"> Global Mclien Software Solutions </a>. 
                           Our rigorous testing procedures and attention to detail guarantee that every Android app we
                            deliver is polished, 
                           bug-free, and optimized for performance across various devices and screen sizes.       
                        </p>
                    </div>
                    <div className="data-objections">
                        <h2>
                            Customer Satisfaction:
                        </h2>
                        <p>
                        Our dedication to customer satisfaction sets us apart. We prioritize responsiveness, reliability,
                         and post-launch support to ensure our clients are delighted with the final product and achieve 
                         their business objectives.                      
                        </p>
                    </div>
                </div>
                <Reviews />
                <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    Android development sounds expensive
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                     While quality Android development comes at a price, we offer competitive rates without
                                      compromising on quality. Plus, investing in a well-built Android app yields
                                       significant returns in terms of user engagement and business growth.
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    How long will it take to develop my Android app?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                Timelines vary based on project complexity, but we're committed to delivering high-quality 
                                Android apps within agreed-upon deadlines. Our streamlined development process ensures 
                                efficient project execution without sacrificing quality.                             
                                   </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                     Will my Android app work consistently across different devices and OS versions?"
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                    Our <Link className="link-builder" to="/usadevelopers" >Android developers  </Link>are well-versed in addressing device fragmentation challenges. 
                                    We follow best practices and conduct extensive testing to ensure your app functions 
                                    seamlessly across a wide range of devices, screen sizes, and Android OS versions.           
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    How can I ensure my Android app gets noticed on the Google Play Store?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                    We offer app store optimization (ASO) services to enhance your app's visibility and 
                                    discoverability
                                    on the Google Play Store. Additionally, our team can provide strategic guidance on 
                                    marketing and
                                    promotion to maximize your app's reach and downloads.                            
                                </p>
                            </div>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    What happens after my Android app is launched?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                            <div>
                                <p>
                                    Our commitment to client satisfaction extends beyond launch day.
                                    We provide ongoing maintenance, updates, and technical support to ensure your Android
                                     app remains secure, 
                                    functional, and aligned with evolving user expectations.
                                </p>
                            </div>
                            }
                        </div>
                     </div>
                </div>
                <FooterForm field="Get started"/>
            </div>
        </>
    )
}

export default React.memo(AndroidApp);