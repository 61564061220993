import React , {useState} from 'react';
import {IoAppsSharp, IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import {FaChalkboardTeacher ,FaReact,FaGamepad } from "react-icons/fa";
import { TfiWrite, TfiVideoClapper } from "react-icons/tfi";
import { SiHiveBlockchain , SiFlutter} from "react-icons/si";
import {BsSmartwatch,BsTelephone ,BsArrowDownRight, BsApple } from "react-icons/bs";
import { FcAndroidOs,FcTodoList , FcRadarPlot , FcCollect ,FcDeployment ,FcOk, FcSupport} from "react-icons/fc";
import { Helmet } from 'react-helmet';
import { EmailJsComponent } from '../utility/EmailJsComponent';
import Reviews from './Reviews';
import FooterForm from './Footer/FooterForm';
import { Link } from 'react-router-dom';

const MobileApplication = () =>{
    const[showFaqParagraph, setShowFaqParagraph] = useState(0);
    const[showProcess, setShowProcess] = useState('scope');

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name='keyword ' content='custom code, scalability , software development ,desktop applications, software consultation,software'/>
            <meta name='description' 
                content='We Develop custom Mobile Applications with features on a variety of Technology Platforms.
                Assisst your clients scale their business by offering your market-ready Mobile App solution.
                high engaging UI|UX , Cross platform on android and iOS store.Contact Us for your next App development!'
                />
            <title>Global Mclien Software Solutions - Mobile App Development | iOS | Android</title>
            <link rel="canonical" href="https://www.globalmcliensoftwaresolutions/mobileapp" />
        </Helmet>
        <div className='component_container'>
            <div className="hero_container mobileappPage">
                <div className="hero_message">
                    <div className="hero_message_content">
                            <div className="hero_message_topdiv">
                            <h1 className="hero_message_h1">A Better Way to Build Mobile Application</h1>
                            <ul className="hero_message_ul">
                                <li>iOS App Development</li>
                                <li>Android App Development</li>
                                <li>Cross Platform | Hybrid App Development</li>
                                <li>React-Native App Development</li>
                                <li>Flutter App Development</li>
                            </ul>
                            <p className="hero_message_p">
                                Launch your Business Ideas and concept 3X faster and cost effective with 
                                our cross platform <Link className="link-builder" to="/mobileapp">mobile app </Link>development approach.
                            </p>
                            <div>
                               <a className="hero_message_button" href="#content_form">Scope your project</a>
                            </div>
                        </div>
                        <div className="hero_message_image">
                            <img src="/images/mobilenobg.png" alt="Global mclien gear on a screen" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="getting_started">
                <div className="gts_header">
                    <div></div>
                    <h3>Getting Started </h3>
                    <div></div>
                </div>
                <div className="get_startedcontainer">
                    <div className="getting_starteditem">
                        <IoChatbubblesOutline />
                        <div className="getstarted_item">
                            <p className="getstarted_P">Step 1</p>
                            <p className="getstartedP">Contact us</p>
                        </div>
                    </div>
                    <div className="gts_arrow">
                      <p> &#62;</p>
                    </div>
                    <div className="getting_starteditem">
                        <FaChalkboardTeacher />
                        <div className="getstarted_item">
                            <p className="getstarted_P">Step 2</p>
                            <p className="getstartedP">Free Idea and Concept Consultation</p>
                        </div>
                    </div>
                    <div className="gts_arrow">
                        <p> &#62;</p>
                    </div>
                    <div className="getting_starteditem">
                        <TfiWrite />
                        <div className="getstarted_item">
                            <p className="getstarted_P">Step 3</p>
                            <p className="getstartedP">Develop &amp; Email Proposal</p>
                        </div>
                    </div>
                </div>
                <div className="getin_touch">
                    <div className="getin_touchheader">
                        <span className='container_span bigspan'>Call Us </span> 
                        <span>or</span>
                        <h3>Have us Call You</h3>
                    </div>
                    <div className="getin_touchform">
                        <div className="getintouchaddress">
                            <h4>Office Locations</h4>
                            <ul>
                                <li>Menifee, CA</li>
                                <li>New York, NY</li>
                                <li>Chicago, IL</li>
                                <li>Lagos,Nigeria</li>
                            </ul>
                            <div className="getintouchdivider">
                                <h4>Contact Us</h4>
                                <div title='click to call' className="getintouchdivider_div">
                                    <BsTelephone />
                                    <a href="tel:+7472498760">747.249.8760</a>
                                </div>
                                <div title='click to send email' className="getintouchdivider_div">
                                    <IoMailOutline />
                                    <a href="mailto:info@globalmcliensoftwaresolutions.com">
                                        info@globalmclienSS.com
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="getin_touchformelement">
                            <EmailJsComponent callToAction='Get In Touch'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="new_objection">
                <div className="new_objectionitem">
                    <div className="new_objectionelement">
                        <img src="/images/mobileapptwo.png" alt="Global Mclien Software Solutions mobile app developer" />
                    </div>
                    <div className="new_objectionelement secondside">
                        <h3>Most Reliable <span className='container_span bigspan'>
                            <a href="/mobileapp" className="backlinks">App Developers</a> </span>in USA.
                        </h3>
                        <p>
                            Our team of <a href="https://www.softwaredevbytes.com/mobileapp/" className="backlinks">Award-winning Developers </a> 
                            Award-winning Developers, Designers and Project Managers 
                            <a href="https://www.softwaredevbytes.com/mobileapp/" className="backlinks">build Apps</a>
                             of tomorrow that are designed for <a href="/software" className="backlinks">Great User experience (UX) design</a>.
                              We keep our customers' vision, feedback and ideas at the centre of the development process by tracking
                              App performance & optimisation
                        </p>
                        <div>
                            <a className="hero_message_button" href="tel:+7472498760">Call Us</a>
                            <a className="hero_message_button" href="#content_form">Scope your Project</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="whatwedo_container">
                    <div className="whatwedo_containerdiv">
                        <h3>A California Mobile Application development agency</h3>
                        <p>Providing mobile app <a href="/website" className="backlinks">development </a>services in 
                        <Link className="link-builder" to="/usadevelopers" > California </Link>to help you move your app from concept to completion.</p>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div>
                                <BsApple />
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>iOS App Development</h4>
                                <p>
                                <Link className="link-builder" to="/iosapp" >iOS app development by </Link> 
                                    <Link className="link-builder" to="/">Global Mclien Software Solutions & </Link> 
                                    <a href="https://www.softwaredevbytes.com/mobileapp/" className="backlinks">
                                        Software Dev Bytes  
                                    </a> 
                                    involves the creation of <Link className="link-builder" to="/"></Link>mobile applications 
                                    for <Link className="link-builder" to="/iosapp">Apple's iOS </Link>operating system.
                                    Our developers use various programming languages, such as Swift, 
                                    <Link className="link-builder" to="/xamarinapp">Xamarin, </Link> 
                                    <Link className="link-builder" to="/javapp">JAVA, </Link>  
                                    Objective-C, to build <Link className="link-builder" to="/customwebsites">custom apps </Link>for clients. 
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <FcAndroidOs/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Android App Development</h4>
                                <p>
                                <Link className="link-builder" to="/androidapp" >Android app development </Link>is the process of creating applications for mobile devices that run on the 
                                    android operating system.This involves designing and <Link className="link-builder" to="/software" >coding the app, </Link>testing it for functionality and usability, 
                                    and launching it to market.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <IoAppsSharp/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Roku App Development</h4>
                                <p>
                                    Roku App Development services typically involves designing <Link className="link-builder" to="/customwebsites">custom applications </Link>
                                    for the roku platform.These 
                                    apps may include features such as video playback, 
                                    live streaming, App store submission, and interactive content.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div>
                                <TfiVideoClapper/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4><a href="/website" className="backlinks">Progressive Application Development</a> </h4>
                                <p>
                                <Link className="link-builder" to="/software" >Progressive web App(PWA) </Link>development is the
                                 process of creating web applications designed to provide 
                                    a seamless user experirnce similar to a native mobile app.PWA's use modern web stacks such as 
                                    <Link className="link-builder" to="/customwebsites" > Web app  </Link>manifest 
                                    and service workers.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <FaReact/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>React Native App Development</h4>
                                <p>
                                    React Native app development services refer to the process of creating mobile applications
                                     using React Native 
                                    framework. React Native is a popular open-source framework developed by 
                                    <Link className="link-builder" to="/facebookads" > Facebook  </Link>that allows developers to 
                                    build cross-platform mobile applications using Javascript and React.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <SiFlutter/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Flutter App Development</h4>
                                <p>
                                   Flutter app development is the process of building mobile applications using the Flutter
                                    framework, which is als an 
                                   open-source framwork created by google. This framework enables 
                                   <Link className="link-builder" to="/"> developers  </Link>to create
                                    visually appealing and high-performing 
                                   apps for both 
                                   <Link className="link-builder" to="/androidapp" > Android </Link> 
                                   and <Link className="link-builder" to="/iosapp" > iOS platforms  </Link>with a 
                                   single codebase.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="whatwedo_element">
                        <div className="whatwedo_item">
                            <div>
                                <BsSmartwatch/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Wearables</h4>
                                <p>
                                    The development of apps for wearables presents special potential to improve user
                                    experiences, track health and fitness data, and offer seamless connectivity.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <FaGamepad/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>Game Development</h4>
                                <p>
                                    The design and production of interactive games for a variety of platforms, 
                                    including mobile devices, consoles, PCs, and virtual reality (VR) platforms.
                                </p>
                            </div>
                        </div>
                        <div className="whatwedo_item">
                            <div>
                                <SiHiveBlockchain/>
                            </div>
                            <div className="whatwedo_itemdiv">
                                <h4>BlockChain App Development</h4>
                                <p>
                                <Link className="link-builder" to="/dao" >Decentralized applications (dApps) </Link>that use 
                                    <Link className="link-builder" to="/blockchain" > blockchain technology  </Link>to deliver safe,
                                    open, and unchangeable solutions across multiple industries are created through.
                                </p>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="our_processnew">
                <h2>App Development Process</h2>
                <div className="our_processnewelement">
                    <div className="our_processnewdiv">
                        <div onClick={()=>setShowProcess('requirement')} className="processnewdiv">
                            <div className="processnewitem">
                                <FcTodoList />
                                <h4>Requirement Gathering</h4>
                            <p> <BsArrowDownRight /></p>
                            </div>
                        </div>
                        <div onClick={()=>setShowProcess('scope')} className="processnewdiv">
                            <div className="processnewitem">
                                <FcCollect />
                                <h4>Scope Of work</h4>
                                <p> <BsArrowDownRight /></p>
                            </div>
                        </div>
                        <div onClick={()=>setShowProcess('designandwireframe')} className="processnewdiv">
                            <div className="processnewitem">
                                <FcRadarPlot />
                                <h4>Design and Wireframing</h4>
                            <p> <BsArrowDownRight /></p>
                            </div>
                        </div>
                        <div onClick={()=>setShowProcess('development')} className="processnewdiv">
                            <div className="processnewitem">
                                <FcSupport />
                                <h4>Development</h4>
                            <p> <BsArrowDownRight /></p>
                            </div>
                        </div>
                        <div onClick={()=>setShowProcess('qualityassurance')} className="processnewdiv">
                            <div className="processnewitem">
                                <FcOk />
                                <h4>Quality Assurance</h4>
                            <p> <BsArrowDownRight /></p>
                            </div>
                        </div>
                        <div onClick={()=>setShowProcess('deployment')} className="processnewdiv">
                            <div className="processnewitem">
                                <FcDeployment />
                                <h4>Deployment</h4>
                            <p> <BsArrowDownRight /></p>
                            </div>
                        </div>
                    </div>
                    <div className="our_processnewdiv processContent">
                            { showProcess=== "scope" ? (
                                <div className="processContent_div">
                                    <h3>2.Scope of Work</h3>
                                    <p>
                                        A project's particular deliverables, tasks, and timetable are defined 
                                        and outlined in the scope of work (SoW) document. It offers a detailed 
                                        explanation of the tasks to be carried out, the project goals, and the 
                                        roles and responsibilities of all parties involved. By acting as a point
                                        of reference and agreement between the client or project sponsor and 
                                        the project team, the SoW makes sure that everyone is on the same page
                                        about the project's parameters and objectives.
                                        The scope of work definition acts as a crucial point of reference and 
                                        forms the foundation for project planning, execution, and control. It 
                                        ensures that the project team and stakeholders have a shared idea of 
                                        what will be delivered, by when, and at what degree of quality. It also 
                                        helps prevent scope creep (uncontrolled extension of project scope).
                                    </p>
                                    <Link className="hero_message_button" to="/contact">Get a Free Quote</Link>
                                </div>
                            ) : showProcess=== "requirement" ? (
                                <div className="processContent_div">
                                    <h3>1. Requirement Gathering</h3>
                                    <p>
                                        Gathering and recording stakeholder needs and expectations is a critical step in
                                         project management and <Link className="link-builder" to="/software" > software development </Link>known as requirement gathering. 
                                         The project team and stakeholders work together at this first stage of defining 
                                         and planning a project to identify and comprehend the project's requirements and
                                          constraints.
                                        The project team engages with stakeholders, including customers, end users, 
                                        subject matter experts, and other pertinent parties, during the requirement
                                         gathering phase to learn about the project's goals, scope, functionality, 
                                         and any unique business or technical requirements. The major objective is 
                                         to develop a thorough grasp of the project's objectives and the skills it
                                          needs to have.
                                    </p>
                                    <Link className="hero_message_button" to="/contact">Get a Free Quote</Link>
                                </div>
                            ):showProcess=== "designandwireframe" ?  (
                                <div className="processContent_div">
                                    <h3>3. Design &amp; Wireframing</h3>
                                    <p>
                                        Whether designing <Link className="link-builder" to="/websites" > websites, </Link>applications,
                                         or other digital interfaces, design and 
                                        wireframing are crucial aspects in producing visually appealing and user-friendly products.
                                         Wireframing entails producing visual representations of the product's structure and 
                                         arrangement, whereas design concentrates on the product's overall appearance and feel. 
                                         In the development of user experience (UX) and user interface (UI) designs, design and
                                          wireframing are equally important.
                                        The creative process of envisioning and graphically defining the appeal, branding, 
                                        and overall look of a product is referred to as design. It includes components like 
                                        color schemes, typefaces, pictures, icons, and layouts. The main objective of design 
                                        is to produce a visually appealing and compelling experience that complements the brand
                                         identity and fulfills the needs and expectations of the target market.
                                    </p>
                                    <Link className="hero_message_button" to="/contact">Get a Free Quote</Link>
                                </div>
                            ) : showProcess=== "development" ? (
                                    <div className="processContent_div">
                                        <h3>4. Development</h3>
                                        <div>
                                            <h6>Android App Development</h6>
                                            <p>
                                                Your hunt for the top <Link className="link-builder" to="/androidapp" >Android app </Link>development company has come to an end thanks to App 
                                                Crafters Studio, which has a team of skilled <Link className="link-builder" to="/androidapp" >Android app developers </Link> and business experts 
                                                available to assist you with your app idea.
                                            </p>
                                        </div>
                                        <div>
                                            <h6>IOS & IPhone App Development</h6>
                                            <p>
                                                Dedicated business owners and ambitious entrepreneurs may create feature-rich,
                                                 scalable <Link className="link-builder" to="/iosapp" >iOS apps </Link>with the help 
                                                 of App Crafters Studio, a top iOS & iphone app development company.
                                            </p>
                                            <ul>
                                                <li>Client Onboarding</li>
                                                <li>NDA Signing</li>
                                                <li>Gathering Data &amp; Client's Requirements</li>
                                                <li>Crafting App Solution</li>
                                                <li>Review Session &amps; Deal Finalization</li>
                                            </ul>
                                        </div>
                                        <Link className="hero_message_button" to="/contact">Get a Free Quote</Link>
                                    </div>
                            ): showProcess === "qualityassurance" ? (
                                <div className="processContent_div">
                                    <h3>5. Quality Assurance</h3>
                                    <p>
                                        Assuring that goods, services, or procedures adhere to the required quality standards and 
                                        satisfy customer needs is known as quality assurance (QA). Throughout the course of the
                                         project lifecycle, it encompasses a number of actions and procedures intended to foresee problems,
                                          pinpoint problems, and continuously enhance the quality of deliverables. In many sectors, 
                                          including software development, manufacturing, healthcare, and more, QA is essential.
                                        Early fault detection and correction is the goal of quality assurance, which lowers the 
                                        chance of expensive rework, customer discontent, or project failure. Organizations can 
                                        create a culture of quality, provide trustworthy goods or services, and raise customer 
                                        satisfaction by putting QA techniques into effect.
                                    </p>
                                    <Link className="hero_message_button" to="/contact">Get a Free Quote</Link>
                                </div>
                            ): showProcess=== "deployment" ? (
                                <div className="processContent_div">
                                    <h3>6. Deployment</h3>
                                    <p>
                                        A <Link className="link-builder" to="/software" > software program  </Link>or system is deployed when it is made available for usage in a real-world 
                                        production environment. It entails tasks like installing, configuring, and activating the
                                         program or system on the intended platform or infrastructure. Deployment, which moves generated 
                                         software from the development or testing environment to the operational environment, is a crucial 
                                         stage in the lifecycle of <Link className="link-builder" to="/software" > software development. </Link>
                                        The timely availability of <Link className="link-builder" to="/software" > software program  </Link> or systems 
                                        to end users is made possible by 
                                        effective and well-planned deployment processes that limit disruptions, cut downtime, and 
                                        increase uptime. To achieve a smooth transition from development to production, coordination 
                                        and collaboration between development teams, system administrators, network engineers, and
                                         other stakeholders are necessary.
                                    </p>
                                    <Link className="hero_message_button" to="/contact">Get a Free Quote</Link>
                                </div>
                            ): (null)
                            }
                    </div>
                    <div className="our_processnewdiv imageholder">
                        <img src="/images/mobileprocesstwo.png" alt="Mobile process" />
                    </div>

                </div>
            </div>
            <div className="sub_header">
                <p className="sub_paragraph">
                    At Global Mclien Software Solutions, we turn your ideas into highly engaging <span className="container_span">Mobile Applications.</span>
                </p>
                <div>
                    <h2 className="sub_htwo">Our Main Features &amp; <span className="container_span">Benefits</span></h2>
                </div>
                <div className="sub_features">
                    <div className="sub_features_item">
                        <img src="/images/resultgoal.jpg" alt="global mclien software solutions mobile app dev" />
                        <h5>End-to-End product Development</h5>
                        <p>
                            We can create your dream product—from start to finish
                            Our battle-tested mobile app developers are ready to take the challenge 
                            head-on. We have all the competencies in-house, so you don't need
                            to look for any additional vendors.
                        </p>
                    </div>
                    <div className="sub_features_item">
                        <img src="/images/teamwork.jpg" alt="global mclien software solutions mobile app dev"  />
                        <h5>Team extension</h5>
                        <p>
                            Add skilled programmers to your in-house mobile app development team
                            Get extra coding power to increase your development capacity.
                            Scale up and down at will.
                        </p>
                    </div>
                    <div className="sub_features_item">
                        <img src="/images/presentation.png" alt="global mclien software solutions mobile app dev"  />
                        <h5>Scaling App Performance</h5>
                        <p>
                            Whether you want to increase your app performance by building , rebuilding, or updating your 
                            existing app architecture, our experienced iOS and Android developers 
                            can take on that challenge giving you more time to focus on your clients.
                        </p>
                    </div>
                </div>
            </div>
            <div className="reviewsheader_container">
                <div className="main_reviewsheader">
                    <div>
                        <h4>Our Reviews</h4>
                        <p>& Clients Feedbacks</p>
                    </div>
                    <Link to="/all-reviews" >See all Reviews</Link>
                </div>
                <Reviews />
            </div>
            <div className="app_faqscontainer">
                    <h3>Frequently Asked Questions</h3>
                    <div className="app_faqselement">
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(1)}>
                                    Can you build Apps for both iOS and Android?
                                </h3>
                                {showFaqParagraph === 1 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p >&#x25BC;</p> }

                            </div>
                            {(showFaqParagraph === 1) && 
                                <p>
                                    Yes, the majority of the apps we create are compatible with both iOS and Android. People
                                    frequently want their applications to function on both mobile and web devices, therefore
                                    we employ development frameworks like React and React Native to swiftly support both
                                    platforms.                              
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(2)}>
                                    How long does App development take?
                                </h3>
                                {showFaqParagraph === 2 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 2) && 
                                <p>
                                    A successful project launch depends on a variety of elements, and some criteria can
                                     make the project take longer to build than others. After we have comprehended the 
                                    , only then can we communicate the estimated time.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(3)}>
                                    How much will app development cost?
                                </h3>
                                {showFaqParagraph === 3 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 3) && 
                                <p>
                                    The cost of the development of the mobile app depends on the requirements of the app.
                                    We can only give you an estimate after listening to all the requirements.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(4)}>
                                  What if i want any modification after launching my app?
                                </h3>
                                {showFaqParagraph === 4 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 4) && 
                                <p>
                                    The team at Blitz Mobile Apps is highly talented and can develop anything you like
                                     with extreme professionalism. Our developers believe in a customer-centric approach,
                                      therefore, developing very user-friendly yet attractive applications.  
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(5)}>
                                    What is the role of a mobile app developer?
                                </h3>
                                {showFaqParagraph === 5 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 5) && 
                                <p>
                                    A mobile app developer creates, tests, and develops applications for mobile devices 
                                    using programming languages and development expertise. They generally consider UI 
                                    and UX ideas when developing applications and work in well-known operating systems
                                     environments like iOS and Android.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(6)}>
                                    How much does it cost of hiring a developer ?
                                </h3>
                                {showFaqParagraph === 6 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 6) && 
                                <p>
                                    The cost of hiring a mobile app developer mostly depends on two factors: the 
                                    technology used for development and the developer’s level of experience.
                                    However, Resource offers app developers for hire in India for, on average, 
                                    $49 to $100 per hour.
                                </p>
                            }
                        </div>
                        <div className="faq_element">
                            <div className="faq_elementdiv">
                                <h3 onClick={()=>setShowFaqParagraph(7)}>
                                   What kind of assisstance can i expect during the development process?
                                </h3>
                                {showFaqParagraph === 7 ? <p onClick={()=>setShowFaqParagraph(0)}>&#x25B2;</p> : <p>&#x25BC;</p> }
                            </div>
                            {(showFaqParagraph === 7) && 
                                <p>
                                    Your personal project manager will be your primary point of contact for the duration
                                    of the development process. Our project managers handle all of your requests and 
                                    inquiries to make sure we can respond to you quickly and prioritize your work 
                                    appropriately.                               
                                </p>
                            }
                        </div>
                    </div>
            </div>
            <FooterForm field="Scope your Project"/>
        </div>
    </>
    )
}

export default React.memo(MobileApplication);