import React from 'react';
import { Link } from 'react-router-dom';

const Press =() =>{

    return(
        <div className='component_container blog ' >
            <div className="component_element">
                <img src="/images/underconstructionnobg.png" alt="global mclien software solutions under construction page" />
            </div>
            <h1 className="component_hone">Our Press Page is Under Construction. </h1>
            <div className="component_buttons">
                <Link to="/">&larr; Home</Link>
                <Link to="/contact">Contact Us</Link>
            </div>
        </div>
    )
}

export default React.memo(Press);